import React from "react";

export function Favicon() {
  return (
    <>
      <link
        rel="icon"
        type="image/png"
        href={require("./favicon/favicon-16.png")}
        sizes="16x16"
      />
      <link
        rel="icon"
        type="image/png"
        href={require("./favicon/favicon-32.png")}
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href={require("./favicon/favicon-120.png")}
        sizes="120x120"
      />
      <link
        rel="icon"
        type="image/png"
        href={require("./favicon/favicon-228.png")}
        sizes="228x228"
      />
      <link
        rel="icon"
        type="image/x-icon"
        href={require("./favicon/favicon.ico")}
      />
    </>
  );
}
