import { t } from "@lingui/macro";
import { format } from "d3-format";
import React from "react";
import { withDataLoader } from "../components/data-loader";
import { EmbedChart, StaticChart, Dataset } from "../components/figure";
import { HeroControlsWrapper } from "../components/hero-controls/hero-controls";
import { Area } from "../components/hero-vis/area";
import { Hero } from "../components/hero-vis/hero";
import { Lines } from "../components/hero-vis/line";
import { metadata } from "../data/data_finance_hero";
import {
  ChartRenderer,
  mkGemEntityDecoder,
  RenderMode,
  useCountryEntities,
  useNamedEntities,
  useRegionEntities,
} from "../domain";
import {
  foldChartType,
  HeroStateProvider,
  useChartType,
} from "../domain/hero-state";
import { useContentRect } from "../hooks";
import { useI18n } from "../locales";
import { io } from "../prelude";
import MultipleHeroes from "../components/multiple-heroes";

export * from "../data/data_finance_hero";

const formatValue = format(".1%");
const formatAxisValue = format(".0%");

const targetTitle = t("fig.finance.HERO.targetTitle")`Target`;
const targetLabel = t(
  "fig.finance.HERO.target"
)`Countries spend around 4% of their GDP on education`;
const infoLabel = t(
  "fig.finance.HERO.info"
)`Countries spend around 4% of their GDP on education`;

export const Data = mkGemEntityDecoder(["ind_id", "year"], {
  value: io.number,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({
  data: dataRaw,
  width,
  csv,
  xlsx,
  renderMode,
  dataset,
}: ChartRenderer<Data> & {
  width: number;
  csv: string;
  xlsx: string;
  dataset?: string;
}) => {
  const i18n = useI18n();

  const indicatorLabel = i18n._(
    t("fig.finance.HERO.indicatorLabel")`GDP spent on education`
  );

  const [chartType] = useChartType();

  const data = useNamedEntities(dataRaw);
  const countryData = useCountryEntities(data);
  const regionData = useRegionEntities(data);

  return (
    <HeroControlsWrapper
      renderMode={renderMode}
      caption={i18n._(metadata.caption)}
      content={i18n._(infoLabel)}
      source={i18n._(metadata.source)}
      id={metadata.id}
      csv={csv}
      xlsx={xlsx}
      dataset={dataset}
      hideToggle
    >
      {foldChartType(chartType, {
        today: () => (
          <Area
            label={indicatorLabel}
            width={width}
            values={countryData}
            target={0.04}
            max={0.08}
            tickValuesY={[0, 0.02, 0.04, 0.06, 0.08]}
            formatValue={formatValue}
            formatAxisValue={formatAxisValue}
          />
        ),
        timeseries: () => (
          <Lines
            label={indicatorLabel}
            width={width}
            values={regionData}
            max={0.08}
            tickValuesY={[0, 0.02, 0.04, 0.06, 0.08]}
            target={0.04}
            staggerDelay={renderMode === "static" ? 0 : undefined}
            worldFirst={false}
            showTarget={renderMode !== "embed"}
          />
        ),
      })}
    </HeroControlsWrapper>
  );
};

const csv = require("../data/data_finance_hero.zip");
const xlsx = require("../data/data_finance_hero.xlsx");
const datasets = require("../data/data_finance_hero_datasets").datasets;

const HF = ({
  renderMode = "default",
  dataset,
  renderMultiple,
}: {
  renderMode: RenderMode;
  dataset?: string;
  renderMultiple?: boolean;
}) => {
  const i18n = useI18n();

  const [ref, contentRect] = useContentRect();
  const Renderer = withDataLoader(
    dataset
      ? (datasets[dataset as keyof typeof datasets] as Dataset).url
      : require("../data/data_finance_hero.json"),
    Data,
    (props) =>
      dataset ? (
        <Chart
          {...props}
          width={contentRect.width}
          csv={(datasets[dataset as keyof typeof datasets] as any).csv}
          xlsx={(datasets[dataset as keyof typeof datasets] as any).xlsx}
          dataset={dataset}
        />
      ) : (
        <Chart
          {...props}
          width={contentRect.width}
          csv={csv}
          xlsx={xlsx}
          dataset={dataset}
        />
      ),
    { narrow: 400, wide: 400 }
  );

  const children = (
    <div ref={ref} style={{ width: "100%", overflow: "hidden" }}>
      <Renderer key="finance" renderMode={renderMode} />
    </div>
  );

  return renderMode === "embed" ? (
    <EmbedChart
      caption={i18n._(infoLabel)}
      source={i18n._(metadata.source)}
      isHeroChart
    >
      <HeroStateProvider initial={"today"}>{children}</HeroStateProvider>
    </EmbedChart>
  ) : renderMode === "static" ? (
    <StaticChart
      layout={"columns"}
      title={i18n._(metadata.title)}
      caption={i18n._(metadata.caption)}
      source={i18n._(metadata.source)}
    >
      <HeroStateProvider initial={"today"}>{children}</HeroStateProvider>
      <div style={{ height: "40px" }}></div>
      <HeroStateProvider initial={"timeseries"}>{children}</HeroStateProvider>
    </StaticChart>
  ) : renderMultiple ? (
    <MultipleHeroes datasets={datasets} Component={HF} />
  ) : (
    <HeroStateProvider>
      <Hero
        title={i18n._(metadata.title)}
        caption={i18n._(metadata.caption)}
        targetTitle={i18n._(targetTitle)}
        targetLabel={i18n._(targetLabel)}
      >
        {children}
      </Hero>
    </HeroStateProvider>
  );
};

export default HF;
