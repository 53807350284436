import { Draft } from "immer";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import React from "react";
import { useImmer } from "use-immer"; //
import { useNextClientRouteParams } from "../hooks";
import { MkPattern } from "../lib/type-utils";
import { An, Ar, E, Eq, io, O, Ord, pipe, R, tuple } from "../prelude";

// -----------------------------------------------------------------------------
// Control

export const FigureControlItem = io.intersection([
  io.type({
    value: io.string,
    label: io.string,
  }),
  io.partial({
    Component: io.unknown,
    isDisabled: io.boolean,
  }),
]);
export type FigureControlItem = io.TypeOf<typeof FigureControlItem>;

export const FigureControlPosition = io.union([
  io.literal("LEFT"),
  io.literal("RIGHT"),
  io.literal("TOOLBAR"),
  io.literal("TOP"),
  io.literal(""),
]);
export type FigureControlPosition = io.TypeOf<typeof FigureControlPosition>;

export function useFigureControlItems<A, B extends string>(
  data: Array<A>,
  getValue: (x: A) => B,
  getLabel: (x: A) => string,
  options: { ord?: Ord.Ord<B> } = {}
) {
  const sortedData = React.useMemo(
    () =>
      options.ord
        ? Ar.sort(Ord.contramap(getValue)(options.ord))(data)
        : Ar.sort(Ord.contramap(getLabel)(Ord.ordString))(data),
    [data] // eslint-disable-line
  );

  return React.useMemo(
    () =>
      pipe(
        sortedData,
        Ar.uniq(Eq.contramap(getLabel)(Eq.eqString)),
        Ar.map((x) => ({ value: getValue(x), label: getLabel(x) }))
      ),
    [sortedData] // eslint-disable-line
  );
}

// -----------------------------------------------------------------------------

export const ButtonGroupControl = io.intersection([
  io.type({
    type: io.literal("ButtonGroup"),
    selected: io.string,
  }),
  io.partial({
    position: FigureControlPosition,
    title: io.string,
  }),
]);
export type ButtonGroupControl = io.TypeOf<typeof ButtonGroupControl>;

export const CheckboxControl = io.intersection([
  io.type({
    type: io.literal("Checkbox"),
    label: io.string,
    selected: io.boolean,
  }),
  io.partial({
    position: FigureControlPosition,
    title: io.string,
  }),
]);
export type CheckboxControl = io.TypeOf<typeof CheckboxControl>;

export const SingleSelectControl = io.intersection([
  io.type({
    type: io.literal("SingleSelect"),
    selected: optionFromNullable(io.string),
  }),
  io.partial({
    title: io.string,
    label: io.string,
    position: FigureControlPosition,
  }),
]);
export type SingleSelectControl = io.TypeOf<typeof SingleSelectControl>;

export const MultiSelectControl = io.intersection([
  io.type({
    type: io.literal("MultiSelect"),
    label: io.string,
    selected: optionFromNullable(io.array(io.string)),
  }),
  io.partial({
    title: io.string,
    position: FigureControlPosition,
    minItems: io.number,
    maxItems: io.number,
  }),
]);
export type MultiSelectControl = io.TypeOf<typeof MultiSelectControl>;

export const TimelineControl = io.intersection([
  io.type({
    type: io.literal("Timeline"),
    range: io.tuple([io.number, io.number]),
    selected: io.number,
    checked: io.boolean,
  }),
  io.partial({
    title: io.string,
    position: FigureControlPosition,
  }),
]);
export type TimelineControl = io.TypeOf<typeof TimelineControl>;

export const RadioControl = io.intersection([
  io.type({
    type: io.literal("Radio"),
    title: io.string,
    selected: optionFromNullable(io.string),
  }),
  io.partial({
    title: io.string,
    position: FigureControlPosition,
  }),
]);
export type RadioControl = io.TypeOf<typeof RadioControl>;

export const MultiCheckboxControl = io.intersection([
  io.type({
    type: io.literal("MultiCheckbox"),
    label: io.string,
    selected: optionFromNullable(io.array(io.string)),
  }),
  io.partial({
    title: io.string,
    position: FigureControlPosition,
  }),
]);

export type MultiCheckboxControl = io.TypeOf<typeof MultiCheckboxControl>;

// -----------------------------------------------------------------------------

export const FCToggle = io.union([CheckboxControl, TimelineControl]);
export type FCToggle = io.TypeOf<typeof FCToggle>;

export const FCSelection = io.union([
  ButtonGroupControl,
  MultiSelectControl,
  SingleSelectControl,
  RadioControl,
  MultiCheckboxControl,
]);
export type FCSelection = io.TypeOf<typeof FCSelection>;

export const FigureControl = io.union([FCToggle, FCSelection]);
export type FigureControl = io.TypeOf<typeof FigureControl>;

// -----------------------------------------------------------------------------
// FigureState

export const FigureControls = io.record(io.string, FigureControl);
export type FigureControls = io.TypeOf<typeof FigureControls>;

export const FigureControlItems = io.record(
  io.string,
  io.array(FigureControlItem)
);
export type FigureControlItems = io.TypeOf<typeof FigureControlItems>;

export const FigureState = io.type({
  controls: optionFromNullable(FigureControls),
  controlItems: FigureControlItems,
  title: io.string,
});
export type FigureState = io.TypeOf<typeof FigureState>;

export const initialFigureState: FigureState = {
  controls: O.none,
  controlItems: {},
  title: "",
};

export function mkActions(
  produce: (f: (draft: Draft<FigureState>) => void | FigureState) => void
) {
  // We use a lot of imperative code here to follow Immer's model of mutating
  // the draft, so Immer can apply the changes immutably under the hood.
  return {
    setTitle: (title: string) => {
      produce((draft) => {
        draft.title = title;
      });
    },
    setToggleControl: (id: string, control: FCToggle) => {
      produce((draft) => {
        if (O.isSome(draft.controls)) {
          // If a selected value has been set before, don't override it with the default
          const selected = draft.controls.value[id]
            ? draft.controls.value[id].selected
            : control.selected;
          draft.controls.value[id] = {
            ...control,
            position: control.position || "TOP",
          };
          draft.controls.value[id].selected = selected as FCToggle["selected"];
        } else {
          (draft.controls = O.some({
            [id]: { ...control, position: control.position || "TOP" },
          })),
            (draft.controlItems = {});
        }
      });
    },
    updateToggleControl: (id: string, selected: FCToggle["selected"]) => {
      produce((draft) => {
        if (O.isSome(draft.controls) && draft.controls.value[id]) {
          draft.controls.value[id].selected = selected;
        }
      });
    },
    setSelectionControl: (
      id: string,
      control: FCSelection,
      controlItems: Array<FigureControlItem>
    ) => {
      produce((draft) => {
        if (O.isSome(draft.controls)) {
          // If a selected value has been set before, don't override it with the default
          const selected = draft.controls.value[id]
            ? draft.controls.value[id].selected
            : control.selected;
          draft.controls.value[id] = {
            ...control,
            position: control.position || "TOP",
          };
          draft.controls.value[id].selected = selected as any;
          draft.controlItems[id] = controlItems;
        } else {
          (draft.controls = O.some({
            [id]: { ...control, position: control.position || "TOP" },
          })),
            (draft.controlItems = { [id]: controlItems });
        }
      });
    },
    updateSelectionControl: (id: string, selected: FCSelection["selected"]) => {
      produce((draft) => {
        if (O.isSome(draft.controls) && draft.controls.value[id]) {
          draft.controls.value[id].selected = selected;
        }
      });
    },
    clearAllControls: () => {
      produce((draft) => {
        draft.controls = O.none;
        draft.controlItems = {};
      });
    },
    clearControl: (id: string) => {
      produce((draft) => {
        pipe(
          draft.controls,
          O.map((controls) => {
            if (controls[id]) {
              draft.controls = O.fromNullable(R.deleteAt(id)(controls));
              draft.controlItems = R.deleteAt(id)(draft.controlItems);
            }
          })
        );
      });
    },
    setMultiSelectItems: (id: string, nextItems: Array<FigureControlItem>) => {
      produce((draft) => {
        if (O.isSome(draft.controls)) {
          const control = draft.controls.value[id];
          if (control && control.type === "MultiSelect") {
            draft.controls.value[id].selected = pipe(
              control.selected,
              O.alt(() => O.some([]) as O.Option<Array<string>>),
              O.map((items) => {
                const minItems =
                  control.minItems != null ? control.minItems : 1;
                const maxItems =
                  control.maxItems != null ? control.maxItems : Infinity;
                return nextItems.length >= minItems &&
                  nextItems.length <= maxItems
                  ? nextItems.map((x) => x.value)
                  : items;
              }),
              O.chain((xs) => (xs.length > 0 ? O.some(xs) : O.none))
            );
          }
        }
      });
    },
    setMultiCheckboxItems: (
      id: string,
      nextItems: Array<FigureControlItem>
    ) => {
      produce((draft) => {
        if (O.isSome(draft.controls)) {
          const control = draft.controls.value[id];
          if (control && control.type === "MultiCheckbox") {
            draft.controls.value[id].selected = pipe(
              control.selected,
              O.alt(() => O.some([]) as O.Option<Array<string>>),
              O.map(() => nextItems.map((x) => x.value)),
              O.chain((xs) => (xs.length > 0 ? O.some(xs) : O.none))
            );
          }
        }
      });
    },
    toggleMultiSelectItem: (id: string, item: string) => {
      produce((draft) => {
        if (O.isSome(draft.controls)) {
          const control = draft.controls.value[id];
          if (control && control.type === "MultiSelect") {
            draft.controls.value[id].selected = pipe(
              control.selected,
              O.alt(() => O.some([] as Array<string>)),
              O.map((items) => {
                const minItems =
                  control.minItems != null ? control.minItems : 1;
                const maxItems =
                  control.maxItems != null ? control.maxItems : Infinity;
                const nextItems = items.includes(item)
                  ? items.filter((x) => x !== item)
                  : items.concat(item);
                return nextItems.length >= minItems &&
                  nextItems.length <= maxItems
                  ? nextItems
                  : items;
              }),
              O.chain((items) => (items.length > 0 ? O.some(items) : O.none))
            );
          }
        }
      });
    },
    toggleMultiCheckboxItem: (id: string, item: string) => {
      produce((draft) => {
        if (O.isSome(draft.controls)) {
          const control = draft.controls.value[id];
          if (control && control.type === "MultiCheckbox") {
            draft.controls.value[id].selected = pipe(
              control.selected,
              O.alt(() => O.some([] as Array<string>)),
              O.map((items) => {
                const nextItems = items.includes(item)
                  ? items.filter((x) => x !== item)
                  : items.concat(item);
                return nextItems;
              }),
              O.chain((items) => (items.length > 0 ? O.some(items) : O.none))
            );
          }
        }
      });
    },
  };
}

export type FigureActions = ReturnType<typeof mkActions>;

// -----------------------------------------------------------------------------
// FigureStateContext

export const FigureStateContext = React.createContext([
  initialFigureState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  mkActions(() => {}),
] as [FigureState, FigureActions]);

export const FigureStateProvider = ({
  children,
  initialState,
}: {
  children: React.ReactNode;
  initialState: FigureState;
}) => {
  const [state, updateState] = useImmer<FigureState>(initialState);
  const actions = React.useMemo(() => mkActions(updateState), [updateState]);
  return (
    <FigureStateContext.Provider value={tuple(state, actions)}>
      {children}
    </FigureStateContext.Provider>
  );
};

export const useFigureState = () => React.useContext(FigureStateContext);

export const useSerializedFigureState = () => {
  const [state] = useFigureState();
  const encodedControls = pipe(
    state.controls,
    // We only encode the "controls" part of the state into the URL, as it would
    // get too long otherwise.
    O.fold(
      () => ({}),
      (controls) => FigureControls.encode(controls)
    )
  );

  return { title: state.title, controls: encodedControls };
};

export const useUrlFigureState = () => {
  const router = useNextClientRouteParams();
  return React.useMemo(() => {
    const query: $IntentionalAny = router.query || {};
    const state = JSON.parse(query.state || "{}");
    const validation = FigureControls.decode(state.controls);
    return pipe(
      validation,
      E.chain((controls) =>
        R.isEmpty(controls) ? E.left({}) : E.right(controls)
      ),
      // We don't rehydrate controlItems from the URL (it would be too long),
      // so we trust that the app's logic will fill in controlItems with the
      // items we need for rendering the chart.
      E.map((controls) => ({
        controls: O.some(controls),
        controlItems: {},
        title: state.title,
      })),
      E.getOrElse(() => initialFigureState)
    );
  }, [router.query]);
};

/**
 * This is not very type safe, which is why we only allow access through more
 * strictly typed helper functions. But they, too, can lead into runtime errors.
 */
const useSelectedFigureState = <A extends FigureControl>(
  type: FigureControl["type"],
  id: string
): [A["selected"], FigureActions] => {
  const [state, actions] = useFigureState();
  const selected = React.useMemo(
    () =>
      pipe(
        state.controls,
        O.chain(lookupFigureControl(id)),
        O.chain((x) => {
          if (x.type !== type) {
            console.error(
              `[useSelectedFigureState]: Requested "${type}" figure control state for "${id}", but got "${x.type}".`
            );
            return O.none;
          }
          return O.some(x);
        }),
        O.fold(
          () => O.none,
          (x) => x.selected
        )
      ),
    [id, type, state.controls]
  );
  return tuple(selected, actions);
};

type Control =
  | "ButtonGroup"
  | "Radio"
  | "Checkbox"
  | "MultiCheckbox"
  | "SingleSelect"
  | "MultiSelect"
  | "Timeline";

/**
 * Makes a specialized useSelectedFigureState hook. If the
 * value does not exist in the current state, it will be
 * defaulted.
 *
 * The type returned by the returned hook will correspond
 * to the type of the defaultValue passed in the options.
 */
export const makeUseControl = <
  TSpec extends Record<
    string,
    {
      defaultValue: unknown;
      control: Control;
    }
  >,
>(
  spec: TSpec
) => {
  return <IdType extends Exclude<keyof TSpec, number | symbol>>(
    id: IdType
  ): TSpec[IdType]["defaultValue"] => {
    const control = spec[id].control;
    const [selected] = useSelectedFigureState(control, id);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const shouldUnwrap = O.isSome(selected) || O.isNone(selected);
    return (
      (shouldUnwrap ? O.toNullable(selected) : selected) ??
      spec[id].defaultValue
    );
  };
};

export const useButtonGroupState = (id: string) => {
  return useSelectedFigureState<ButtonGroupControl>("ButtonGroup", id);
};

export const useCheckboxState = (id: string) => {
  return useSelectedFigureState<CheckboxControl>("Checkbox", id);
};

export const useSingleSelectState = (id: string) => {
  return useSelectedFigureState<SingleSelectControl>("SingleSelect", id);
};

export const useMultiSelectState = (id: string) => {
  return useSelectedFigureState<MultiSelectControl>("MultiSelect", id);
};

export const useTimelineState = (id: string) => {
  return useSelectedFigureState<TimelineControl>("Timeline", id);
};

export const useRadioState = (id: string) => {
  return useSelectedFigureState<RadioControl>("Radio", id);
};

export const useMultiCheckboxState = (id: string) => {
  return useSelectedFigureState<MultiCheckboxControl>("MultiCheckbox", id);
};

// -----------------------------------------------------------------------------
// Functions

export function foldFigureControl<A>(
  x: FigureControl,
  pattern: MkPattern<FigureControl, "type", A>
): A {
  return (pattern[x.type] as $Unexpressable)(x);
}

export function foldFigureControl_<A>(
  pattern: MkPattern<FigureControl, "type", A>
) {
  return (x: FigureControl) => foldFigureControl(x, pattern);
}

export function lookupFigureControl(id: string) {
  return (controls: FigureControls) => R.lookup(id, controls);
}

export function lookupFigureControlItems(id: string) {
  return (controlItems: FigureControlItems) =>
    pipe(
      R.lookup(id, controlItems),
      O.chain((xs) => An.fromArray(xs))
    );
}
