import { t } from "@lingui/macro";
import { curveBasis } from "d3-shape";
import React from "react";
import { LineChart } from "../charts/LineChart";
import { withFigureIO } from "../components/figure";
import { useReadScrolly } from "../components/scrolly";
import { metadata } from "../data/data_equity_fig_GPITREND";
import {
  ChartRenderer,
  getLevelName,
  getRegionCategoryName,
  mkGemEntityDecoder,
  ordLevel,
  useButtonGroupState,
  useFigureControlItems,
  useNamedEntitiesWithLevel,
  useRegionEntities,
} from "../domain";
import { useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { io, O, pipe } from "../prelude";

export * from "../data/data_equity_fig_GPITREND";

const DEFAULT_REGION_TYPE = "income";

export const Data = mkGemEntityDecoder(
  ["ind_id", "level", "year", "value"],
  {}
);
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const [regionType, actions] = useButtonGroupState("regionType");
  const { activeSection } = useReadScrolly();

  const [scrollyRegionType, scrollyId] = React.useMemo(
    () =>
      pipe(
        activeSection,
        // Format: "income,1", but we throw away the "1" (sections need to have distinct IDs)
        O.map((str) => {
          const [a = "", b = ""] = str.split(",");
          return [a, b] as [string, string];
        }),
        O.getOrElse(() => [DEFAULT_REGION_TYPE, "0"])
      ),
    [activeSection]
  );

  const entityData = useNamedEntitiesWithLevel(data);
  const regionData = useRegionEntities(entityData);
  const levels = useFigureControlItems(
    entityData,
    (x) => x.level,
    (x) => getLevelName(i18n, x.level),
    {
      ord: ordLevel,
    }
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "regionType",
      {
        type: "ButtonGroup",
        selected: DEFAULT_REGION_TYPE,
      },
      [
        {
          value: "income",
          label: getRegionCategoryName(i18n, "income"),
        },
        {
          value: "geo",
          label: getRegionCategoryName(i18n, "geo"),
        },
      ]
    );
  }, [i18n, actions]);

  React.useEffect(() => {
    if (O.isSome(activeSection)) {
      actions.updateSelectionControl("regionType", scrollyRegionType);
    }
  }, [actions, activeSection, scrollyRegionType, scrollyId]);

  const chartData = React.useMemo(() => {
    return regionData.filter((x) => x.entity_type === regionType);
  }, [regionData, regionType]);

  return (
    <LineChart
      tLabel={(s) => s}
      height={
        client.screenSDown
          ? M.chartHeight.xs
          : client.screenMDown
            ? M.chartHeight.s
            : M.chartHeight.m
      }
      minInnerWidth={client.screenSDown ? 60 : undefined}
      x="year"
      yTicks={[0.4, 0.6, 0.8, 1, 1.2, 1.4]}
      yAnnotations={[
        {
          type: "band",
          label: i18n._(t`Parity`),
          range: [0.95, 1.05],
          dy: client.screenMDown ? "-0.7em" : undefined,
        },
        {
          type: "line",
          value: 1,
        },
      ]}
      zero={false}
      yNice={0}
      timeParse="%Y"
      timeFormat="%Y"
      numberFormat=".2f"
      column="level_name"
      columns={levels.length}
      columnSort="none"
      category="datum.entity_name"
      colorLegend
      endLabel={false}
      values={chartData}
      showTooltip
      curve={curveBasis}
    />
  );
};

export default withFigureIO({
  url: require("../data/data_equity_fig_GPITREND.json"),
  csv: require("../data/data_equity_fig_GPITREND.zip"),
  xlsx: require("../data/data_equity_fig_GPITREND.xlsx"),
  metadata,
  Data,
  Chart,
  size: "wide",
  datasets: require("../data/data_equity_fig_GPITREND_datasets").datasets,
});
