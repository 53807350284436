import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import useFormat from "hooks/useFormat";
import React from "react";
import { ChartGrid } from "../charts-motion";
import {
  useColorScaleVDP,
  VerticalDotPlot,
} from "../charts-motion/vertical-dot-plot";
import ColorLegend from "../charts/lib/ColorLegend";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_equity_fig_WLGPI";
import {
  ChartRenderer,
  countryIdIso,
  getCountryMeta,
  getLevelACName,
  LevelAC,
  mkGemEntityDecoder,
  ordLevelAC,
  ordRegionCategory,
  regionIdEq,
  useButtonGroupState,
  useCountryEntities,
  useFigureControlItems,
  useFigureState,
  useNamedEntities,
  useRegionEntities,
  useSingleSelectState,
} from "../domain";
import { useTheme } from "../hooks";
import { extentNumber, insertionOrderSet, unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { Ar, identity, io, O, Ord, pipe } from "../prelude";

export * from "../data/data_equity_fig_WLGPI";

const DEFAULT_COUNTRY = "BEN";
const DEFAULT_CATEGORY = "Sex";

const yUnitLabel = t("fig.equity.WLGPI.yUnitLabel")`Parity index`;

const Category = io.keyof({
  Location: null,
  Sex: null,
  Wealth: null,
});
type Category = io.TypeOf<typeof Category>;

const categoryLookup: Record<Category, MessageDescriptor> = {
  Location: t("fig.equity.WLGPI.Location")`Location`,
  Sex: t("fig.equity.WLGPI.Sex")`Sex`,
  Wealth: t("fig.equity.WLGPI.Wealth")`Wealth`,
};

export const Data = mkGemEntityDecoder([], {
  ind_id: LevelAC,
  value: io.number,
  category: Category,
  // survey: io.string // Not defined for all data points
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: dataRaw }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const env = useConfig();
  const { client } = useTheme();
  const [, actions] = useFigureState();
  const [selectedCountry] = useSingleSelectState("country");
  const [selectedCategory] = useButtonGroupState("category");

  const entityData_ = useNamedEntities(dataRaw);
  const entityData = React.useMemo(() => {
    return entityData_.map((x) => ({
      ...x,
      category_name: i18n._(categoryLookup[x.category]),
      ind_name: getLevelACName(i18n, x.ind_id),
    }));
  }, [entityData_, i18n]);
  const countryData = useCountryEntities(entityData);
  const regionData = useRegionEntities(entityData);

  const countryControlItems = useFigureControlItems(
    countryData,
    (x) => countryIdIso.unwrap(x.id),
    (x) => x.entity_name
  );
  const categoryControlItems = useFigureControlItems(
    entityData,
    (x) => x.category,
    (x) => x.category_name
  );

  const selectedCountryMeta = React.useMemo(() => {
    return pipe(
      selectedCountry,
      O.chain((id) => getCountryMeta(env.countries, countryIdIso.wrap(id)))
    );
  }, [env.countries, selectedCountry]);

  React.useEffect(() => {
    actions.setSelectionControl(
      "category",
      {
        type: "ButtonGroup",
        selected: DEFAULT_CATEGORY,
      },
      categoryControlItems
    );

    actions.setSelectionControl(
      "country",
      {
        type: "SingleSelect",
        selected: pipe(
          countryControlItems,
          Ar.findFirst((x) => x.value === DEFAULT_COUNTRY),
          O.alt(() => Ar.head(countryControlItems)),
          O.map((x) => x.value)
        ),
      },
      countryControlItems
    );
  }, [actions, categoryControlItems, countryControlItems]);

  const { chartData, colorDomain } = React.useMemo(() => {
    const countries = pipe(
      countryData,
      Ar.filter(({ id }) =>
        pipe(
          selectedCountry,
          O.fold(
            () => false,
            (x) => x === countryIdIso.unwrap(id)
          )
        )
      ),
      Ar.sortBy([
        Ord.contramap((x: (typeof countryData)[number]) => x.entity_name)(
          Ord.ordString
        ),
      ])
    );

    const regions = pipe(
      regionData,
      Ar.filter(
        ({ id, entity_type }) =>
          entity_type === "world" ||
          pipe(
            selectedCountryMeta,
            O.fold(
              () => false,
              (x) =>
                (entity_type === "geo" &&
                  regionIdEq.equals(id, x.sdg_region)) ||
                (entity_type === "income" &&
                  x.income != null &&
                  regionIdEq.equals(id, x.income))
            )
          )
      ),
      Ar.sortBy([
        Ord.contramap((x: (typeof regionData)[number]) => x.entity_type)(
          ordRegionCategory
        ),
      ])
    );

    const data = [...countries, ...regions];
    const colorDomain = insertionOrderSet(
      unsafeFromArray(data),
      (x) => x.entity_name
    );

    return {
      chartData: unsafeFromArray(
        Ar.sortBy([
          Ord.contramap((x: (typeof data)[number]) => x.ind_id)(ordLevelAC),
          Ord.contramap((x: (typeof data)[number]) => x.entity_type)(
            ordRegionCategory
          ),
          Ord.contramap((x: (typeof data)[number]) => x.entity_name)(
            Ord.ordString
          ),
        ])(data.filter(({ category }) => category === selectedCategory))
      ),
      colorDomain,
    };
  }, [
    countryData,
    regionData,
    selectedCountry,
    selectedCategory,
    selectedCountryMeta,
  ]);
  type Datum = (typeof chartData)[number];

  const getX = React.useCallback((x: Datum) => x.ind_name, []);
  const getValue = React.useCallback((x: Datum) => x.value, []);
  const getColor = React.useCallback((x: Datum) => x.entity_name, []);
  const getColorPalette = React.useCallback(() => M.colorRanges.discrete, []);
  const formatValue = useFormat(".2f");
  const formatAxisValue = useFormat(".1f");

  const extentAll = React.useMemo(() => {
    return extentNumber(unsafeFromArray(entityData), getValue) as [
      number,
      number,
    ];
  }, [entityData, getValue]);

  const [colorScale, colorLegendValues] = useColorScaleVDP({
    data: chartData,
    getColor,
    colorPalette: getColorPalette,
    formatColor: identity,
    domainColor: colorDomain,
  });

  return (
    <>
      <ChartGrid
        data={chartData}
        getCell={getX}
        columnCount={5}
        minWidth={client.screenMDown ? 60 : 80}
      >
        {({ data, width, firstInRow }) => (
          <VerticalDotPlot
            width={width}
            height={
              client.screenSDown
                ? M.chartHeight.xs
                : client.screenMDown
                  ? M.chartHeight.s
                  : M.chartHeight.m
            }
            data={data}
            getX={getX}
            getValue={getValue}
            getColor={getColor}
            formatX={identity}
            formatValue={formatValue}
            formatAxisValue={formatAxisValue}
            domainY={extentAll}
            colorScale={colorScale}
            colorLegendValues={colorLegendValues}
            showTooltip
            labelStyleX="none"
            marginLeft={20}
            hideYAxis={!firstInRow}
            yUnitLabel={firstInRow ? i18n._(yUnitLabel) : undefined}
            yAnnotations={[
              {
                type: "band",
                label: i18n._(t`Parity`),
                range: [0.95, 1.05],
                dy: client.screenMDown ? "-0.7em" : undefined,
              },
              {
                type: "line",
                value: 1,
              },
            ]}
            dotStyle={client.screenSDown ? "medium" : "default"}
          />
        )}
      </ChartGrid>
      <ColorLegend inline maxWidth={"100%"} values={colorLegendValues} />
    </>
  );
};

export default withFigureIO({
  url: require("../data/data_equity_fig_WLGPI.json"),
  csv: require("../data/data_equity_fig_WLGPI.zip"),
  xlsx: require("../data/data_equity_fig_WLGPI.xlsx"),
  metadata,
  Data,
  Chart,
  size: "wide",
  datasets: require("../data/data_equity_fig_WLGPI_datasets").datasets,
});
