import { t } from "@lingui/macro";
import { scaleOrdinal } from "d3-scale";
import React from "react";
import ColorLegend from "../charts/lib/ColorLegend";
import { LineChart } from "../charts/LineChart";
import { withFigureIO } from "../components/figure";
import { useReadScrolly } from "../components/scrolly";
import { useConfig } from "../config";
import { metadata } from "../data/data_access_fig_OOSRATE";
import {
  ChartRenderer,
  countryIdIso,
  foldGeoEntity,
  getCountryMeta,
  getCountryName,
  mkGemEntityDecoder,
  useCountryEntities,
  useFigureControlItems,
  useMultiSelectState,
  useNamedEntitiesWithLevel,
} from "../domain";
import { useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { fromCount, mkScrollyColorPalette } from "../materials";
import { Ar, io, O, pipe } from "../prelude";

export * from "../data/data_access_fig_OOSRATE";

const DEFAULT_COUNTRIES = ["IDN", "MYS"];

export const Data = mkGemEntityDecoder(["value", "year", "level"], {
  type: io.string,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const env = useConfig();
  const i18n = useI18n();
  const { client } = useTheme();
  const [selectedCountries, actions] = useMultiSelectState("countries");
  const { activeSection, sections } = useReadScrolly();

  const scrollyCountries = React.useMemo(
    () =>
      pipe(
        activeSection,
        O.map((x) => x.split(",")),
        O.chain((strIds) =>
          Ar.array.sequence(O.option)(
            strIds.map((strId) =>
              getCountryMeta(env.countries, countryIdIso.wrap(strId))
            )
          )
        ),
        O.map((xs) => xs.map((meta) => meta.iso3c)),
        O.getOrElse(() => DEFAULT_COUNTRIES.map(countryIdIso.wrap))
      ),
    [env.countries, activeSection]
  );

  const entityData = useNamedEntitiesWithLevel(data);
  const countryData = useCountryEntities(entityData);
  const countryControlItems = useFigureControlItems(
    countryData,
    (x) => countryIdIso.unwrap(x.id),
    (x) => x.entity_name
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "countries",
      {
        type: "MultiSelect",
        label: i18n._(t`Countries`),
        selected: O.some(DEFAULT_COUNTRIES),
      },
      countryControlItems
    );
  }, [i18n, actions, countryControlItems]);

  React.useEffect(() => {
    if (O.isSome(activeSection)) {
      actions.updateSelectionControl(
        "countries",
        O.some(scrollyCountries.map((x) => countryIdIso.unwrap(x)))
      );
    }
  }, [i18n, actions, activeSection, scrollyCountries]);

  const chartData = React.useMemo(() => {
    return entityData.filter(
      foldGeoEntity({
        country: ({ id }) =>
          pipe(
            selectedCountries,
            O.fold(
              () => false,
              (xs) => xs.includes(countryIdIso.unwrap(id))
            )
          ),
        region: ({ entity_type }) => entity_type === "world",
      })
    );
  }, [entityData, selectedCountries]);

  const selectedCountryLabels = React.useMemo(() => {
    return pipe(
      selectedCountries,
      O.map((xs) =>
        xs.map((x) => getCountryName(env.countries, countryIdIso.wrap(x)))
      )
    );
  }, [env.countries, selectedCountries]);

  const { colorScale, colorLegendValues } = React.useMemo(() => {
    const multiIdO = pipe(
      selectedCountries,
      O.map((xs) => xs.join(","))
    );
    const count = pipe(
      multiIdO,
      O.fold(
        () => 1,
        (id) => id.split(",").length
      )
    );
    const palette = fromCount(
      mkScrollyColorPalette(Array.from(sections.values()), multiIdO),
      count
    );
    const colorScale = scaleOrdinal(palette).domain(
      pipe(
        selectedCountryLabels,
        O.getOrElse<Array<string>>(() => [])
      )
    );
    const colorLegendValues = colorScale.domain().map((label) => ({
      label,
      color: colorScale(label),
    }));
    return { colorScale, colorLegendValues };
  }, [sections, selectedCountries, selectedCountryLabels]);

  return (
    <>
      <LineChart
        tLabel={(s) => s}
        height={client.screenMDown ? M.chartHeight.xs : M.chartHeight.m}
        endLabel={!client.screenMDown}
        x="year"
        timeParse="%Y"
        timeFormat="%Y"
        numberFormat=".1%"
        column="level_name"
        columns={3}
        columnSort="none"
        category="datum.entity_name"
        colorLegend={false}
        colorScale={colorScale}
        values={chartData}
        yTicks={[0, 0.5, 1]}
        connectLines
        showTooltip
        markStyle={(x) => (x.entity_type === "world" ? "reference" : "normal")}
      />
      <ColorLegend inline maxWidth={"100%"} values={colorLegendValues} />
    </>
  );
};

export default withFigureIO({
  url: require("../data/data_access_fig_OOSRATE.json"),
  csv: require("../data/data_access_fig_OOSRATE.zip"),
  xlsx: require("../data/data_access_fig_OOSRATE.xlsx"),
  metadata,
  Data,
  Chart,
  datasets: require("../data/data_access_fig_OOSRATE_datasets").datasets,
});
