import css from "@emotion/css";
import { t } from "@lingui/macro";
import { springConfigs } from "charts/lib/motion";
import { extent, group } from "d3-array";
import { format } from "d3-format";
import { scaleLinear, scaleOrdinal } from "d3-scale";
import { line } from "d3-shape";
import { motion } from "framer-motion";
import React from "react";
import { removeTrailingZero } from "../../charts/lib/utils";
import { useConfig } from "../../config";
import {
  filterEssentialIncomeRegions,
  getRegionName,
  RegionId,
  regionIdEq,
  sortRegions,
} from "../../domain";
import { usePointerPosition, useTheme } from "../../hooks";
import { getContrastText } from "../../lib/colorManipulator";
import { reduceVerticalOverlap } from "../../lib/reduceOverlap";
import { rtlDir } from "../../lib/rtl";
import { useI18n } from "../../locales";
import * as M from "../../materials";
import { fromCount } from "../../materials";
import { Ar, O, Ord, pipe } from "../../prelude";

// const DEFAULT_STAGGER_DELAY = 0.7; // seconds

export const formatPercentage = format(".0%");
export const formatEndValue = removeTrailingZero(format(".1%"));

export interface LineProps {
  label: string;
  values: Array<{
    id: RegionId;
    value: number | null;
    year: number;
  }>;
  width: number;
  target?: number;
  showTarget?: boolean;
  max?: number;
  tickValuesY?: number[];
  sdgRanges?: number[][];
  topLabels?: Array<{
    year: number;
    label: string;
  }>;
  staggerDelay?: number;
  worldFirst?: boolean;
}

const [YEAR_SDG_ADOPTION, YEAR_SDG_GOAL] = [2015, 2030];

export const Lines = ({
  label,
  width,
  max = 1,
  values,
  target = 1,
  showTarget = true,
  worldFirst = true,
  //staggerDelay = DEFAULT_STAGGER_DELAY,
  ...rest
}: LineProps) => {
  const i18n = useI18n();
  const { regions } = useConfig();
  const { client, activeTheme } = useTheme();
  const [pointerPositionRef, pointerPosition] =
    usePointerPosition<SVGRectElement>();

  const height = Math.min(Math.max(width / 1.6, 210), 400);
  const [yearMin, yearMax] = extent(values, (d) => +d.year) as [number, number];

  const [focusedYear, setFocusedYear] = React.useState<number>(yearMax);

  const PADDING = React.useMemo(
    () => ({
      left: client.screenSDown ? 30 : 40,
      right: 24,
      top: 24,
      bottom: 3,
    }),
    [client.screenSDown]
  );

  const xScale = React.useCallback(
    scaleLinear()
      .domain([yearMin, 2030])
      .range([PADDING.left, width - PADDING.right]),
    [PADDING.left, PADDING.right, yearMin, width]
  );

  const yScale = React.useCallback(
    scaleLinear()
      .domain([0, max])
      .range([height - (PADDING.bottom + PADDING.top), PADDING.top])
      .clamp(true),
    [PADDING.top, PADDING.bottom, height, max]
  );

  const lineData = React.useMemo(() => {
    const ord = Ord.contramap((x: (typeof values)[number]) => x.year)(
      Ord.ordNumber
    );
    const data = Array.from(group(values, (d) => d.id)).map(([id, points_]) => {
      const points = Ar.sort(ord)(points_);
      const last = points[points.length - 1];
      const endValue = last && last.value != null ? last.value : 0;
      return {
        id,
        label: getRegionName(regions, id),
        points,
        endValue: endValue,
        currentValue: points.find((p) => p.year === focusedYear)?.value,
      };
    });

    return sortRegions(regions, worldFirst)(
      // Only show essential regions on small screens
      client.screenSDown
        ? filterEssentialIncomeRegions(data, (x) => x.id)
        : data,
      (x) => x.id
    );
  }, [values, regions, client.screenSDown, focusedYear, worldFirst]);

  const yLabelScale = React.useMemo(() => {
    const labelHeight = 20 + 2; // Magic number based on manually measuring the label + padding
    const lookup = reduceVerticalOverlap(
      lineData,
      labelHeight,
      (x) => x.id,
      (x) => yScale(x.currentValue as $FixMe) as $FixMe
    );
    return (id: RegionId) => {
      const el = lookup.find((x) => regionIdEq.equals(x.id, id));
      return el ? el.top : 0;
    };
  }, [lineData, yScale]);

  const colorScale = React.useCallback(
    // The first item uses the reference gray (it's the "World" line)
    scaleOrdinal(
      worldFirst
        ? [
            M.grayscalePalette[5],
            ...fromCount(activeTheme.palette, lineData.length - 1),
          ]
        : fromCount(activeTheme.palette, lineData.length)
    ),
    [lineData.length]
  );

  const lineGenerator = React.useCallback(
    (line as $FixMe)()
      .defined((d: $FixMe) => d.value !== null)
      .x((d: $FixMe) => xScale(d.year))
      .y((d: $FixMe) => yScale(d.value)),
    []
  );

  const { tickValuesX, sdgRanges, tickValuesY, topLabels } = React.useMemo(
    () => ({
      tickValuesX: [yearMin, YEAR_SDG_ADOPTION, yearMax, YEAR_SDG_GOAL],
      sdgRanges: [
        [yearMin, yearMax],
        [YEAR_SDG_ADOPTION, yearMax],
      ],
      tickValuesY: rest.tickValuesY || [0, 0.25, 0.5, 0.75, 1],
      topLabels: rest.topLabels || [
        { year: yearMin, label },
        {
          year: YEAR_SDG_ADOPTION,
          label: client.screenSDown ? "" : i18n._(t`SDG adoption`),
        },
      ],
    }),
    [
      client.screenSDown,
      label,
      rest.tickValuesY,
      rest.topLabels,
      yearMax,
      yearMin,
      i18n,
    ]
  );

  React.useEffect(() => {
    const fy = Math.max(
      yearMin,
      Math.min(
        yearMax,
        pipe(
          pointerPosition,
          O.map((pos) => Math.round(xScale.invert(pos.x))),
          O.getOrElse(() => yearMax)
        )
      )
    );
    if (focusedYear !== fy) {
      setFocusedYear(fy);
    }
  }, [pointerPosition, xScale, yearMax, yearMin, focusedYear]);

  return (
    <div style={{ display: "flex", position: "relative" }}>
      <svg width={width} height={height}>
        {sdgRanges.map((range) => {
          const [from = 0, to = 0] = range.map(xScale);
          const [top, bottom] = [yScale(0), yScale(target)];
          return (
            <polygon
              key={from - to}
              fill="currentColor"
              fillOpacity={0.04}
              points={`${from},${top} ${to},${top} ${to},${bottom} ${from},${bottom}`}
            />
          );
        })}

        <mask id="line-mask">
          <motion.g
            initial={false}
            transition={springConfigs.gentle}
            animate={{ translateX: (xScale(focusedYear) as $FixMe) - width }}
          >
            <rect width={width} height={height} fill="white" />
          </motion.g>
        </mask>

        {topLabels.map((d, i) => {
          const isFirst = i === 0;
          return (
            <g
              key={d.label}
              transform={`translate(${xScale(d.year)} ${yScale(max)})`}
            >
              <Text textAnchor={rtlDir(client.isRTL, "start")} dy={"-0.6em"}>
                {d.label}
              </Text>
              {!isFirst && <circle r={2.5} fill={M.divider} />}
            </g>
          );
        })}
        {tickValuesX.map((d, i) => {
          const isFirst = i === 0;
          const isSDGAdoption = d === YEAR_SDG_ADOPTION;
          const isLast = i === tickValuesX.length - 1;
          return (
            <g key={d} transform={`translate(${xScale(d)} ${height})`}>
              {!isFirst && !isLast && (
                <line
                  x1={0}
                  x2={0}
                  y1={-PADDING.top}
                  y2={-height + PADDING.top}
                  stroke={M.divider}
                  strokeWidth={1}
                  vectorEffect="non-scaling-stroke"
                  shapeRendering="crispEdges"
                />
              )}
              <Text
                textAnchor={
                  isSDGAdoption || isLast
                    ? rtlDir(client.isRTL, "end")
                    : rtlDir(client.isRTL, "start")
                }
                dy={"-0.6em"}
              >
                {d}
              </Text>
            </g>
          );
        })}
        {tickValuesY.map((d, i) => (
          <YTick
            key={d}
            labelWidth={PADDING.left}
            yPos={i === 0 ? (yScale(d) as $FixMe) + 2 : yScale(d)}
            totalWidth={width - PADDING.right}
            label={d}
            isTarget={d === target}
            showTarget={showTarget}
            darken={true}
            formatValue={formatPercentage}
          />
        ))}

        <motion.g
          initial={false}
          transition={springConfigs.gentle}
          animate={{
            translateX: xScale(focusedYear),
            opacity: focusedYear === yearMax ? 0 : 1,
          }}
        >
          <line
            x1={0}
            x2={0}
            y1={PADDING.top}
            y2={height - (PADDING.bottom + PADDING.top)}
            stroke="black"
            strokeWidth="1"
          />
          <rect
            width="4ch"
            height="2em"
            fill="white"
            fillOpacity={0.8}
            y={height - 20}
            x={"-2ch"}
          />
          <Text y={height} textAnchor="middle" dy={"-0.6em"}>
            {focusedYear}
          </Text>
        </motion.g>

        {lineData.map(({ label, points, currentValue }) => {
          const valuePath = lineGenerator(points) || "";

          return (
            <g key={label}>
              <motion.path
                d={valuePath}
                key={label}
                fill="none"
                stroke={colorScale(label)}
                strokeWidth={2}
                strokeOpacity={0.3}
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                exit="hidden"
                variants={variants}
                initial={"hidden"}
                animate="visible"
              />

              <motion.path
                d={valuePath}
                key={label + "masked"}
                fill="none"
                stroke={colorScale(label)}
                strokeWidth={4}
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                exit="hidden"
                variants={variants}
                initial={"hidden"}
                animate="visible"
                mask="url(#line-mask)"
              />

              <motion.circle
                fill="white"
                stroke={colorScale(label)}
                strokeWidth={4}
                vectorEffect="non-scaling-stroke"
                initial={{
                  r: 0,
                  opacity: 0,
                  translateX: xScale(focusedYear),
                  translateY: yScale(currentValue as $FixMe),
                }}
                animate={{
                  r: 4,
                  opacity: 1,
                  translateX: xScale(focusedYear),
                  translateY: yScale(currentValue as $FixMe),
                }}
                transition={springConfigs.gentle}
              />
            </g>
          );
        })}
        <rect
          ref={pointerPositionRef}
          width={innerWidth}
          height={innerHeight}
          fill={"rgba(255,0,0,0)"}
        />
      </svg>
      {lineData.map((d, i) => {
        const { id, label, currentValue } = d;
        return (
          <motion.div
            key={i}
            css={css`
              pointer-events: none;
              position: absolute;
              transform: translate(${M.spacing.base8(1.5)}, -50%);
              display: flex;
              justify-content: space-between;
              white-space: nowrap;
              ${M.fontMeta};
              padding: ${M.spacing.base1(2)} ${M.spacing.base1(4)};
              border-radius: ${M.spacing.base1(2)};
              color: ${getContrastText(colorScale(label), {
                light: M.whiteText,
                dark: M.blackText,
              })};
              background: ${colorScale(label)};
              & > * + * {
                margin-left: ${client.screenSDown
                  ? M.spacing.base8(0.5)
                  : M.spacing.base8(1.5)};
                html[dir="rtl"] & {
                  margin-left: 0;
                  margin-right: ${client.screenSDown
                    ? M.spacing.base8(0.5)
                    : M.spacing.base8(1.5)};
                }
              }
            `}
            initial={{
              opacity: 0,
              left: xScale(yearMax),
              top: yLabelScale(id),
            }}
            animate={{
              opacity: 1,
              left: xScale(focusedYear),
              top: yLabelScale(id),
            }}
            transition={springConfigs.gentle}
          >
            <span>{label}</span>
            <span>{formatEndValue(currentValue as $FixMe)}</span>
          </motion.div>
        );
      })}
    </div>
  );
};

const variants = {
  visible: {
    opacity: 1,
    pathLength: 1,
    pathOffset: 0,
  },
  hidden: {
    opacity: 0,
    pathLength: 1,
    pathOffset: -1,
  },
};

interface YTickProps {
  labelWidth: number;
  totalWidth: number;
  yPos: number;
  label: number;
  isTarget: boolean;
  showTarget: boolean;
  formatValue(x: number): string;
  highlightLabel?: boolean;
  darken?: boolean;
}

export const YTick = ({
  labelWidth,
  totalWidth,
  yPos,
  label,
  isTarget,
  showTarget,
  formatValue,
  highlightLabel,
  darken,
}: YTickProps) => {
  const { client } = useTheme();

  return (
    <g transform={`translate(0 ${yPos})`}>
      <line
        x1={0}
        x2={labelWidth - 10}
        y1={0}
        y2={0}
        stroke={M.divider}
        strokeWidth={1}
        vectorEffect="non-scaling-stroke"
        shapeRendering="crispEdges"
      />
      <line
        x1={labelWidth}
        x2={totalWidth}
        y1={0}
        y2={0}
        stroke={darken ? M.divider : M.whiteText}
        strokeWidth={1}
        opacity={darken ? 1 : 0.5}
        vectorEffect="non-scaling-stroke"
        shapeRendering="crispEdges"
        pointerEvents="none"
      />
      {isTarget && showTarget && (
        <>
          {highlightLabel && (
            <line
              stroke="currentColor"
              x1={labelWidth}
              x2={totalWidth}
              strokeWidth={2}
              vectorEffect="non-scaling-stroke"
              shapeRendering="crispEdges"
              pointerEvents="none"
            />
          )}

          <g>
            {highlightLabel ? (
              <>
                <rect
                  width={40}
                  height={14}
                  y={-7}
                  rx={7}
                  fill="currentColor"
                />
                <Text fill={M.whiteText} dy={4} dx={20} textAnchor="middle">
                  {formatValue(label)}
                </Text>
              </>
            ) : (
              <>
                <circle
                  cx={totalWidth - 7}
                  fill="white"
                  stroke="currentColor"
                  vectorEffect="non-scaling-stroke"
                  strokeWidth={3}
                  r={7}
                />
                <circle
                  pointerEvents="none"
                  cx={totalWidth - 7}
                  fill="currentColor"
                  r={3}
                />
              </>
            )}
          </g>
        </>
      )}
      {!(isTarget && highlightLabel) && (
        <Text dy={"-0.6em"} textAnchor={rtlDir(client.isRTL, "start")}>
          {formatValue(label)}
        </Text>
      )}
    </g>
  );
};

export interface TextProps extends React.SVGProps<SVGTextElement> {
  children: React.ReactNode;
}

export const Text = ({
  children,
  fill = "currentColor",
  ...rest
}: TextProps) => {
  return (
    <text
      css={css`
        ${M.fontChartLabel};
      `}
      fill={fill}
      {...rest}
    >
      {children}
    </text>
  );
};
