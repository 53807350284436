import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import React from "react";
import { BarChart } from "../charts/BarChart";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_learning_fig_LEARN_REACHFINAL";
import {
  ChartRenderer,
  getRegionName,
  mkGemDataDecoder,
  regionIdIso,
} from "../domain";
import { insertionOrderSet, unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { fromCount } from "../materials";
import { io } from "../prelude";

export * from "../data/data_learning_fig_LEARN_REACHFINAL";

const statusOrder = {
  "Will not reach the last grade": 0,
  "Will reach last grade but will not learn": 1,
  "Will learn": 2,
};

const Status = io.keyof(statusOrder);
type Status = io.TypeOf<typeof Status>;

const statusLookup: Record<Status, MessageDescriptor> = {
  "Will not reach the last grade": t(
    "fig.finance.AID_TARGETING.will-not-reach"
  )`Will not reach the last grade`,
  "Will reach last grade but will not learn": t(
    "fig.finance.AID_TARGETING.will-reach-not-learn"
  )`Will reach last grade but will not learn`,
  "Will learn": t("fig.finance.AID_TARGETING.will-learn")`Will learn`,
};

export const Data = mkGemDataDecoder(["value", "region", "ind_id"], {
  status: Status,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: dataRaw }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const env = useConfig();
  const data = React.useMemo(() => {
    return dataRaw.map((x) => {
      return {
        ...x,
        status_name: i18n._(statusLookup[x.status]),
        region_name: getRegionName(env.regions, x.region),
        highlight: regionIdIso.unwrap(x.region) === "World",
      };
    });
  }, [i18n, env.regions, dataRaw]);
  const colorRange = React.useMemo(() => {
    const stati = insertionOrderSet(unsafeFromArray(data), (x) => x.status);
    return fromCount(M.colorRanges.diverging, stati.length);
  }, [data]);
  return (
    <BarChart
      tLabel={(s) => s}
      y="region_name"
      xTicks={[0, 0.2, 0.4, 0.6, 0.8, 1]}
      numberFormat={".0%"}
      color="status_name"
      colorRange={colorRange}
      colorLegend
      barStyle="small"
      values={data}
      domain={[0, 1]}
      sort={"none"}
      colorSort={"none"}
      highlight={"datum.highlight"}
      showBarValuesHighlight
      showTooltip
    />
  );
};

export default withFigureIO({
  url: require("../data/data_learning_fig_LEARN_REACHFINAL.json"),
  csv: require("../data/data_learning_fig_LEARN_REACHFINAL.zip"),
  xlsx: require("../data/data_learning_fig_LEARN_REACHFINAL.xlsx"),
  metadata,
  Data,
  Chart,
  size: "main",
  datasets: require("../data/data_learning_fig_LEARN_REACHFINAL_datasets")
    .datasets,
});
