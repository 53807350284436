import { t } from "@lingui/macro";
import React from "react";
import { ChartGrid } from "../charts-motion";
import ColorLegend from "../charts/lib/ColorLegend";
import { Line as FixedWidthLineChart } from "../charts/lib/Lines";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_quality_fig_PTR";
import {
  ChartRenderer,
  CountryId,
  countryIdIso,
  foldGeoEntity,
  getCountryName,
  getLevelName,
  mkGemEntityDecoder,
  ordLevel,
  useButtonGroupState,
  useCountryEntities,
  useFigureControlItems,
  useFigureState,
  useMultiSelectState,
  useNamedEntities,
} from "../domain";
import { useOrderedColorScale, useTheme } from "../hooks";
import { unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { io, O, pipe } from "../prelude";

export * from "../data/data_quality_fig_PTR";

const DEFAULT_COUNTRIES = ["BDI", "MLI", "UGA"];
const DEFAULT_LEVEL = "primary";

export const Data = mkGemEntityDecoder(
  ["ind_id", "level", "year", "value"],
  {}
);
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const env = useConfig();
  const { client } = useTheme();
  const [, actions] = useFigureState();
  const [selectedLevel] = useButtonGroupState("a:level");
  const [selectedCountries] = useMultiSelectState("b:countries");

  const entityData = useNamedEntities(data);
  type Datum = (typeof entityData)[number];

  const countryData = useCountryEntities(entityData);
  const countryControlItems = useFigureControlItems(
    countryData,
    (x) => countryIdIso.unwrap(x.id),
    (x) => x.entity_name
  );
  const levelControlItems = useFigureControlItems(
    entityData,
    (x) => x.level,
    (x) => getLevelName(i18n, x.level),
    { ord: ordLevel }
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "a:level",
      {
        type: "ButtonGroup",
        selected: DEFAULT_LEVEL,
      },
      levelControlItems
    );

    actions.setSelectionControl(
      "b:countries",
      {
        type: "MultiSelect",
        label: i18n._(t`Countries`),
        selected: O.some(DEFAULT_COUNTRIES),
      },
      countryControlItems
    );
  }, [i18n, actions, countryControlItems, levelControlItems]);

  const chartData = React.useMemo(() => {
    const hasCountry = (country: CountryId) =>
      pipe(
        selectedCountries,
        O.fold(
          () => false,
          (xs) => xs.includes(countryIdIso.unwrap(country))
        )
      );

    return unsafeFromArray(
      entityData.filter(
        foldGeoEntity({
          country: ({ id, level }) => hasCountry(id) && level === selectedLevel,
          region: ({ entity_type, level }) =>
            entity_type === "geo" && level === selectedLevel,
        })
      )
    );
  }, [entityData, selectedCountries, selectedLevel]);

  const selectedCountryLabels = React.useMemo(() => {
    return pipe(
      selectedCountries,
      O.map((xs) =>
        xs.map((x) => getCountryName(env.countries, countryIdIso.wrap(x)))
      )
    );
  }, [env.countries, selectedCountries]);

  const { colorScale, colorLegendValues } = useOrderedColorScale(
    selectedCountryLabels
  );

  return (
    <ChartGrid
      data={chartData}
      getCell={React.useCallback((x: Datum) => x.entity_type_name, [])}
      columnCount={2}
      minWidth={100}
    >
      {({ data, width, first }) => {
        return (
          <>
            <FixedWidthLineChart
              tLabel={(s) => s}
              width={width}
              height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
              endLabel={!client.screenMDown}
              x="year"
              timeParse="%Y"
              timeFormat="%Y"
              numberFormat={".0f"}
              yTicks={[0, 20, 40, 60, 80, 100]}
              category="datum.entity_name"
              colorLegend={!first}
              colorScale={first ? colorScale : undefined}
              values={data}
              connectLines
              showTooltip
            />
            {first && (
              <ColorLegend
                inline
                maxWidth={"100%"}
                values={colorLegendValues}
              />
            )}
          </>
        );
      }}
    </ChartGrid>
  );
};

export default withFigureIO({
  url: require("../data/data_quality_fig_PTR.json"),
  csv: require("../data/data_quality_fig_PTR.zip"),
  xlsx: require("../data/data_quality_fig_PTR.xlsx"),
  metadata,
  Data,
  Chart,
  datasets: require("../data/data_quality_fig_PTR_datasets").datasets,
});
