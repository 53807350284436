import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import useFormat from "hooks/useFormat";
import React from "react";
import { useColorScaleVDP, VerticalDotPlotAuto } from "../charts-motion";
import { withFigureIO } from "../components/figure";
import { metadata } from "../data/data_quality_fig_BULLY_FIGHT";
import {
  ChartRenderer,
  Gender,
  getGenderName,
  mkGemEntityDecoder,
  ordGender,
  useButtonGroupState,
  useCountryEntities,
  useFigureControlItems,
  useFigureState,
  useNamedEntities,
} from "../domain";
import { useTheme } from "../hooks";
import { unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { Ar, identity, io, Ord } from "../prelude";

export * from "../data/data_quality_fig_BULLY_FIGHT";

const DEFAULT_INDICATOR = "PER.11T15.BULLIED";

const Indicator = io.keyof({
  "PER.11T15.BULLIED": null,
  "SCHL.VIOL": null,
});
type Indicator = io.TypeOf<typeof Indicator>;

const indicatorLookup: Record<Indicator, MessageDescriptor> = {
  "PER.11T15.BULLIED": t(
    "fig.quality.BULLY_FIGHT.PER.11T15.BULLIED"
  )`Experienced bullying`,
  "SCHL.VIOL": t(
    "fig.quality.BULLY_FIGHT.SCHL.VIOL"
  )`Involved in physical fights`,
};

export const Data = mkGemEntityDecoder([], {
  value: io.number,
  ind_id: Indicator,
  gender: Gender,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: dataRaw }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const [, actions] = useFigureState();
  const [selectedIndicator] = useButtonGroupState("indicator");

  const entityData_ = useNamedEntities(dataRaw);
  const entityData = React.useMemo(() => {
    const data = entityData_.map((x) => ({
      ...x,
      gender_name: getGenderName(i18n, x.gender),
    }));
    type Dtm = (typeof data)[number];

    return Ar.sortBy([
      Ord.contramap((x: Dtm) => x.ind_id)(Ord.ordString),
      Ord.contramap((x: Dtm) => x.gender)(ordGender),
      Ord.contramap((x: Dtm) => x.value)(Ord.ordNumber),
    ])(data);
  }, [i18n, entityData_]);
  const countryData = useCountryEntities(entityData);
  const indicatorControlItems = useFigureControlItems(
    entityData,
    (x) => x.ind_id,
    (x) => i18n._(indicatorLookup[x.ind_id])
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "indicator",
      {
        type: "ButtonGroup",
        selected: DEFAULT_INDICATOR,
      },
      indicatorControlItems
    );
  }, [actions, indicatorControlItems]);

  const chartData = React.useMemo(() => {
    return unsafeFromArray(
      countryData.filter((x) => x.ind_id === selectedIndicator)
    );
  }, [countryData, selectedIndicator]);
  type Datum = (typeof chartData)[number];

  const getX = React.useCallback((x: Datum) => x.entity_name, []);
  const getValue = React.useCallback((x: Datum) => x.value, []);
  const getColor = React.useCallback((x: Datum) => x.gender_name, []);
  const getColorPalette = React.useCallback(() => M.colorRanges.discrete, []);
  const formatY = useFormat(".0%");

  const [colorScale, colorLegendValues] = useColorScaleVDP({
    data: chartData,
    getColor,
    colorPalette: getColorPalette,
    formatColor: identity,
  });

  return (
    <VerticalDotPlotAuto
      height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
      data={chartData}
      getX={getX}
      getValue={getValue}
      getColor={getColor}
      formatX={identity}
      formatValue={formatY}
      domainY={[0, 1]}
      colorScale={colorScale}
      colorLegendValues={colorLegendValues}
      showTooltip
      showLegend
      dotStyle={"small"}
      labelStyleX="none"
    />
  );
};

export default withFigureIO({
  url: require("../data/data_quality_fig_BULLY_FIGHT.json"),
  csv: require("../data/data_quality_fig_BULLY_FIGHT.zip"),
  xlsx: require("../data/data_quality_fig_BULLY_FIGHT.xlsx"),
  metadata,
  Data,
  Chart,
  datasets: require("../data/data_quality_fig_BULLY_FIGHT_datasets").datasets,
});
