import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { rollup, sum } from "d3-array";
import { format } from "d3-format";
import { scaleLinear } from "d3-scale";
import { tuple } from "fp-ts/lib/function";
import { pipe } from "fp-ts/lib/pipeable";
import React from "react";
import { StackedBarAuto } from "../charts-motion";
import { removeTrailingZero } from "../charts/lib/utils";
import { withFigureIO } from "../components/figure";
import { useReadScrolly } from "../components/scrolly";
import { useConfig } from "../config";
import { metadata } from "../data/data_finance_fig_GOVHH_ABS_REL";
import {
  ChartRenderer,
  CountryId,
  countryIdIso,
  getCountryMeta,
  mkGemDataDecoder,
  useButtonGroupState,
  useFigureState,
  useNamedCountries,
} from "../domain";
import { useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { An, Ar, identity, io, O, Ord } from "../prelude";

export * from "../data/data_finance_fig_GOVHH_ABS_REL";

const Indicator = io.keyof({
  FSGOV: null,
  FSHH: null,
});
type Indicator = io.TypeOf<typeof Indicator>;

const indicatorLookup: Record<Indicator, MessageDescriptor> = {
  FSGOV: t(
    "fig.finance.GOVHH_ABS_REL.FSGOV"
  )`Government funding of education as a percentage of GDP`,
  FSHH: t(
    "fig.finance.GOVHH_ABS_REL.FSHH"
  )`Household funding of education as a percentage of GDP`,
};

const indicatorLookupRel: Record<Indicator, MessageDescriptor> = {
  FSGOV: t(
    "fig.finance.GOVHH_ABS_REL.FSGOV_rel"
  )`Share of government in total education expenditure`,
  FSHH: t(
    "fig.finance.GOVHH_ABS_REL.FSHH_rel"
  )`Share of households in total education expenditure`,
};

export const Data = mkGemDataDecoder(["iso3c", "year"], {
  ind_id: Indicator,
  value: io.number,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: rawData }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const env = useConfig();
  const [, actions] = useFigureState();
  const [selectedUnit] = useButtonGroupState("unit");
  const { activeSection } = useReadScrolly();

  const [scrollyUnit, scrollyCountries] = React.useMemo(
    () =>
      pipe(
        activeSection,
        O.map((x) => x.split(",")),
        O.fold(
          () => tuple("absolute", O.none),
          (strIds) => {
            const [unit, ...countryIds] = strIds; // Expected format: "relative,KHM,TJK,UGA"
            const scrollyUnit = unit === "relative" ? "relative" : "absolute";
            return tuple(
              scrollyUnit,
              pipe(
                Ar.array.sequence(O.option)(
                  countryIds.map((mbId) =>
                    getCountryMeta(env.countries, countryIdIso.wrap(mbId))
                  )
                ),
                O.map((xs) => xs.map((meta) => meta.country)),
                O.chain(An.fromArray)
              )
            );
          }
        )
      ),
    [env.countries, activeSection]
  );

  const data = useNamedCountries(rawData);

  React.useEffect(() => {
    actions.setSelectionControl(
      "unit",
      {
        type: "ButtonGroup",
        selected: "absolute",
      },
      [
        { value: "absolute", label: i18n._(t`Percent of GDP`) },
        { value: "relative", label: i18n._(t`Ratio`) },
      ]
    );
  }, [i18n, actions]);

  React.useEffect(() => {
    if (O.isSome(activeSection)) {
      actions.updateSelectionControl("unit", scrollyUnit);
    }
  }, [i18n, actions, activeSection, scrollyUnit]);

  const isAbsolute = selectedUnit === "absolute";

  const stretchedScale = React.useMemo(() => {
    const scales = rollup(
      data,
      (xs) =>
        scaleLinear()
          .domain([0, sum(xs, (x) => x.value)])
          .range([0, 1]),
      (x) => x.iso3c
    );
    return (country: CountryId, value: number) =>
      (scales.get(country) as $FixMe)(value);
  }, [data]);

  const getCountryTotal = React.useMemo(() => {
    const lookup = rollup(
      data,
      (xs) => xs.reduce((sum, x) => sum + x.value, 0),
      (x) => x.iso3c
    );
    return (id: CountryId) => lookup.get(id) as number;
  }, [data]);

  const getHouseholdTotal = React.useMemo(() => {
    const lookup = rollup(
      data,
      (xs) => {
        const el = xs.find((x) => x.ind_id === "FSHH");
        return el != null ? stretchedScale(el.iso3c, el.value) : 0;
      },
      (x) => x.iso3c
    );
    return (id: CountryId) => lookup.get(id) as number;
  }, [data, stretchedScale]);

  const chartData = React.useMemo(() => {
    return pipe(
      data,
      Ar.map((x) =>
        isAbsolute ? x : { ...x, value: stretchedScale(x.iso3c, x.value) }
      ),
      Ar.sortBy([
        Ord.contramap((x: (typeof data)[number]) =>
          x.ind_id === "FSGOV" ? 0 : 1
        )(Ord.ordNumber),
        Ord.contramap((x: (typeof data)[number]) =>
          isAbsolute ? -getCountryTotal(x.iso3c) : -getHouseholdTotal(x.iso3c)
        )(Ord.ordNumber),
      ])
    );
  }, [isAbsolute, data, stretchedScale, getCountryTotal, getHouseholdTotal]);

  type Datum = (typeof chartData)[number];

  const getStack = React.useCallback((x: Datum) => x.country_name, []);
  const getLayer = React.useCallback((x: Datum) => x.ind_id, []);
  const getLayerLabel = React.useCallback(
    (ind_id: string) =>
      isAbsolute
        ? i18n._(indicatorLookup[ind_id as Indicator])
        : i18n._(indicatorLookupRel[ind_id as Indicator]),
    [i18n, isAbsolute]
  );
  const getValue = React.useCallback((x: Datum) => x.value || 0, []);
  const formatY = React.useMemo(
    () => removeTrailingZero(format(isAbsolute ? ".1%" : ".0%")),
    [isAbsolute]
  );
  const formatYAxisLabel = React.useMemo(() => format(".0%"), []);
  const getColorPalette = React.useCallback(() => M.colorRanges.discrete, []);

  return pipe(
    An.fromArray(chartData),
    O.map((xs) => (
      <StackedBarAuto
        key={"linter-map"}
        height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
        data={xs}
        getStack={getStack}
        getLayer={getLayer}
        getLayerLabel={getLayerLabel}
        getValue={getValue}
        formatX={identity}
        formatY={formatY}
        formatYAxisLabel={formatYAxisLabel}
        colorPalette={getColorPalette}
        showTooltip
        hideXAxisLabel
        highlightedItems={scrollyCountries}
      />
    )),
    O.toNullable
  );
};

export default withFigureIO({
  url: require("../data/data_finance_fig_GOVHH_ABS_REL.json"),
  csv: require("../data/data_finance_fig_GOVHH_ABS_REL.zip"),
  xlsx: require("../data/data_finance_fig_GOVHH_ABS_REL.xlsx"),
  metadata,
  Data,
  Chart,
  datasets: require("../data/data_finance_fig_GOVHH_ABS_REL_datasets").datasets,
});
