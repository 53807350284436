import { t } from "@lingui/macro";
import React from "react";
import { useInView } from "react-intersection-observer";
import { ScatterplotAuto } from "../charts-motion/scatterplot";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_finance_fig_GOVVGDP";
import {
  ChartRenderer,
  countryIdIso,
  getCountryMeta,
  getRegionName,
  mkGemEntityDecoder,
  mkOrdRegion,
  regionIdIso,
  useCountryEntities,
  useFigureControlItems,
  useFigureState,
  useMultiSelectState,
  useNamedEntities,
  useTimelineState,
} from "../domain";
import { useTheme } from "../hooks";
import { catOptions, extentNumber, unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { Ar, io, O, Ord, pipe } from "../prelude";

export * from "../data/data_finance_fig_GOVVGDP";

const DEFAULT_COUNTRIES = ["CMR", "BGR", "ISL", "BDI"];

export const Data = mkGemEntityDecoder(["year"], {
  "EDU.SHARE.GDP": io.number,
  "EDU.SHARE.GOV": io.number,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: dataRaw }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const env = useConfig();
  const [, actions] = useFigureState();
  const [selectedYear] = useTimelineState("year");
  const [selectedCountries] = useMultiSelectState("countries");

  const [ref, inView] = useInView({
    // rootMargin: "25%"
    // triggerOnce: true
  });

  const entityData = useNamedEntities(dataRaw);
  const countryData_ = useCountryEntities(entityData);
  const countryData = React.useMemo(() => {
    const data = countryData_.map((x) => {
      return pipe(
        getCountryMeta(env.countries, x.id),
        O.chain((meta) =>
          pipe(
            O.fromNullable(meta.income),
            O.fold(
              () => O.none,
              (income) => O.some({ ...meta, income })
            )
          )
        ),
        O.map((meta) => ({
          ...x,
          region: meta.income,
          region_name: getRegionName(env.regions, meta.income),
        }))
      );
    });
    return pipe(
      data,
      catOptions,
      Ar.sortBy([
        mkOrdRegion<$FixMe>(env.regions, (x) => x.region),
        Ord.contramap((x: { year: number }) => x.year)(Ord.ordNumber),
      ])
    );
  }, [env.countries, env.regions, countryData_]);

  const countryControlItems = useFigureControlItems(
    countryData,
    (x) => countryIdIso.unwrap(x.id),
    (x) => x.entity_name
  );

  const yearExtent = React.useMemo(
    () =>
      extentNumber(unsafeFromArray(countryData), (x) => x.year) as [
        number,
        number,
      ],
    [countryData]
  );

  React.useEffect(() => {
    actions.setToggleControl("year", {
      type: "Timeline",
      range: yearExtent,
      selected: yearExtent[1],
      checked: inView,
    });
  }, [inView, yearExtent, actions]);

  React.useEffect(() => {
    actions.setSelectionControl(
      "countries",
      {
        type: "MultiSelect",
        label: i18n._(t`Countries`),
        minItems: 0,
        maxItems: 5,
        selected: O.some(DEFAULT_COUNTRIES),
      },
      countryControlItems
    );

    actions.setToggleControl("year", {
      type: "Timeline",
      range: yearExtent,
      selected: yearExtent[1],
      checked: false,
    });
  }, [i18n, actions, countryControlItems, yearExtent]);

  const chartData = unsafeFromArray(countryData);
  type Datum = (typeof chartData)[number];

  const getX = React.useCallback((x: Datum) => x?.["EDU.SHARE.GDP"], []);
  const getY = React.useCallback((x: Datum) => x?.["EDU.SHARE.GOV"], []);
  const getColor = React.useCallback(
    (x: Datum) => regionIdIso.unwrap(x.region),
    []
  );
  const getGroup = React.useCallback(
    (x: Datum) => countryIdIso.unwrap(x.id),
    []
  );

  const formatColor = React.useCallback((x: Datum) => x.region_name, []);
  const formatGroup = React.useCallback((x: Datum) => x.entity_name, []);
  const annotations = React.useMemo(
    () => ({
      x: [0.04, 0.06],
      y: [0.15, 0.2],
      labels: [
        {
          label: i18n._(t`Benchmark`),
          x: 0.04,
          y: 0.2,
          dx: "0.4em",
          dy: "1.2em",
        },
      ],
    }),
    [i18n]
  );
  const xTicks = React.useMemo(() => [0, 0.09], []);
  const yTicks = React.useMemo(() => [0, 0.3], []);

  return (
    <div ref={ref}>
      <ScatterplotAuto
        height={
          client.screenSDown
            ? M.chartHeight.s
            : client.screenMDown
              ? M.chartHeight.m
              : M.chartHeight.l
        }
        data={chartData}
        getX={getX}
        getY={getY}
        getColor={getColor}
        getGroup={getGroup}
        xLabel={i18n._(t("EDU.SHARE.GDP")`Share of GDP`)}
        yLabel={i18n._(t("EDU.SHARE.GOV")`Share of government expenditure`)}
        formatColor={formatColor}
        formatGroup={formatGroup}
        xTicks={xTicks}
        yTicks={yTicks}
        selectedYear={selectedYear}
        highlightedGroups={selectedCountries}
        annotations={annotations}
        onSelect={(x) =>
          actions.toggleMultiSelectItem("countries", countryIdIso.unwrap(x.id))
        }
      />
    </div>
  );
};

export default withFigureIO({
  url: require("../data/data_finance_fig_GOVVGDP.json"),
  csv: require("../data/data_finance_fig_GOVVGDP.zip"),
  xlsx: require("../data/data_finance_fig_GOVVGDP.xlsx"),
  metadata,
  Data,
  Chart,
  datasets: require("../data/data_finance_fig_GOVVGDP_datasets").datasets,
});
