import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/pipeable";
import * as io from "io-ts";
import { stringify } from "query-string";
import * as ta from "type-assertions";
import { omit } from "./lib/record.extended";
import { Locale, locales } from "./locales";
//import * as fs from "fs";
//import * as path from "path";
import { focusArticles } from "./focus";

// -----------------------------------------------------------------------------
// Route Aliases

const pageAliasesK = {
  home: null,
  terms: null,
  about: null,
  indicators: null,
  embed: null,
  figure: null,
  figures: null,
  markdown: null,
  focusPosts: null,
};

const articleAliasesK = {
  access: null,
  equity: null,
  learning: null,
  trajectories: null,
  quality: null,
  finance: null,
  financeAid: null,
};

const themesAliasesK = {
  access: null,
  equity: null,
  learning: null,
  quality: null,
  finance: null,
};

const focusAliasesK = focusArticles.published.reduce(
  (acc, curr) => ({ ...acc, [curr]: null }),
  {}
);

export const RAlias = io.keyof({
  ...pageAliasesK,
  ...articleAliasesK,
  ...focusAliasesK,
});

export type RAlias = io.TypeOf<typeof RAlias>;
export const pageAliases = Object.keys(RAlias.keys) as Array<RAlias>;

export const RAliasArticle = io.keyof(articleAliasesK);
export type RAliasArticle = io.TypeOf<typeof RAliasArticle>;
export const articleAliases = Object.keys(
  RAliasArticle.keys
) as Array<RAliasArticle>;

export const RAliasTheme = io.keyof(themesAliasesK);
export type RAliasTheme = io.TypeOf<typeof RAliasTheme>;
export const themeAliases = Object.keys(RAliasTheme.keys) as Array<RAliasTheme>;

export const subthemesIndex: Record<RAliasTheme, RAliasArticle[]> = {
  access: ["access"],
  equity: ["equity"],
  learning: ["learning", "trajectories"],
  quality: ["quality"],
  finance: ["finance", "financeAid"],
};

export const foldRAlias = <A>(
  alias: RAlias,
  pattern: { [K in RAlias]: () => A }
): A => {
  return pattern[alias]();
};

export const foldRAlias_ =
  <A>(pattern: { [K in RAlias]: () => A }) =>
  (alias: RAlias) =>
    foldRAlias(alias, pattern);

export const foldRAliasArticle = <A>(
  alias: RAliasArticle,
  pattern: { [K in RAliasArticle]: () => A }
): A => {
  return pattern[alias]();
};

export const foldRAliasArticle_ =
  <A>(pattern: { [K in RAliasArticle]: () => A }) =>
  (alias: RAliasArticle) =>
    foldRAliasArticle(alias, pattern);

export const foldRAliasTheme = <A>(
  alias: RAliasTheme,
  pattern: { [K in RAliasTheme]: () => A }
): A => {
  return pattern[alias]();
};
export const foldRAliasTheme_ =
  <A>(pattern: { [K in RAliasTheme]: () => A }) =>
  (alias: RAliasTheme) =>
    foldRAliasTheme(alias, pattern);

export function findArticleAlias(route: RAlias): O.Option<RAliasArticle> {
  return O.fromPredicate<RAliasArticle>((x) => articleAliases.includes(x))(
    route as RAliasArticle
  );
}

export function findArticleThemeAlias(article: RAliasArticle): RAliasTheme {
  const themes = Object.keys(subthemesIndex) as Array<RAliasTheme>;
  return themes.find((t) => subthemesIndex[t].includes(article)) as RAliasTheme;
}

export function findArticleAliasRelaxed(
  route?: string
): O.Option<RAliasArticle> {
  return O.fromPredicate<RAliasArticle>((x) => articleAliases.includes(x))(
    route as RAliasArticle
  );
}

export function findThemeAliasRelaxed(route?: string): O.Option<RAliasTheme> {
  return O.fromPredicate<RAliasTheme>((x) => themeAliases.includes(x))(
    route as RAliasTheme
  );
}

export function findArticleAliasIndex(articleAlias: RAliasArticle) {
  return articleAliases.findIndex((x) => x === articleAlias);
}

export function findThemeAliasIndex(themeAlias: RAliasTheme) {
  return themeAliases.findIndex((x) => x === themeAlias);
}

export function matchesArticleAlias(
  selectedArticle: O.Option<RAliasArticle>,
  articleAlias: RAliasArticle
) {
  return pipe(
    selectedArticle,
    O.filter((x) => x === articleAlias),
    O.isSome
  );
}

export function getArticleName(i18n: I18n, alias: RAliasArticle) {
  return foldRAliasArticle(alias, {
    access: () => i18n._(t`Access`),
    equity: () => i18n._(t`Equity`),
    learning: () => i18n._(t`Learning outcomes`),
    trajectories: () => i18n._(t`Learning trajectories`),
    quality: () => i18n._(t`Quality`),
    finance: () => i18n._(t`Finance – Governments and households`),
    financeAid: () => i18n._(t`Finance – Aid`),
  });
}

// -----------------------------------------------------------------------------
// Env

const REnvBase = io.type({
  alias: RAlias,
  locale: Locale,
});
export const REnv = io.intersection([
  REnvBase,
  io.partial({ figure: io.string }),
]);
export type REnv = io.TypeOf<typeof REnv>;

export const REnvPlus = <Q extends io.Mixed>(queryCodec: Q) =>
  io.intersection([REnv, queryCodec]);
export type REnvPlus<Q> = REnv & Q;

export type Route = typeof routes;

export type RQuery<R extends RAlias> = Omit<
  io.TypeOf<Route[R]["decoder"]>,
  "alias" | "locale"
>;

// -----------------------------------------------------------------------------
// Route definition

export const DEFAULT_ROUTE: RAlias = "home";

export const routes = {
  home: { ...localizedRoute("/index"), decoder: REnv },
  about: { ...localizedRoute("/about"), decoder: REnv },
  terms: { ...localizedRoute("/terms"), decoder: REnv },
  indicators: { ...localizedRoute("/indicators"), decoder: REnv },
  focusPosts: { ...localizedRoute("/focus-articles"), decoder: REnv },
  embed: {
    filePath: formatFilePath("/_embed"),
    publicPath: formatPublicPathL("/embed"),
    decoder: REnvPlus(io.type({ id: io.string })),
  },
  figure: {
    filePath: formatFilePath("/_figure"),
    publicPath: formatPublicPathL("/figure"),
    decoder: REnvPlus(
      io.intersection([
        io.type({ id: io.string }),
        io.partial({ dataset: io.string }),
      ])
    ),
  },
  figures: {
    filePath: formatFilePath("/_figures"),
    publicPath: formatPublicPathL("/figures"),
    decoder: REnvPlus(io.type({ id: io.string })),
  },
  markdown: {
    filePath: formatFilePath("/_markdown"),
    publicPath: formatPublicPathL("/_markdown"),
    decoder: REnv,
  },

  access: { ...localizedRoute("/articles/access"), decoder: REnv },
  equity: { ...localizedRoute("/articles/equity"), decoder: REnv },
  learning: { ...localizedRoute("/articles/learning"), decoder: REnv },
  trajectories: { ...localizedRoute("/articles/trajectories"), decoder: REnv },
  quality: { ...localizedRoute("/articles/quality"), decoder: REnv },
  finance: { ...localizedRoute("/articles/finance"), decoder: REnv },
  financeAid: { ...localizedRoute("/articles/finance-aid"), decoder: REnv },
};

const NOINDEX_ROUTES = ["/figures", "/_markdown"];
export const isNoIndexRoute = (route: string) => NOINDEX_ROUTES.includes(route);

// Typelevel assertion to ensure all routes defined in RAlias are actually
// implemented. We use this because we can't infer the value-side of objects
// that are not homogenous.
export type assertRoutes1 = ta.Assert<
  ta.UnionIncludesExact<RAlias, keyof typeof routes>
>;
export type assertRoutes2 = ta.Assert<
  ta.UnionIncludesExact<keyof typeof routes, RAlias>
>;

export const getFocusMetadata = (alias: RAlias, locale: Locale) => {
  const route = localizedRoute(`focus/${alias}`);
  const filePath = route.filePath(locale);
  const { title, lead, year, article } = require(`./pages/${filePath}.mdx`);
  return {
    alias,
    title,
    lead,
    year,
    article,
  };
};

/**
 * Get an alias's route representation for use in Next.js links
 */
export const getRoute =
  (alias: RAlias) =>
  <Q extends REnv>(query: Q) => {
    const route = routes[alias] || localizedRoute(`/focus/${alias}`);
    const publicPath = route.publicPath(query.locale);
    const envKeys = Object.keys(REnvBase.props) as Array<keyof REnv>;
    const search = stringify(omit(query, envKeys));
    return {
      href: {
        pathname: route.filePath(query.locale),
        query,
      },
      as: search ? `${publicPath}?${search}` : publicPath,
    };
  };

// -----------------------------------------------------------------------------
// Formatters

export function formatFilePath(path: string) {
  return () => `${path}`;
}

export function formatFilePathL(path: string) {
  return (locale: Locale) => `${path}.${locale}`;
}

export function formatPublicPath(path: string) {
  return () => `${path === "/index" ? "/" : path}`;
}

export function formatPublicPathL(path: string) {
  return (locale: Locale) => `/${locale}${path === "/index" ? "" : path}`;
}

export function localizedRoute(path: string) {
  return {
    filePath: formatFilePathL(path),
    publicPath: formatPublicPathL(path),
  };
}

// -----------------------------------------------------------------------------
// Code Gen

/**
 * Generate a pathmap for use with Next's exportPathMap configuration during
 * server-side rendering.
 */
export const genPathMap = () => {
  const routeAliases = Object.keys(routes) as Array<RAlias>;
  const allFocusAliases = Object.values(focusArticles).reduce(
    (acc, val) => acc.concat(val),
    []
  );

  const focusRoutes = allFocusAliases.reduce<
    Record<string, { page: string; query: REnv }>
  >((acc, alias) => {
    const route = localizedRoute(`/focus/${alias}`);
    locales.map(({ lang: locale }) => {
      acc[route.publicPath(locale)] = {
        page: route.filePath(locale),
        query: { alias: alias as RAlias, locale },
      };
    });
    return acc;
  }, {});

  const staticRoutes = routeAliases.reduce<
    Record<string, { page: string; query: REnv }>
  >((acc, alias) => {
    const route = routes[alias];
    locales.map(({ lang: locale }) => {
      acc[route.publicPath(locale)] = {
        page: route.filePath(locale),
        query: { alias, locale },
      };
    });
    return acc;
  }, {});

  return {
    ...focusRoutes,
    ...staticRoutes,
  };
};

export const genVercelConfig = () => {
  const pathMap = genPathMap();

  return {
    rewrites: [
      { source: "/docs", destination: "/_catalog" },
      ...Object.entries(pathMap).map(([k, v]) => ({
        source: k,
        destination: `${v.page}?locale=${v.query.locale}&alias=${v.query.alias}`,
      })),
    ],
  };
};
