import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import useFormat from "hooks/useFormat";
import React from "react";
import { useColorScaleVDP, VerticalDotPlotAuto } from "../charts-motion";
import { withFigureIO } from "../components/figure";
import { metadata } from "../data/data_learning_fig_ASSESS";
import {
  ChartRenderer,
  Gender,
  getGenderName,
  mkGemEntityDecoder,
  ordGender,
  useButtonGroupState,
  useFigureControlItems,
  useFigureState,
  useNamedEntities,
} from "../domain";
import { useTheme } from "../hooks";
import { unsafeFromArray } from "../lib";
import { useI18n } from "../locales";
import * as M from "../materials";
import { Ar, identity, io, Ord } from "../prelude";

export * from "../data/data_learning_fig_ASSESS";

const DEFAULT_INDICATOR = "READ";

const Indicator = io.keyof({
  READ: null,
  MATH: null,
});
type Indicator = io.TypeOf<typeof Indicator>;

const indicatorLookup: Record<Indicator, MessageDescriptor> = {
  READ: t("fig.learning.ASSESS.READ")`Reading`,
  MATH: t("fig.learning.ASSESS.MATH")`Mathematics`,
};

const indicatorOrd: Record<Indicator, number> = {
  READ: 0,
  MATH: 1,
};

export const Data = mkGemEntityDecoder([], {
  ind_id: Indicator,
  gender: Gender,
  value: io.number,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data: dataRaw }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const [, actions] = useFigureState();
  const [selectedIndicator] = useButtonGroupState("indicator");

  const entityData_ = useNamedEntities(dataRaw);
  const entityData = React.useMemo(() => {
    type Dtm = (typeof data)[number];
    const data = entityData_.map((x) => ({
      ...x,
      gender_name: getGenderName(i18n, x.gender),
      ind_name: i18n._(indicatorLookup[x.ind_id]),
    }));
    return Ar.sortBy([
      Ord.contramap((x: Dtm) => indicatorOrd[x.ind_id])(Ord.ordNumber),
      Ord.contramap((x: Dtm) => x.gender)(ordGender),
      Ord.contramap((x: Dtm) => x.value)(Ord.ordNumber),
    ])(data);
  }, [i18n, entityData_]);

  const indicatorControlItems = useFigureControlItems(
    entityData,
    (x) => x.ind_id,
    (x) => i18n._(indicatorLookup[x.ind_id])
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "indicator",
      {
        type: "ButtonGroup",
        selected: DEFAULT_INDICATOR,
      },
      [...indicatorControlItems].reverse()
    );
  }, [actions, indicatorControlItems]);

  const chartData = React.useMemo(() => {
    return unsafeFromArray(
      entityData.filter((x) => x.ind_id === selectedIndicator)
    );
  }, [entityData, selectedIndicator]);

  type Datum = (typeof chartData)[number];

  const getColor = React.useCallback((x: Datum) => x.gender_name, []);
  const getValue = React.useCallback((x: Datum) => x.value, []);
  const getX = React.useCallback((x: Datum) => x.entity_name, []);
  const getColorPalette = React.useCallback(() => M.colorRanges.discrete, []);
  const formatY = useFormat(".0%");

  const [colorScale, colorLegendValues] = useColorScaleVDP({
    data: chartData,
    getColor,
    colorPalette: getColorPalette,
    formatColor: identity,
  });

  return (
    <VerticalDotPlotAuto
      height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
      data={chartData}
      getX={getX}
      getValue={getValue}
      getColor={getColor}
      formatX={identity}
      formatValue={formatY}
      domainY={[0, 1]}
      colorScale={colorScale}
      colorLegendValues={colorLegendValues}
      showTooltip
      showLegend
      dotStyle={"small"}
      labelStyleX="none"
    />
  );
};

export default withFigureIO({
  url: require("../data/data_learning_fig_ASSESS.json"),
  csv: require("../data/data_learning_fig_ASSESS.zip"),
  xlsx: require("../data/data_learning_fig_ASSESS.xlsx"),
  metadata,
  Data,
  Chart,
  datasets: require("../data/data_learning_fig_ASSESS_datasets").datasets,
});
