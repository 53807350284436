import css from "@emotion/css";
import { t, Trans } from "@lingui/macro";
import { pipe } from "fp-ts/lib/pipeable";
import React from "react";
import {
  useStatefulFigureScreenshotUrl,
  useStatefulFigureEmbedUrl,
} from "../figures";
import { mkStyles, useFocusVisible } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { O } from "../prelude";
import { buttonReset } from "./button";
import { ReactComponent as FacebookIcon } from "./footer/ic_social-media-facebook_16dp.svg";
import { ReactComponent as LinkedinIcon } from "./footer/ic_social-media-linkedin_16dp.svg";
import { ReactComponent as TwitterIcon } from "./footer/ic_social-media-twitter_16dp.svg";
import { InteractiveTooltip } from "./tooltip";
import { ReactComponent as DownloadDataIcon } from "./tooltip-triggers/ic_download-data_16dp.svg";
import { ReactComponent as DownloadImageIcon } from "./tooltip-triggers/ic_download-image_16dp.svg";
import { ReactComponent as DownloadIcon } from "./tooltip-triggers/ic_download_32dp.svg";
import { ReactComponent as InfoIcon } from "./tooltip-triggers/ic_info_32dp.svg";
import { ReactComponent as ResetIcon } from "./tooltip-triggers/ic_reset_32dp.svg";
import { ReactComponent as ShareIcon } from "./tooltip-triggers/ic_share_32dp.svg";
import { ReactComponent as CopyIcon } from "./tooltip-triggers/ic_copy_16dp.svg";
import { ReactComponent as CheckIcon } from "./tooltip-triggers/ic_check_16dp.svg";
import { useStateWithAutoRevert } from "hooks/useStateWithAutoRevert";
import { AnimatePresence, motion } from "framer-motion";

// It takes about this many milliseconds to capture a screenshot
const DOWNLOAD_TIMEOUT = 10000;

const useIconStyles = mkStyles((theme) => ({
  root: css`
    ${buttonReset};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: ${M.spacing.base8(4)};
    height: ${M.spacing.base8(4)};
    background: ${theme.palette.text.white};
    color: ${M.unescoMainBlue};
    cursor: pointer;
    outline: none;
  `,
  trigger: css`
    border-radius: ${M.spacing.base8(4)};
    box-shadow: 0 0 0 1px ${theme.palette.grayscale[3]};
    &:hover {
      background: ${theme.palette.grayscale[3]};
    }
  `,
  grayBackground: css`
    background: ${theme.palette.grayscale[2]};
  `,
  focus: css`
    outline: 2px solid ${theme.palette.action.main};
    outline-offset: 2px;
  `,
}));

const resetStyles = css`
  color: ${M.whiteText};
  background: ${M.unescoMarineBlue};

  &:hover {
    background: ${M.unescoDarkBlue};
  }
`;

export const InfoButton = ({
  gray,
  content,
  source,
}: {
  content: React.ReactChild;
  source?: React.ReactChild;
  gray?: boolean;
}) => {
  const iconStyles = useIconStyles();
  const { focusVisible, ...handleFocus } = useFocusVisible();

  return (
    <InteractiveTooltip
      content={[
        {
          title: <Trans>Information</Trans>,
          body: content,
          source: source,
        },
      ]}
    >
      <button
        css={[
          iconStyles.root,
          iconStyles.trigger,
          focusVisible && iconStyles.focus,
          gray && iconStyles.grayBackground,
        ]}
        {...handleFocus}
      >
        <InfoIcon />
      </button>
    </InteractiveTooltip>
  );
};

function currentUrlWithFigureId(url: string, id?: string) {
  const [withoutQuery] = url.split("?");
  const [withoutId] = withoutQuery.split("#");

  return `${withoutId}${id ? `#${id}` : ""}`;
}

export type ShareButtonProps = {
  id?: string;
  message: string;
  gray?: boolean;
  disableEmbed?: boolean;
  dataset?: string;
};

export const ShareButton = ({
  id,
  message,
  gray,
  disableEmbed,
  dataset,
}: ShareButtonProps) => {
  const i18n = useI18n();
  const iconStyles = useIconStyles();
  const { focusVisible, ...handleFocus } = useFocusVisible();
  const screenshotUrl =
    typeof window !== "undefined"
      ? `${currentUrlWithFigureId(window.location.href, id)}`
      : SITE_URL;

  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    message
  )}&url=${encodeURIComponent(screenshotUrl)}&via=${"GEMReport"}`;

  const facebookUrl = `https://www.facebook.com/dialog/feed?app_id=${FACEBOOK_APP_ID}&link=${encodeURIComponent(
    screenshotUrl
  )}&caption=${encodeURIComponent(message)}&name=${encodeURIComponent(
    encodeURIComponent(i18n._(t("meta.title")`GEM Report Education Progress`))
  )}`;

  const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
    screenshotUrl
  )}&title=${encodeURIComponent(
    i18n._(t("meta.title")`GEM Report Education Progress`)
  )}&summary=${encodeURIComponent(message)}`;

  const content = React.useMemo(() => {
    const content = [
      {
        title: <Trans>Share</Trans>,
        body: (
          <ul>
            <ListLinkButton
              key="share.twitter"
              url={twitterUrl}
              icon={<TwitterIcon />}
            >
              <Trans>Twitter</Trans>
            </ListLinkButton>
            <ListLinkButton
              key="share.facebook"
              url={facebookUrl}
              icon={<FacebookIcon />}
            >
              <Trans>Facebook</Trans>
            </ListLinkButton>
            <ListLinkButton
              key="share.linkedin"
              url={linkedinUrl}
              icon={<LinkedinIcon />}
            >
              <Trans>LinkedIn</Trans>
            </ListLinkButton>
          </ul>
        ),
      },
    ];

    if (!disableEmbed && id) {
      content.push({
        title: <Trans>Embed code</Trans>,
        body: (
          <ul>
            <ListEmbedButton id={id} dataset={dataset} />
          </ul>
        ),
      });
    }

    return content;
  }, [disableEmbed, facebookUrl, id, linkedinUrl, twitterUrl, dataset]);

  return (
    <InteractiveTooltip content={content}>
      <button
        css={[
          iconStyles.root,
          iconStyles.trigger,
          focusVisible && iconStyles.focus,
          gray && iconStyles.grayBackground,
        ]}
        {...handleFocus}
      >
        <ShareIcon />
      </button>
    </InteractiveTooltip>
  );
};

export const ResetButton = () => {
  const iconStyles = useIconStyles();

  return (
    <div css={[iconStyles, resetStyles]}>
      <ResetIcon />
    </div>
  );
};

export const DownloadButton = ({
  id,
  csv,
  xlsx,
  gray,
}: {
  id: string;
  csv: string;
  xlsx: string;
  gray?: boolean;
}) => {
  const iconStyles = useIconStyles();
  const downloadUrl = useStatefulFigureScreenshotUrl(id);
  const { focusVisible, ...handleFocus } = useFocusVisible();

  return (
    <InteractiveTooltip
      content={[
        {
          title: <Trans>Download</Trans>,
          body: (
            <ul>
              {pipe(
                downloadUrl,
                O.map((url) => (
                  <ListLinkButton
                    key="download.image"
                    url={url}
                    icon={<DownloadImageIcon />}
                    timeout={DOWNLOAD_TIMEOUT}
                    message={
                      <Trans id="download.wait">
                        Download will start shortly …
                      </Trans>
                    }
                  >
                    <Trans id="download.image">Figure as .png image</Trans>
                  </ListLinkButton>
                )),
                O.toNullable
              )}
              <ListLinkButton
                key="download.xlsx"
                url={xlsx}
                icon={<DownloadDataIcon />}
              >
                <Trans id="download.xlsx">Data as Excel file</Trans>
              </ListLinkButton>
              <ListLinkButton
                key="download.csv"
                url={csv}
                icon={<DownloadDataIcon />}
              >
                <Trans id="download.csv">Data as .csv file</Trans>
              </ListLinkButton>
            </ul>
          ),
        },
      ]}
    >
      <button
        css={[
          iconStyles.root,
          iconStyles.trigger,
          focusVisible && iconStyles.focus,
          gray && iconStyles.grayBackground,
        ]}
        {...handleFocus}
      >
        <DownloadIcon />
      </button>
    </InteractiveTooltip>
  );
};

const useListLinkButtonStyle = mkStyles(
  (theme) => css`
    ${M.fontTable};
    display: flex;
    align-items: center;
    margin: 0 -${M.spacing.base8(1)};
    color: ${M.blackText};
    &:hover,
    &:hover > div {
      background: ${theme.palette.grayscale[3]};
      color: ${M.unescoMarineBlue};
    }
  `
);

const ListLinkButton = ({
  children,
  icon,
  url,
  timeout,
  message,
}: $PropsWithChildren<{
  icon: React.ReactNode;
  url: string;
  timeout?: number;
  message?: React.ReactNode;
}>) => {
  const rootStyles = useListLinkButtonStyle();
  const iconStyles = useIconStyles();
  const { focusVisible, ...handleFocus } = useFocusVisible();
  const [isDownloading, setIsDownloading] = React.useState(false);

  const handleClick = React.useCallback(() => {
    timeout != null && setIsDownloading(true);
  }, [timeout]);

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (timeout != null) {
      timeoutId = setTimeout(() => setIsDownloading(false), timeout);
    }
    return () => clearTimeout(timeoutId);
  }, [timeout, isDownloading]);

  return (
    <li>
      <a href={url} css={rootStyles} onClick={handleClick}>
        <div
          css={[iconStyles.root, focusVisible && iconStyles.focus]}
          {...handleFocus}
        >
          {icon}
        </div>
        {isDownloading && message ? message : children}
      </a>
    </li>
  );
};

const listEmbedButtonStyle = css`
  display: flex;
  direction: ltr;
  margin-bottom: ${M.spacing.base8(1)};

  & input {
    width: 200px;
    padding-top: ${M.spacing.base8(0.75)};
    padding-right: ${M.spacing.base8(0.5)};
    padding-bottom: ${M.spacing.base8(0.75)};
    padding-left: ${M.spacing.base8(1.5)};
    background-color: ${M.grayscalePalette[1]};
    border: 1px solid ${M.grayscalePalette[3]};
    border-top-left-radius: ${M.spacing.base8(0.5)};
    border-bottom-left-radius: ${M.spacing.base8(0.5)};
    outline: none;
    color: ${M.unescoMainBlue};
    ${M.fontTable};
  }

  & button {
    ${buttonReset};
    padding: ${M.spacing.base8(1)};
    background-color: ${M.unescoMainBlue};
    border-top-right-radius: ${M.spacing.base8(0.5)};
    border-bottom-right-radius: ${M.spacing.base8(0.5)};
    color: ${M.whiteText};
    cursor: pointer;
    transition: background-color
      ${M.opacityTransitionMotionProps.transition.duration * 2}s;

    &:hover {
      background-color: ${M.unescoMarineBlue};
    }
  }
`;

const ListEmbedButton = ({ id, dataset }: { id: string; dataset?: string }) => {
  const iconStyles = useIconStyles();
  const { focusVisible, ...handleFocus } = useFocusVisible();
  const [success, setSuccess] = useStateWithAutoRevert({
    timeout: 1500,
    revertState: false,
  });
  const url = useStatefulFigureEmbedUrl(id, dataset);
  return (
    <li css={listEmbedButtonStyle}>
      {pipe(
        url,
        O.map((url) => {
          const iframeHTML = `<iframe src="${url}" />`;
          return (
            <>
              <input type="text" value={iframeHTML} disabled />
              <button
                onClick={() => {
                  navigator.clipboard.writeText(iframeHTML);
                  setSuccess(true);
                }}
                css={[
                  focusVisible && iconStyles.focus,
                  success && {
                    backgroundColor: `${M.unescoMainGreen} !important;`,
                  },
                ]}
                {...handleFocus}
              >
                <AnimatePresence exitBeforeEnter>
                  {success ? (
                    <motion.div
                      key="success"
                      {...M.opacityTransitionMotionProps}
                    >
                      <CheckIcon />
                    </motion.div>
                  ) : (
                    <motion.div key="copy" {...M.opacityTransitionMotionProps}>
                      <CopyIcon />
                    </motion.div>
                  )}
                </AnimatePresence>
              </button>
            </>
          );
        }),
        O.toNullable
      )}
    </li>
  );
};
