import css from "@emotion/css";
import styled from "@emotion/styled";
import { FigureControlPosition } from "domain/figure-state";
import * as React from "react";
import * as M from "../materials";

export interface Group<T> {
  label: string;
  options: Choice<T>[];
}

export interface Choice<T> {
  value: string;
  label: React.ReactNode;
  data?: T;
}

export type Choices<T> = Array<Choice<T> | Group<T>>;

function foldChoices1<T, R>(
  options: Choices<T>,
  a: R,
  f: (a: R, o: Choice<T>) => R
): R {
  let ret = a;

  for (const option of options) {
    if ("value" in option) {
      ret = f(ret, option);
    } else {
      ret = foldChoices1(option.options, ret, f);
    }
  }

  return ret;
}

export function findChoice<T>(
  options: Choices<T>,
  value: string
): undefined | Choice<T> {
  for (const option of options) {
    if ("value" in option) {
      if (option.value === value) {
        return option;
      }
    } else {
      const opt = findChoice(option.options, value);
      if (opt) {
        return opt;
      }
    }
  }

  return undefined;
}

interface SelectProps<T> {
  transparent?: boolean;
  inverted?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
  title?: string;
  label?: string;
  value: string;
  position?: FigureControlPosition;
  options: Choices<T>;
  onChange?: (v: Choice<T>) => void;
}

export const Select = React.memo(Select_);
function Select_<T extends Record<string, unknown>>({
  icon,
  value,
  options,
  onChange,
  title,
  label,
  position = "TOP",
  transparent = false,
  inverted = false,
  disabled = false,
}: SelectProps<T>) {
  const handleOnChange = React.useCallback(
    (v: React.ChangeEvent<HTMLSelectElement>) => {
      if (onChange) {
        const option = findChoice(options, v.currentTarget.value);
        if (option) {
          onChange(option);
        }
      }
    },
    [options, onChange]
  );
  /*
   * Forcefully disable the control if it contains fewer than two items.
   */
  const numOptions = foldChoices1(options, 0, (a, _) => a + 1);
  const isDisabled = numOptions <= 1 || disabled || false;

  return (
    <>
      {title && (
        <h4
          css={css`
            ${M.fontHeading4}
            margin-bottom: ${M.spacing.base8(0.5)};
          `}
        >
          {title}
        </h4>
      )}
      <Root
        disabled={isDisabled}
        transparent={transparent}
        inverted={inverted}
        icon={!!icon}
        data-control-type="select"
        position={position}
      >
        <select value={value} disabled={disabled} onChange={handleOnChange}>
          <option label=" "></option>
          {options.map((option, i) => {
            if ("value" in option) {
              return (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              );
            } else {
              return (
                <optgroup key={i} label={option.label}>
                  {option.options.map((option, j) => (
                    <option key={j} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </optgroup>
              );
            }
          })}
        </select>

        <Overlay>
          {icon && <Icon>{icon}</Icon>}
          <SelectedOptionLabel
            selectedOption={findChoice(options, value)}
            icon={!!icon}
            label={label}
          />
          {!disabled && <Triangle />}
        </Overlay>
      </Root>
    </>
  );
}

const Overlay = styled("div")`
  pointer-events: none;
`;

const Icon = styled("div")`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  svg {
    display: block;
  }
`;

const SelectedOptionLabelText = styled("div")`
  ${M.fontTable};
  appearance: none;
  position: absolute;
  left: 0;
  bottom: 0;
  color: currentColor;
  display: block;
  outline: none;
  overflow: hidden;
  padding: 6px 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 95%;

  html[dir="rtl"] & {
    left: unset;
    right: 0;
  }
`;

const SelectedOptionLabel = <T extends Record<string, unknown>>({
  selectedOption,
  label,
}: {
  selectedOption: Choice<T> | undefined;
  icon: boolean;
  label?: string;
}) => {
  // We want to set the label message on the title too, so the non-truncated text shows in a tooltip
  // BUT: we might get a <FormattedMessage> or something similar which may not be a string, so we have to check
  // The solution would be just to accept strings but that has bigger implications (it's not impossible though!)
  return selectedOption ? (
    <SelectedOptionLabelText
      title={
        typeof selectedOption.label === "string"
          ? selectedOption.label
          : undefined
      }
    >
      {selectedOption.label}
    </SelectedOptionLabelText>
  ) : (
    <SelectedOptionLabelText title={label}>{label}</SelectedOptionLabelText>
  );
};

interface RootProps {
  disabled: boolean;
  transparent: boolean;
  inverted: boolean;
  icon: boolean;
  position: FigureControlPosition;
}

const Root = styled("div")<RootProps>`
  position: relative;
  padding: 4px 12px;
  background: ${(p) =>
    p.transparent ? "transparent" : p.inverted ? M.blackText : M.whiteText};
  border: 1px solid
    ${(p) => (p.inverted ? M.grayscalePalette[1] : M.grayscalePalette[4])};
  color: ${(p) => (p.inverted ? M.grayscalePalette[1] : M.unescoMarineBlue)};
  border-radius: 4px;
  max-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  &:hover {
    background: ${(p) => {
      if (p.disabled) {
        // Same as the normal background, no hover effect.
        return p.transparent
          ? "transparent"
          : p.inverted
            ? M.blackText
            : M.grayscalePalette[2];
      } else {
        return p.transparent
          ? p.inverted
            ? "rgba(255,255,255,.1)"
            : "rgba(0,0,0,.1)"
          : p.inverted
            ? M.blackText
            : M.grayscalePalette[3];
      }
    }};
  }
  select {
    ${M.fontTable};
    /* Hide the component, so we can display truncated label on top of it */
    opacity: 0;
    background: transparent;
    width: 100%;
    appearance: none;
    border: none;
    outline: none;
    padding-left: 0;
    padding-right: 15px;
    cursor: ${(p) => (p.disabled ? "inherit" : "pointer")};
    min-width: ${(p) =>
      p.position === "TOP" || p.position === "TOOLBAR" ? "220px" : "auto"};

    &:-moz-focusring {
      color: transparent;
      text-shadow: none;
    }
    &::-ms-expand {
      display: none;
    }

    html[dir="rtl"] & {
      padding-left: 15px;
      padding-right: 0;
    }
  }
  /* For Firefox, or else the text inherits the color from the <select> element which we set to transparent */
  optgroup,
  option {
    color: ${M.blackText};
  }
`;

const Triangle = () => {
  return (
    <svg
      width="10px"
      height="6px"
      viewBox="0 0 10 6"
      css={css`
        position: absolute;
        top: calc(50% - 3px);
        bottom: 0;
        right: 16px;

        html[dir="rtl"] & {
          left: 16px;
          right: unset;
        }
      `}
    >
      <polygon points="0 0 5 6 10 0" fill="currentColor" />
    </svg>
  );
};
