import { AxisBottom, AxisLeft, AxisScale } from "@visx/axis";
import { bottomTickLabelProps } from "@visx/axis/lib/axis/AxisBottom";
import { GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { format } from "d3-format";
import React from "react";

import * as M from "../../../../materials";
import XTick from "charts/lib/shared/XTick";
import YTick from "charts/lib/shared/YTick";
import { PADDING } from "../constants";
import { TrajectoriesChartProps } from "../Trajectories";
import { useTheme } from "emotion-theming";

interface AxisProps<A> {
  boundedWidth: number;
  boundedHeight: number;
  yAxisTitle?: TrajectoriesChartProps<A>["yAxisTitle"];
  xAxisTitle?: TrajectoriesChartProps<A>["xAxisTitle"];
  xAxisSubtitle?: TrajectoriesChartProps<A>["xAxisSubtitle"];
  scaleX: AxisScale;
  scaleY: AxisScale;
}

function Axis<A>({
  scaleX,
  scaleY,
  xAxisTitle,
  xAxisSubtitle,
  yAxisTitle,
  boundedWidth,
  boundedHeight,
}: AxisProps<A>): React.ReactElement {
  const isEmptyXAxis = scaleX.domain()[1] === -1;
  const xAxisTickValues = isEmptyXAxis
    ? []
    : [...Array(scaleX.domain()[1] - scaleX.domain()[0] + 1).keys()].map(
        (d) => d + scaleX.domain()[0]
      );

  const { client } = useTheme<$FixMe>();

  return (
    <Group>
      <GridRows
        width={boundedWidth}
        scale={scaleY}
        numTicks={5}
        stroke={M.divider}
      />
      <AxisBottom
        top={PADDING.top + boundedHeight}
        scale={scaleX}
        stroke={M.divider}
        tickFormat={(value) => {
          const formattedValue = format(".0f")(value);
          return value === scaleX.domain()[1]
            ? `${xAxisTitle} ${formattedValue}`
            : formattedValue;
        }}
        tickValues={client.screenMDown ? undefined : xAxisTickValues}
        numTicks={
          client.screenMDown
            ? Math.min(xAxisTickValues.length, 6)
            : xAxisTickValues.length
        }
        tickLength={0}
        tickLabelProps={(value, index) => ({
          ...bottomTickLabelProps,
          textAnchor:
            index === 0
              ? "start"
              : value === scaleX.domain()[1]
                ? "end"
                : "middle",
        })}
        tickComponent={({ textAnchor, formattedValue, x }) => (
          <XTick
            textAnchor={textAnchor}
            label={formattedValue}
            transform={`translate(${x},${0})`}
          />
        )}
        label={isEmptyXAxis ? xAxisSubtitle : ""}
        labelProps={{
          ...M.fontAxisLabel.fontProps.cssProperties,
          fill: M.lightText,
          x: boundedWidth,
          textAnchor: "end",
          dy: "1.2em",
        }}
      />
      <AxisLeft
        scale={scaleY}
        hideAxisLine
        numTicks={5}
        hideTicks
        hideZero
        tickFormat={(value) =>
          value === 1
            ? `${format(".0%")(value)} ${yAxisTitle}`
            : format(".0f")(value * 100)
        }
        tickComponent={({ formattedValue, y }) => {
          if (formattedValue === "0") return null;
          return (
            <YTick
              label={formattedValue}
              transform={`translate(${0},${y - 2})`}
            />
          );
        }}
      />
    </Group>
  );
}

export default React.memo(Axis) as typeof Axis;
