import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { rollup, sum } from "d3-array";
import { format } from "d3-format";
import { scaleLinear } from "d3-scale";
import { tuple } from "fp-ts/lib/function";
import { pipe } from "fp-ts/lib/pipeable";
import React from "react";
import { StackedBarAuto } from "../charts-motion";
import { removeTrailingZero } from "../charts/lib/utils";
import { withFigureIO } from "../components/figure";
import { useReadScrolly } from "../components/scrolly";
import { metadata } from "../data/data_finance_aid_fig_EDU_SUB";
import {
  ChartRenderer,
  mkGemDataDecoder,
  useButtonGroupState,
  useFigureState,
} from "../domain";
import { useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { An, Ar, identity, io, O, Ord } from "../prelude";

export * from "../data/data_finance_aid_fig_EDU_SUB";

const Indicator = io.keyof({
  basic: null,
  secondary: null,
  tertiary: null,
});
type Indicator = io.TypeOf<typeof Indicator>;

const indicatorLookup: Record<Indicator, MessageDescriptor> = {
  basic: t("fig.finance.aid.EDU_SUB.basic")`Basic education`,
  secondary: t("fig.finance.aid.EDU_SUB.secondary")`Secondary education`,
  tertiary: t("fig.finance.aid.EDU_SUB.tertiary.FSHH")`Tertiary education`,
};

const indicatorLookupRel: Record<Indicator, MessageDescriptor> = {
  basic: t("fig.finance.aid.EDU_SUB.basic")`Basic education`,
  secondary: t("fig.finance.aid.EDU_SUB.secondary")`Secondary education`,
  tertiary: t("fig.finance.aid.EDU_SUB.tertiary.FSHH")`Tertiary education`,
};

export const Data = mkGemDataDecoder([], {
  ind_id: Indicator,
  value: io.number,
  year: io.number,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const [, actions] = useFigureState();
  const [selectedUnit] = useButtonGroupState("unit");
  const { activeSection } = useReadScrolly();

  const [scrollyUnit, selectedYears] = React.useMemo(
    () =>
      pipe(
        activeSection,
        O.map((x) => x.split(",")),
        O.fold(
          () => tuple("absolute", O.none),
          (strIds) => {
            const [unit, ...years] = strIds; // Expected format: "relative,2011,2012,2013"
            const scrollyUnit = unit === "relative" ? "relative" : "absolute";
            return tuple(
              scrollyUnit,
              pipe(
                Ar.array.sequence(O.option)(years.map((d) => O.some(d))),
                O.map((xs) => xs),
                O.chain(An.fromArray)
              )
            );
          }
        )
      ),
    [activeSection]
  );

  const entityData = React.useMemo(
    () =>
      data.map((x) => ({
        ...x,
        indicator: i18n._(indicatorLookup[x.ind_id]),
      })),
    [i18n, data]
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "unit",
      {
        type: "ButtonGroup",
        selected: "absolute",
      },
      [
        { value: "absolute", label: i18n._(t`Absolute values`) },
        { value: "relative", label: i18n._(t`Ratio`) },
      ]
    );
  }, [i18n, actions]);

  React.useEffect(() => {
    if (O.isSome(activeSection)) {
      actions.updateSelectionControl("unit", scrollyUnit);
    }
  }, [i18n, actions, activeSection, scrollyUnit]);

  const isAbsolute = selectedUnit === "absolute";

  const stretchedScale = React.useMemo(() => {
    const scales = rollup(
      entityData,
      (xs) =>
        scaleLinear()
          .domain([0, sum(xs, (x) => x.value)])
          .range([0, 1]),
      (x) => x.year
    );
    return (year: number, value: number) =>
      (scales.get(year as $FixMe) as $FixMe)(value);
  }, [entityData]);

  const chartData = React.useMemo(() => {
    return pipe(
      entityData,
      Ar.map((x) =>
        isAbsolute ? x : { ...x, value: stretchedScale(x.year, x.value) }
      ),
      Ar.sortBy([
        Ord.contramap((x: (typeof data)[number]) => x.year)(Ord.ordNumber),
      ])
    );
  }, [isAbsolute, entityData, stretchedScale]);

  type Datum = (typeof chartData)[number];

  const getStack = React.useCallback((x: Datum) => `${x.year}`, []);
  const getLayer = React.useCallback((x: Datum) => x.ind_id, []);
  const getLayerLabel = React.useCallback(
    (ind_id: string) =>
      isAbsolute
        ? i18n._(indicatorLookup[ind_id as Indicator])
        : i18n._(indicatorLookupRel[ind_id as Indicator]),
    [i18n, isAbsolute]
  );
  const getValue = React.useCallback((x: Datum) => x.value || 0, []);
  const formatY = React.useMemo(
    () => removeTrailingZero(format(isAbsolute ? ".1f" : ".0%")),
    [isAbsolute]
  );
  const getColorPalette = React.useCallback(() => M.colorRanges.discrete, []);

  return pipe(
    An.fromArray(chartData),
    O.map((xs) => (
      <StackedBarAuto
        key={"linter-map"}
        height={client.screenMDown ? M.chartHeight.s : M.chartHeight.m}
        data={xs}
        getStack={getStack}
        getLayer={getLayer}
        getLayerLabel={getLayerLabel}
        getValue={getValue}
        formatX={identity}
        formatY={formatY}
        yUnitLabel={isAbsolute ? i18n._(t`2022 constant US$ billion`) : ""}
        colorPalette={getColorPalette}
        showTooltip
        rotateXLabels={client.screenMDown}
        highlightedItems={selectedYears}
      />
    )),
    O.toNullable
  );
};

export default withFigureIO({
  url: require("../data/data_finance_aid_fig_EDU_SUB.json"),
  csv: require("../data/data_finance_aid_fig_EDU_SUB.zip"),
  xlsx: require("../data/data_finance_aid_fig_EDU_SUB.xlsx"),
  metadata,
  Data,
  Chart,
  size: "main",
  datasets: require("../data/data_finance_aid_fig_EDU_SUB_datasets").datasets,
});
