import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { curveLinear } from "d3-shape";
import React from "react";
import { LineChart } from "../charts/LineChart";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_learning_fig_LIT_COHORT";
import {
  ChartRenderer,
  countryIdIso,
  getCountryName,
  mkGemDataDecoder,
  useFigureControlItems,
  useMultiSelectState,
} from "../domain";
import { useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { io, O, pipe } from "../prelude";

export * from "../data/data_learning_fig_LIT_COHORT";

const DEFAULT_COUNTRIES = ["BFA", "ETH", "NPL"];

const Indicator = io.keyof({
  cohort: null,
  period: null,
});
type Indicator = io.TypeOf<typeof Indicator>;

const indicatorLookup: Record<Indicator, MessageDescriptor> = {
  cohort: t("fig.learning.LITCOHORT.cohort")`Cohort literacy rate (%)`,
  period: t("fig.learning.LITCOHORT.period")`Period literacy rate (%)`,
};

export const Data = mkGemDataDecoder(["iso3c", "value"], {
  ind_id: Indicator,
});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const env = useConfig();
  const [selectedCountries, actions] = useMultiSelectState("countries");

  const countryData = React.useMemo(() => {
    return data.map((x) => ({
      ...x,
      entity_name: getCountryName(env.countries, x.iso3c),
      ind_name: i18n._(indicatorLookup[x.ind_id]),
    }));
  }, [i18n, data, env.countries]);
  const countryControlItems = useFigureControlItems(
    countryData,
    (x) => countryIdIso.unwrap(x.iso3c),
    (x) => x.entity_name
  );

  React.useEffect(() => {
    actions.setSelectionControl(
      "countries",
      {
        type: "MultiSelect",
        label: i18n._(t`Countries`),
        selected: O.some(DEFAULT_COUNTRIES),
        maxItems: 3,
      },
      countryControlItems
    );
  }, [i18n, actions, countryControlItems]);

  const chartData = React.useMemo(() => {
    return countryData.filter(({ iso3c }) =>
      pipe(
        selectedCountries,
        O.fold(
          () => false,
          (xs) => xs.includes(countryIdIso.unwrap(iso3c))
        )
      )
    );
  }, [countryData, selectedCountries]);

  const columnCount = pipe(
    selectedCountries,
    O.fold(
      () => 1,
      (xs) => xs.length
    )
  );

  return (
    <LineChart
      tLabel={(s) => s}
      height={
        client.screenMDown || columnCount > 3
          ? M.chartHeight.s
          : M.chartHeight.m
      }
      minInnerWidth={client.screenMDown ? 60 : undefined}
      endLabel={!client.screenMDown}
      x="year"
      timeParse="%Y"
      timeFormat="%Y"
      numberFormat=".0%"
      column="entity_name"
      columns={columnCount}
      columnSort="none"
      category="datum.ind_name"
      colorLegend
      values={chartData}
      yTicks={[0, 0.2, 0.4, 0.6, 0.8, 1]}
      curve={curveLinear}
    />
  );
};

export default withFigureIO({
  url: require("../data/data_learning_fig_LIT_COHORT.json"),
  csv: require("../data/data_learning_fig_LIT_COHORT.zip"),
  xlsx: require("../data/data_learning_fig_LIT_COHORT.xlsx"),
  metadata,
  Data,
  Chart,
  datasets: require("../data/data_learning_fig_LIT_COHORT_datasets").datasets,
});
