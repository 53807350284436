import { css } from "@emotion/core";
import { t } from "@lingui/macro";
import { format } from "d3-format";
import React, { useMemo } from "react";

import { TrajectoriesChartProps } from "charts/lib/Trajectories/Trajectories";
import { TrajectoriesChart } from "charts/TrajectoriesChart";
import { useReadScrolly } from "components/scrolly";
import { useConfig } from "config";
import { useI18n } from "locales";
import { withFigureIO } from "../components/figure";
import { metadata } from "../data/data_learning_trajectories_fig_TRAJECTORIES_CURRENT";
import {
  ChartRenderer,
  countryIdIso,
  forceUnwrapCountryOrRegion,
  getCountryMeta,
  getRegionOrCountryName,
  goalRule,
  regionIdIso,
  RiseDataDecoder,
  splitCountriesAndRegions,
  xAxisSubtitleLookup,
  xAxisTitleLookup,
  yAxisTitleLookup,
} from "../domain";
import { useTheme } from "../hooks";
import * as M from "../materials";
import { E, io, O, pipe } from "../prelude";

export * from "../data/data_learning_trajectories_fig_TRAJECTORIES_CURRENT";

// Internationalization messages
const steepTrajectoryLabel = t(
  "fig.learning.trajectories.TRAJECTORIES_CURRENT.steepTrajectory"
)`Steep trajectory`;

const flatTrajectoryLabel = t(
  "fig.learning.trajectories.TRAJECTORIES_CURRENT.flatTrajectory"
)`Flat trajectory`;

const lmicAverageLabel = t(
  "fig.learning.trajectories.TRAJECTORIES_CURRENT.lmicAverage"
)`Average`;

// Scrollytelling steps
const Steps = io.union([
  io.literal("HIGHLIGHT_VIETNAM"),
  io.literal("LMIC_GRADE_3"),
  io.literal("LMIC_GRADE_6"),
  io.literal("DIFFERENCE_TRAJECTORIES"),
  io.literal("default"),
]);
type Steps = io.TypeOf<typeof Steps>;

type StepProps<A> = Record<
  Steps,
  Partial<TrajectoriesChartProps<A>> &
    Pick<TrajectoriesChartProps<A>, "lineStyle">
>;

// Line styles
const backgroundSeriesStyle = {
  stroke: M.grayscalePalette[4],
  strokeWidth: 1.5,
};

const highlightLMICProps = {
  lineStyle: (series: string | number) => {
    if (series === "lmic") {
      return {
        stroke: M.q07Palette[6],
        strokeDasharray: "0 6",
        strokeWidth: 3,
      };
    }
    return backgroundSeriesStyle;
  },
  backgroundSeriesFilter: (series: string | number) => series !== "lmic",
};

export const Data = RiseDataDecoder(["value", "country", "year"], {});
export type Data = io.TypeOf<typeof Data>;

export const Chart = ({ data, renderMode }: ChartRenderer<Data>) => {
  const { client } = useTheme();
  const { activeSection } = useReadScrolly();
  const env = useConfig();

  const i18n = useI18n();

  const activeSectionId = pipe(
    Steps.decode(O.toNullable(activeSection)),
    E.getOrElse(() => Steps.encode("default"))
  );

  const { countries: countryList, regions: regionList } =
    splitCountriesAndRegions({
      input: data.map(({ country }) => ({ country })),
      regions: env.regions,
      countries: env.countries,
    });

  const lowerAndLowerMiddleIncomeCountries = countryList.filter((country) =>
    pipe(
      getCountryMeta(env.countries, countryIdIso.wrap(country.value)),
      O.fold(
        () => false,
        (c) =>
          c.income &&
          ["Low income", "Lower middle income"].includes(
            regionIdIso.unwrap(c.income)
          )
      )
    )
  );

  const countries = [
    ...new Set(
      [...lowerAndLowerMiddleIncomeCountries, ...regionList].map((d) => d.value)
    ),
  ]
    .sort()
    .sort((d) => (d === "lmic" ? -1 : 1));

  const chartData: Data = React.useMemo(
    () =>
      data
        .filter(
          (d) =>
            d.year >= 1 &&
            d.year <= 6 &&
            countries.find(
              (country) => forceUnwrapCountryOrRegion(d.country) === country
            )
        )
        .sort(
          (a, b) =>
            countries.indexOf(forceUnwrapCountryOrRegion(b.country)) -
            countries.indexOf(forceUnwrapCountryOrRegion(a.country))
        ),
    [data, countries]
  );

  const chartPropsAtScrollSteps: StepProps<Data[number]> = useMemo(
    () => ({
      HIGHLIGHT_VIETNAM: {
        lineStyle: (series: string | number) => {
          if (series === "VNM") {
            return {
              stroke: M.q08Palette[5],
              strokeWidth: 3,
            };
          }
          return backgroundSeriesStyle;
        },
        lockActivePoint: chartData.find(
          (d) => forceUnwrapCountryOrRegion(d.country) === "VNM" && d.year === 3
        ),
        backgroundSeriesFilter: (series: string | number) => series !== "VNM",
      },
      LMIC_GRADE_3: {
        lockActivePoint: chartData.find(
          (d) =>
            forceUnwrapCountryOrRegion(d.country) === "lmic" && d.year === 3
        ),
        filterSeriesValues: (d) =>
          forceUnwrapCountryOrRegion(d.country) === "lmic" ? d.year <= 3 : true,
        ...highlightLMICProps,
      },
      LMIC_GRADE_6: {
        lockActivePoint: chartData.find(
          (d) =>
            forceUnwrapCountryOrRegion(d.country) === "lmic" && d.year === 6
        ),
        ...highlightLMICProps,
      },
      DIFFERENCE_TRAJECTORIES: {
        lineStyle: (series: string | number) => {
          if (series === "VNM") {
            return {
              stroke: M.q08Palette[5],
              strokeWidth: 3,
            };
          }
          if (series === "COD") {
            return {
              stroke: M.q03Palette[7],
              strokeWidth: 3,
            };
          }

          return backgroundSeriesStyle;
        },
        lockActivePoint: chartData.find(
          (d) => forceUnwrapCountryOrRegion(d.country) === "VNM" && d.year === 3
        ),
        backgroundSeriesFilter: (series: string | number) =>
          !["VNM", "COD"].includes(series.toString()),
        formatTooltipLabel: (d: Data[number]) => {
          if (forceUnwrapCountryOrRegion(d.country) === "VNM")
            return i18n._(steepTrajectoryLabel);
          if (forceUnwrapCountryOrRegion(d.country) === "COD")
            return i18n._(flatTrajectoryLabel);
          return d.country as unknown as string;
        },
      },
      default: highlightLMICProps,
    }),
    [chartData, i18n]
  );

  const chartProps =
    renderMode === "static"
      ? {
          lockActivePoint: chartData.find(
            (d) =>
              forceUnwrapCountryOrRegion(d.country) === "lmic" && d.year === 6
          ),
          flipTooltip: true,
          ...highlightLMICProps,
        }
      : chartPropsAtScrollSteps[activeSectionId];

  return (
    <div
      css={css`
        margin-top: ${M.spacing.base8(7)};
      `}
    >
      <TrajectoriesChart<Data[number]>
        height={M.chartHeight.m}
        dataset={chartData}
        seriesAccessor={(d) => forceUnwrapCountryOrRegion(d.country)}
        xAccessor={(d) => d.year}
        yAccessor={(d) => d.value}
        yAxisTitle={i18n._(yAxisTitleLookup["LIT"])}
        xAxisTitle={i18n._(xAxisTitleLookup["GRADE"])}
        xAxisSubtitle={
          xAxisSubtitleLookup["GRADE"]
            ? i18n._(xAxisSubtitleLookup["GRADE"])
            : undefined
        }
        formatTooltipLabel={(d) =>
          forceUnwrapCountryOrRegion(d.country) === "lmic"
            ? i18n._(lmicAverageLabel)
            : getRegionOrCountryName({
                input: { country: d.country },
                regions: env.regions,
                countries: env.countries,
              })
        }
        formatTooltipValue={(d) =>
          d.value === null
            ? i18n._(t("fig.label.data.not.available")`Not Available`)
            : format(".0%")(d.value)
        }
        backgroundSeriesFilter={(series) => series !== "lmic"}
        rules={renderMode === "embed" ? [] : [goalRule(i18n, "GRADE", "LIT")]}
        flipTooltip={client.screenSDown}
        {...chartProps}
      />
    </div>
  );
};

export default withFigureIO({
  url: require("../data/data_learning_trajectories_fig_TRAJECTORIES_CURRENT.json"),
  csv: require("../data/data_learning_trajectories_fig_TRAJECTORIES_CURRENT.zip"),
  xlsx: require("../data/data_learning_trajectories_fig_TRAJECTORIES_CURRENT.xlsx"),
  metadata,
  Data,
  Chart,
  size: "main",
  datasets:
    require("../data/data_learning_trajectories_fig_TRAJECTORIES_CURRENT_datasets")
      .datasets,
});
