// import PropTypes from "prop-types";
import { extent, max, min, range } from "d3-array";
import { scaleLinear, scaleOrdinal, scalePoint, scaleTime } from "d3-scale";
import { area as areaShape, curveMonotoneX, line as lineShape } from "d3-shape";
import { timeYear } from "d3-time";
import { AnimatePresence, motion } from "framer-motion";
import { nanoid } from "nanoid";
import React from "react";
import { YAnnotations } from "../../charts-motion";
import { usePointerPosition } from "../../hooks/usePointerPosition";
import { lighten } from "../../lib/colorManipulator";
import * as M from "../../materials";
import { Ar, O, Ord, pipe } from "../../prelude";
import ColorLegend from "./ColorLegend";
import layout, { Y_CONNECTOR, Y_CONNECTOR_PADDING } from "./Lines.layout";
import { springConfigs } from "./motion";
import { timeFormat } from "./timeFormat";
import { foldMarkStyle } from "./types";
import { deduplicate, findClosest, runSort, sortBy, subsup } from "./utils";
import { lineChartDefaultStyles as styles } from "./shared/styles";
import HoverText from "./shared/HoverText";
import HoverLines from "./shared/HoverLines";
import HoverCircle from "./shared/HoverCircle";
import XTick from "./shared/XTick";
import YTick from "./shared/YTick";
import {
  HOVER_CIRCLE_RADIUS,
  HOVER_CIRCLE_RADIUS_ACTIVE,
} from "./shared/constants";

const Y_LABEL_HEIGHT = 12;
const Y_GROUP_MARGIN = 20;
const HOVER_PROXIMITY = Infinity;
const LIGHTEN_BY = 0.5;
const REDUCE_X_TICKS_BELOW = 380; // Show fewer x ticks if inner width below this px value

const last = (array, index) => array.length - 1 === index;

const calculateLabelY = (linesWithLayout, propery) => {
  let lastY = -Infinity;
  sortBy(
    linesWithLayout.filter((line) => line[`${propery}Value`]),
    (line) => line[`${propery}Y`]
  ).forEach((line) => {
    let labelY = line[`${propery}Y`];
    let nextY = lastY + Y_LABEL_HEIGHT;
    if (nextY > labelY) {
      labelY = nextY;
    }
    line[`${propery}LabelY`] = lastY = labelY;
  });
};

const LineGroup = (props) => {
  const {
    lines,
    mini,
    title,
    y,
    yTicks,
    yAxisFormat,
    yAxisLabel,
    x,
    xTicks,
    xAccessor,
    xAxisFormat,
    tooltipFormatX,
    tooltipFormatTitle,
    yFormat,
    width,
    yAnnotations,
    band,
    connectLines,
    endDy,
    showTooltip,
    hoveredPoint,
    setHoveredPoint,
    curve,
  } = props;

  // ---------------------------------------------------------------------------

  const [pointerPositionRef, pointerPosition] = usePointerPosition();

  // ---------------------------------------------------------------------------

  React.useEffect(() => {
    if (showTooltip) {
      const flattenedLinePoints = Ar.sort(
        Ord.contramap(xAccessor)(Ord.ordNumber)
      )(
        lines.reduce(
          (acc, x) => acc.concat(x.sections.flatMap((s) => s.line)),
          []
        )
      );

      const hoveredX = pipe(
        pointerPosition,
        O.map((pos) => x.invert(pos.x)),
        O.map((xVal) =>
          xAccessor(findClosest(flattenedLinePoints, xAccessor, xVal))
        )
      );

      const hoveredPoints = pipe(
        hoveredX,
        O.map((xVal) =>
          flattenedLinePoints.filter(
            (d) => d.value !== null && xAccessor(d).valueOf() === xVal.valueOf()
          )
        ),
        O.chain(O.fromPredicate((xs) => xs.length > 0))
      );

      const activePoint = pipe(
        pointerPosition,
        O.map((pos) => y.invert(pos.y)),
        O.chain((yVal) =>
          pipe(
            hoveredPoints,
            O.map((xs) =>
              xs.reduce(
                (closest, d) =>
                  Math.abs(yVal - closest.value) < Math.abs(yVal - d.value)
                    ? closest
                    : d,
                xs[0]
              )
            ),
            O.chain(
              O.fromPredicate(
                (closest) =>
                  Math.abs(y(yVal) - y(closest.value)) < HOVER_PROXIMITY
              )
            )
          )
        )
      );

      setHoveredPoint({ hoveredX, activePoint });
    }
    // FIXME: we can't depend on `x` or `y` because it is regenerated each time.
    // eslint-disable-next-line
  }, [setHoveredPoint, pointerPosition, xAccessor, showTooltip]);

  // ---------------------------------------------------------------------------

  const [height] = y.range();
  const xAxisY = height;

  const pathGenerator = lineShape()
    .x((d) => x(xAccessor(d)))
    .y((d) => y(d.value))
    .defined((d) => d.value !== null)
    .curve(curve);

  const bandArea = areaShape()
    .x((d) => x(xAccessor(d)))
    .y0((d) => y(+d.datum[`${band}_lower`]))
    .y1((d) => y(+d.datum[`${band}_upper`]));

  const linesWithLayout = lines.map((line) => {
    return {
      ...line,
      startX: x(xAccessor(line.start)),
      // we always render at end label outside of the chart area
      // even if the line ends in the middle of the graph
      endX: width,
      startY: y(line.start.value),
      endY: y(line.end.value),
    };
  });

  calculateLabelY(linesWithLayout, "start");
  calculateLabelY(linesWithLayout, "end");

  const sortedLines = pipe(
    hoveredPoint.activePoint,
    O.fold(
      () => {
        linesWithLayout.forEach((d) => (d.isHovered = null));
        return linesWithLayout;
      },
      (x) => {
        const backgroundLines = linesWithLayout.filter(
          (d) => d.start.category !== x.category
        );
        backgroundLines.forEach((d) => (d.isHovered = "background"));
        const foregroundLines = linesWithLayout.filter(
          (d) => d.start.category === x.category
        );
        foregroundLines.forEach((d) => (d.isHovered = "foreground"));

        return backgroundLines.concat(foregroundLines);
      }
    )
  );

  const isTextFlippedH = (d) =>
    x(xAccessor(d)) > (x.range()[1] - x.range()[0]) * 0.6 ? true : false;
  const isTextFlippedV = (d) => (y(d.value) - y.range()[1] < 60 ? true : false);

  const gradientId = React.useMemo(() => `gradient-${nanoid(5)}`, []);
  const maskId = React.useMemo(() => `mask-${nanoid(5)}`, []);

  return (
    <g>
      <defs>
        <linearGradient id={gradientId}>
          <stop offset="0%" stopColor="white" />
          <stop offset="90%" stopColor="white" />
          <stop offset="100%" stopColor="black" />
        </linearGradient>
      </defs>
      <mask id={maskId}>
        <rect
          x="0"
          y="0"
          width={width}
          height="2em"
          fill={`url(#${gradientId})`}
        />
      </mask>
      <text dy="1.7em" css={styles.columnTitle} mask={`url(#${maskId})`}>
        {subsup.svg(title)}
      </text>
      {xTicks.map((tick, i) => (
        <XTick
          key={`x${tick}`}
          label={xAxisFormat(tick)}
          transform={`translate(${x(tick)},${xAxisY})`}
          textAnchor={i === 0 ? "start" : last(xTicks, i) ? "end" : "middle"}
        />
      ))}
      {yTicks.map((tick, i) => (
        <YTick
          key={`y${tick}`}
          label={`${yAxisFormat(tick, last(yTicks, i))} ${
            last(yTicks, i) && yAxisLabel ? yAxisLabel : ""
          }`}
          transform={`translate(0,${y(tick)})`}
          width={width}
        />
      ))}
      {pipe(
        hoveredPoint.activePoint,
        O.map((point, i) => {
          const xRange = x.range();
          const yRange = y.range();
          return (
            <HoverLines
              key={i}
              showTooltip={showTooltip}
              xPos={x(point.x)}
              yPos={y(point.value)}
              x1={xRange[0]}
              x2={xRange[1]}
              y1={yRange[0]}
              y2={yRange[1]}
            />
          );
        }),
        O.toNullable
      )}
      <YAnnotations
        width={width}
        yScale={y}
        yFormat={yAxisFormat}
        annotations={yAnnotations}
      />
      {sortedLines.map(
        ({
          sections,
          startValue,
          end,
          endValue,
          endLabel,
          markStyle,
          isFaded,
          startX,
          startLabelY,
          endX,
          endLabelY,
          lineColor,
          isHovered,
        }) => {
          return (
            <g key={`line-${end.category}`}>
              {startValue && startValue !== endValue && (
                <YConnector
                  startX={startX}
                  startLabelY={startLabelY}
                  lineColor={lineColor}
                  startValue={startValue}
                />
              )}
              {band && (
                <path fill={lineColor} fillOpacity="0.2" d={bandArea(line)} />
              )}
              {sections.map(({ line, lineDefined, style }, i) => (
                <DataLines
                  key={i}
                  stroke={
                    (isHovered && isHovered) === "background" || isFaded
                      ? lighten(lineColor, LIGHTEN_BY)
                      : lineColor
                  }
                  opacity={foldMarkStyle(markStyle, {
                    normal: () => "none",
                    muted: () => "0 6",
                    reference: () => "0 6",
                  })}
                  backdropPath={
                    connectLines &&
                    markStyle === "normal" &&
                    pathGenerator(lineDefined)
                  }
                  linePath={pathGenerator(line)}
                  {...style}
                />
              ))}

              {endLabel && endValue && (
                <g>
                  {!mini && (
                    <motion.line
                      x1={endX + Y_CONNECTOR_PADDING}
                      x2={endX + Y_CONNECTOR + Y_CONNECTOR_PADDING}
                      y1={endLabelY}
                      y2={endLabelY}
                      stroke={lineColor}
                      strokeWidth={3}
                      animate={{
                        stroke: pipe(
                          hoveredPoint.activePoint,
                          O.fold(
                            () => lineColor,
                            (ap) =>
                              end.category === ap.category
                                ? lineColor
                                : lighten(lineColor, LIGHTEN_BY)
                          )
                        ),
                      }}
                    />
                  )}

                  <motion.g
                    initial={false}
                    transition={springConfigs.lively}
                    animate={{
                      x: mini
                        ? endX
                        : endX + Y_CONNECTOR + Y_CONNECTOR_PADDING * 2,
                      y: mini ? endLabelY - Y_LABEL_HEIGHT : endLabelY,
                    }}
                  >
                    <motion.text
                      initial={false}
                      dy={endDy}
                      fill={M.lightText}
                      textAnchor={mini ? "end" : "start"}
                      opacity={1}
                      animate={{
                        opacity: pipe(
                          hoveredPoint.activePoint,
                          O.fold(
                            () => 1,
                            (ap) =>
                              end.category === ap.category ? 1 : LIGHTEN_BY
                          )
                        ),
                      }}
                    >
                      <tspan css={styles[mini ? "valueMini" : "value"]}>
                        {endValue}
                      </tspan>
                    </motion.text>
                  </motion.g>
                </g>
              )}
            </g>
          );
        }
      )}
      {sortedLines.map(({ sections, end, lineColor }) => (
        <g key={`tooltip-${end.category}`}>
          {pipe(
            hoveredPoint.activePoint,
            O.chain((ap) =>
              pipe(
                Ar.findFirst(
                  (d) =>
                    d.value != null &&
                    xAccessor(d).valueOf() === xAccessor(ap).valueOf()
                )(sections.flatMap((x) => x.line)),
                O.map((d) => [ap, d])
              )
            ),
            O.map(([ap, d], i) => {
              const isActive = d.category === ap.category;
              return (
                <Tooltip
                  key={i}
                  category={d.category}
                  isActive={isActive}
                  textFlippedV={isTextFlippedV(d)}
                  x={x(xAccessor(d))}
                  y={y(d.value)}
                  fill={isActive ? "#ffffff" : lighten(lineColor, LIGHTEN_BY)}
                  stroke={isActive ? lineColor : lighten(lineColor, LIGHTEN_BY)}
                  textAnchor={isTextFlippedH(d) ? "end" : "start"}
                  title={tooltipFormatTitle(d)}
                  xLabel={tooltipFormatX(d)}
                  yLabel={yFormat(d.value)}
                />
              );
            }),
            O.toNullable
          )}
        </g>
      ))}
      {showTooltip && (
        <rect
          ref={pointerPositionRef}
          x={0}
          y={0}
          width={width}
          height={y.range()[0]}
          fill="transparent"
        />
      )}
    </g>
  );
};

// LineGroup.propTypes = {
//   lines: PropTypes.arrayOf(
//     PropTypes.shape({
//       line: PropTypes.arrayOf(
//         PropTypes.shape({
//           value: PropTypes.number.isRequired
//         })
//       ),
//       start: PropTypes.shape({ value: PropTypes.number.isRequired }),
//       end: PropTypes.shape({ value: PropTypes.number.isRequired }),
//       lineColor: PropTypes.string.isRequired,
//       startValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
//       endValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired
//     })
//   ),
//   mini: PropTypes.bool,
//   title: PropTypes.string,
//   y: PropTypes.func.isRequired,
//   yTicks: PropTypes.array.isRequired,
//   yAxisFormat: PropTypes.func.isRequired,
//   x: PropTypes.func.isRequired,
//   xTicks: PropTypes.array.isRequired,
//   xAccessor: PropTypes.func.isRequired,
//   endDy: PropTypes.string.isRequired,
//   width: PropTypes.number.isRequired,
//   band: PropTypes.string
// };

const LineChart = (props) => {
  const {
    width,
    mini,
    children,
    description,
    band,
    bandLegend,
    connectLines,
    endDy,
    showTooltip,
    curve,
  } = props;

  const {
    data,
    groupedData,
    xParser,
    xAccessor,
    y,
    yAxis,
    yAnnotations,
    yFormat,
    colorLegend,
    colorLegendValues,
    paddingLeft,
    paddingRight,
    columnHeight,
  } = layout(props);

  const [hoveredPoint, setHoveredPoint] = React.useState({
    hoveredX: O.none,
    activePoint: O.none,
  });

  const possibleColumns = Math.floor(
    width / (props.minInnerWidth + paddingLeft + paddingRight)
  );
  let columns = props.columns;
  if (possibleColumns < props.columns) {
    columns = Math.max(possibleColumns, 1);
    // decrease columns if it does not lead to new rows
    // e.g. four items, 4 desired columns, 3 possible => go with 2 columns
    if (
      Math.ceil(groupedData.length / columns) ===
      Math.ceil(groupedData.length / (columns - 1))
    ) {
      columns -= 1;
    }
  }

  const columnWidth = Math.floor(width / columns) - 1;
  const innerWidth = columnWidth - paddingLeft - paddingRight;

  const xValues = React.useMemo(() => data.map(xAccessor), [data, xAccessor]);
  let x;
  let xTicks;
  let xFormat = (d) => d;
  if (props.xScale === "time") {
    xFormat = timeFormat(props.timeFormat);
    const xTimes = xValues.map((d) => d.getTime());
    const domainTime = [min(xTimes), max(xTimes)];
    const domain = domainTime.map((d) => new Date(d));
    let yearInteval = Math.round(timeYear.count(domain[0], domain[1]) / 3);

    let time1 = timeYear.offset(domain[0], yearInteval).getTime();
    let time2 = timeYear.offset(domain[1], -yearInteval).getTime();

    x = scaleTime().domain(domain);
    xTicks = (
      innerWidth <= REDUCE_X_TICKS_BELOW
        ? domainTime
        : [
            domainTime[0],
            sortBy(xTimes, (d) => Math.abs(d - time1))[0],
            sortBy(xTimes, (d) => Math.abs(d - time2))[0],
            domainTime[1],
          ]
    )
      .filter(deduplicate)
      .map((d) => new Date(d));
  } else if (props.xScale === "linear") {
    const domain = extent(xValues);
    x = scaleLinear().domain(domain);
    xTicks = domain;
  } else if (props.xScale === "ordinal") {
    let domain = xValues.filter(deduplicate);
    x = scalePoint().domain(domain);
    if (domain.length > 5) {
      let maxIndex = domain.length - 1;
      xTicks = [domain[0], domain[maxIndex]].filter(deduplicate);
    } else {
      xTicks = domain;
    }
  } else {
    throw new Error(`Unknown xScale: "${props.xScale}"`);
  }
  if (mini) {
    xTicks = [xTicks[0], xTicks[xTicks.length - 1]];
  }
  if (props.xTicks) {
    xTicks = props.xTicks.map(xParser);
  }
  x.range([0, innerWidth]);

  let groups = groupedData.map((g) => g.key);
  runSort(props.columnSort, groups);

  const rows = Math.ceil(groups.length / columns);
  const gx = scaleOrdinal()
    .domain(groups)
    .range(range(columns).map((d) => d * columnWidth));
  const gy = scaleOrdinal()
    .domain(groups)
    .range(
      range(groups.length).map((d) => {
        const row = Math.floor(d / columns);
        return row * columnHeight + row * Y_GROUP_MARGIN;
      })
    );

  const tooltipFormatX = props.tooltipFormatX
    ? (x) => props.tooltipFormatX(x.datum)
    : (x) => x.category;

  const tooltipFormatTitle = props.tooltipFormatTitle
    ? (x) => props.tooltipFormatTitle(x.datum)
    : (x) => xFormat(xAccessor(x));

  const activePoint = O.isSome(hoveredPoint.activePoint);

  return (
    <div>
      <svg
        width={width}
        height={rows * columnHeight + (rows - 1) * Y_GROUP_MARGIN}
        style={{ overflow: activePoint ? "visible" : "hidden" }}
      >
        <desc>{description}</desc>
        {groupedData.map(({ values: lines, key }) => {
          return (
            <g
              key={key || 1}
              transform={`translate(${gx(key) + paddingLeft},${gy(key)})`}
            >
              <LineGroup
                mini={mini}
                title={key}
                lines={lines}
                x={x}
                xTicks={xTicks}
                xAccessor={xAccessor}
                xAxisFormat={props.xAxisFormat || xFormat}
                tooltipFormatX={tooltipFormatX}
                tooltipFormatTitle={tooltipFormatTitle}
                yFormat={yFormat}
                yAxisLabel={props.yAxisLabel}
                y={y}
                yTicks={props.yTicks || yAxis.ticks}
                yAxisFormat={yAxis.axisFormat}
                band={band}
                yAnnotations={yAnnotations}
                connectLines={connectLines}
                endDy={endDy}
                width={innerWidth}
                showTooltip={showTooltip}
                hoveredPoint={hoveredPoint}
                setHoveredPoint={setHoveredPoint}
                curve={curve || curveMonotoneX}
              />
            </g>
          );
        })}
      </svg>
      <div>
        <div style={{ paddingLeft, paddingRight }}>
          <ColorLegend
            inline
            values={[]
              .concat(props.colorLegend && colorLegend && colorLegendValues)
              .concat(
                !mini &&
                  band &&
                  bandLegend && {
                    label: (
                      <span css={styles.bandLegend}>
                        <span css={styles.bandBar} />
                        {` ${bandLegend}`}
                      </span>
                    ),
                  }
              )
              .filter(Boolean)}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export const Line = (props) => <LineChart {...props} />;

Line.defaultProps = {
  x: "year",
  xScale: "time",
  timeParse: "%Y",
  timeFormat: "%Y",
  numberFormat: ".0%",
  zero: true,
  unit: "",
  startValue: false,
  endLabel: true,
  endDy: "0.3em",
  minInnerWidth: 110,
  columns: 1,
  height: M.chartHeight.m,
  colorLegend: true,
  yNice: 3,
};

export const Slope = (props) => <LineChart {...props} />;

Slope.defaultProps = {
  x: "year",
  xScale: "ordinal",
  timeParse: "%Y",
  timeFormat: "%Y",
  numberFormat: ".0%",
  zero: true,
  unit: "",
  startValue: true,
  endLabel: false,
  endDy: "0.3em",
  minInnerWidth: 90,
  columns: 2,
  height: M.chartHeight.m,
  colorLegend: true,
  yNice: 3,
};

// Additional Info for Docs
// - Line is the master chart and «owns» the prop types
// Line.propTypes = LineChart.propTypes;
// - Slope just has different default props
Slope.base = "Line";

const YConnector = React.memo(
  ({ startX, startLabelY, lineColor, startValue }) => (
    <g>
      <line
        x1={startX - Y_CONNECTOR_PADDING}
        x2={startX - Y_CONNECTOR - Y_CONNECTOR_PADDING}
        y1={startLabelY}
        y2={startLabelY}
        stroke={lineColor}
        strokeWidth={3}
      />
      <text
        css={styles.value}
        dy="0.3em"
        x={startX - Y_CONNECTOR - Y_CONNECTOR_PADDING * 2}
        y={startLabelY}
        textAnchor="end"
      >
        {startValue}
      </text>
    </g>
  )
);

const DataLineBackdrop = React.memo(({ d, stroke }) => (
  <motion.path
    fill="none"
    stroke={stroke}
    strokeWidth={1}
    d={d}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1, stroke }}
    exit={{ opacity: 0 }}
    transition={springConfigs.gentle}
  />
));

const DataLine = React.memo(({ d, stroke, strokeDasharray }) => (
  <motion.path
    fill="none"
    stroke={stroke}
    strokeWidth={3}
    strokeDasharray={strokeDasharray}
    strokeLinecap={"round"}
    d={d}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1, stroke }}
    exit={{ opacity: 0 }}
    transition={springConfigs.gentle}
  />
));

const DataLines = React.memo(
  ({ stroke, strokeDasharray, linePath, backdropPath }) => (
    <AnimatePresence>
      {backdropPath && (
        <DataLineBackdrop
          key="data-line-backdrop"
          d={backdropPath}
          stroke={stroke}
        />
      )}
      <DataLine
        key="data-line"
        d={linePath}
        stroke={stroke}
        strokeDasharray={strokeDasharray}
      />
    </AnimatePresence>
  )
);

export const Tooltip = React.memo(
  ({ category, isActive, x, y, fill, stroke, ...rest }) => (
    <AnimatePresence>
      <HoverCircle
        key="circle"
        category={category}
        cx={x}
        cy={y}
        r={isActive ? HOVER_CIRCLE_RADIUS_ACTIVE : HOVER_CIRCLE_RADIUS}
        fill={fill}
        stroke={stroke}
      />
      {isActive && (
        <HoverText key="text" category={category} x={x} y={y} {...rest} />
      )}
    </AnimatePresence>
  )
);
