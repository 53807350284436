import css from "@emotion/css";
import { Trans } from "@lingui/macro";
import { RenderMode } from "domain/renderer";
import React from "react";
import { useChartType } from "../../domain/hero-state";
import {
  mkStyles,
  useQueryParamsWithDefault,
  useTheme,
  useThemeNavigation,
} from "../../hooks";
import * as M from "../../materials";
import { Button, ButtonGroup } from "../button";
import { DownloadButton, InfoButton, ShareButton } from "../tooltip-triggers";
import { REnv, findArticleAlias } from "routes";
import { O, pipe } from "prelude";
import { PageLink } from "components/links";
import { ReactComponent as ArrowLeftIcon } from "../../icons/ic_arrow-left.svg";

const useStyles = mkStyles((theme) => ({
  root: css`
    margin-top: ${theme.spacing.base8(5)};
    margin-left: ${theme.spacing.base8(5)};
    margin-right: ${theme.spacing.base8(3)};
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: ${theme.spacing.base8(2)};

    @media ${M.bpDown("l")} {
      margin-left: 0;
    }

    @media ${M.bpDown("s")} {
      flex-direction: column;
      margin-top: ${theme.spacing.base8(3)};
      & > * + * {
        margin-top: ${theme.spacing.base8(2)};
      }
    }
  `,
  rootFallback: M.onlyIE(css`
    display: block;
  `),
  buttons: css`
    flex: 1;
    width: 100%;
    padding: 1px 0;

    & > * {
      margin-bottom: 0 !important; /* FIXME: Ugly hack because ButtonGroup has a margin */
    }

    @media ${M.bpUp("m")} {
      max-width: ${theme.spacing.base8(8, "%")};
    }
  `,
  iconControls: css`
    display: flex;
    gap: ${theme.spacing.base8(2)};
    flex-wrap: nowrap;
  `,

  navIcon: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: ${M.spacing.base8(4)};
    height: ${M.spacing.base8(4)};
    color: ${M.unescoMainBlue};
    cursor: pointer;
    outline: none;
  `,

  icon: css`
    width: ${theme.spacing.base8(4)};
    height: ${theme.spacing.base8(4)};
    border-radius: ${theme.spacing.base8(4)};
    color: ${theme.palette.action.main};
    background: ${theme.palette.grayscale[2]};
    cursor: pointer;
    &:hover {
      background: ${theme.palette.grayscale[3]};
    }
  `,
  divider: css`
    height: 32px;
    width: 1px;
    background-color: ${M.divider};

    @media ${M.bpDown("l")} {
      opacity: 0;
      display: none;
    }
  `,
}));

const Divider = () => {
  const styles = useStyles();
  return <div css={styles.divider} />;
};

type HeroControlProps = {
  id: string;
  caption: string;
  content: React.ReactChild;
  csv: string;
  xlsx: string;
  source?: React.ReactChild;
  hideToggle?: boolean;
  embed?: boolean;
  dataset?: string;
};

export const HeroControls = ({
  id,
  caption,
  content,
  csv,
  xlsx,
  source,
  hideToggle,
  embed,
  dataset,
}: HeroControlProps) => {
  const styles = useStyles();
  const [chartType, setChartType] = useChartType();
  const isToday = chartType === "today";
  const { client } = useTheme();

  const { alias } = useQueryParamsWithDefault(REnv);
  const aliasRoute = pipe(findArticleAlias(alias), O.toUndefined);
  const [prevTheme, nextTheme] = useThemeNavigation(aliasRoute);

  const iconControls = (
    <div css={styles.iconControls}>
      {!embed && (
        <div css={styles.icon}>
          <InfoButton content={content} source={source} gray />
        </div>
      )}
      <div css={styles.icon}>
        <DownloadButton id={id} csv={csv} xlsx={xlsx} gray />
      </div>
      <div css={styles.icon}>
        <ShareButton id={id} message={caption} gray dataset={dataset} />
      </div>
    </div>
  );

  return (
    <div
      css={[
        styles.root,
        styles.rootFallback,
        embed
          ? {
              justifyContent: "flex-start",
              marginLeft: 0,
              marginBottom: M.spacing.base8(3),
            }
          : undefined,
      ]}
    >
      {embed && (
        <>
          {iconControls} {!hideToggle && <Divider />}
        </>
      )}

      <div css={styles.buttons}>
        {!hideToggle && (
          <ButtonGroup>
            <Button inverted={!isToday} onClick={() => setChartType("today")}>
              <Trans>Today</Trans>
            </Button>
            <Button
              inverted={isToday}
              onClick={() => setChartType("timeseries")}
            >
              <Trans>Time series</Trans>
            </Button>
          </ButtonGroup>
        )}
      </div>
      {client.screenMDown ? (
        <div
          css={css`
            width: 100%;
            display: flex;
            gap: ${M.spacing.base8(2)};
            justify-content: space-between;
          `}
        >
          <div>{!embed && iconControls}</div>
          {prevTheme && nextTheme && (
            <div css={styles.iconControls}>
              <PageLink name={prevTheme} query={{}}>
                <div css={[styles.icon, styles.navIcon]}>
                  <ArrowLeftIcon
                    css={css`
                      html[lang="ar"] & {
                        transform: rotate(180deg);
                      }
                    `}
                  />
                </div>
              </PageLink>
              <PageLink name={nextTheme} query={{}}>
                <div css={[styles.icon, styles.navIcon]}>
                  <ArrowLeftIcon
                    css={css`
                      transform: rotate(180deg);
                      html[lang="ar"] & {
                        transform: rotate(0deg);
                      }
                    `}
                  />
                </div>
              </PageLink>
            </div>
          )}
        </div>
      ) : (
        !embed && iconControls
      )}
    </div>
  );
};

export const HeroControlsWrapper = ({
  children,
  renderMode,
  caption,
  content,
  source,
  id,
  csv,
  xlsx,
  hideToggle,
  dataset,
}: HeroControlProps & {
  children: React.ReactChild;
  renderMode: RenderMode;
  dataset?: string;
}) => {
  const embed = renderMode === "embed";
  const controls = (
    <HeroControls
      caption={caption}
      content={content}
      source={source}
      id={id}
      csv={csv}
      xlsx={xlsx}
      hideToggle={hideToggle}
      embed={embed}
      dataset={dataset}
    />
  );

  return (
    <>
      {embed && controls}
      {children}
      {!embed && renderMode !== "static" && controls}
    </>
  );
};
