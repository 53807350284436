import React from "react";

interface Props {
  datasets: {
    [key: string]: {
      filename: string;
      url: string;
      csv: string;
      xlsx: string;
    };
  };
  Component: React.FC<any>;
}

const MultipleHeroes = ({ datasets, Component }: Props) => (
  <>
    <Component key="-1" renderMode="dropzone" />
    {Object.keys(datasets).map((key) => (
      <>
        <h2 style={{ textAlign: "center" }}>
          Dataset: {datasets[key].filename}
        </h2>
        <Component key={key} renderMode="embed" dataset={key} />
      </>
    ))}
  </>
);

export default MultipleHeroes;
