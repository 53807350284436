import { useI18n } from "locales";
import React from "react";
import { SankeyAuto } from "../charts-motion/sankey";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_finance_fig_AID_FLOWS_SSA";
import { ChartRenderer, getAidFlowName } from "../domain";
import * as M from "../materials";
import { Data, NodeGroup } from "./AID_FLOWS";
import { computeSankeyNodes } from "./AID_FLOWS";
import { t } from "@lingui/macro";

export * from "../data/data_finance_fig_AID_FLOWS_SSA";

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const { aidflows } = useConfig();
  const i18n = useI18n();

  const nodeGroups: NodeGroup<Data[number]>[] = React.useMemo(
    () => [
      {
        nodes: "donor",
        depth: 0,
        label: i18n._(t`Donors`),
        category: "donors",
      },
      {
        nodes: "recipient",
        depth: 1,
        label: i18n._(t`Recipients`),
        category: "recipients",
      },
      {
        nodes: "level",
        depth: 2,
        label: i18n._(t`Education levels`),
        category: "education_levels",
      },
    ],
    [i18n]
  );

  const colorPalettes = React.useMemo(
    () => [
      { label: i18n._(t`Donors`), palette: M.q06Palette, id: "donors" },
      { label: i18n._(t`Recipients`), palette: M.q02Palette, id: "recipients" },
      {
        label: i18n._(t`Education levels`),
        palette: M.q10Palette,
        id: "education_levels",
      },
    ],
    [i18n]
  );

  const sankeyData = React.useMemo(() => {
    const localizedData = data.map((row) => ({
      ...row,
      donorId: row.donor,
      donor: getAidFlowName(aidflows, "donor", row.donor),
      recipientId: row.recipient,
      recipient: getAidFlowName(aidflows, "recipient", row.recipient),
      levelId: row.level,
      level: getAidFlowName(aidflows, "level", row.level),
    }));
    return computeSankeyNodes(localizedData, nodeGroups);
  }, [aidflows, data, nodeGroups]);

  return (
    <SankeyAuto
      data={sankeyData}
      nodeGroups={nodeGroups}
      height={M.chartHeight.l}
      colorPalettes={colorPalettes}
    />
  );
};

export default withFigureIO({
  url: require("../data/data_finance_fig_AID_FLOWS_SSA.json"),
  csv: require("../data/data_finance_fig_AID_FLOWS_SSA.zip"),
  xlsx: require("../data/data_finance_fig_AID_FLOWS_SSA.xlsx"),
  metadata,
  Data,
  Chart,
  datasets: require("../data/data_finance_fig_AID_FLOWS_SSA_datasets").datasets,
});
