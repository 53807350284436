import {
  ascending,
  bisector,
  descending,
  max as d3Max,
  rollup,
} from "d3-array";
import { rgb } from "d3-color";
import { formatLocale, formatSpecifier, precisionFixed } from "d3-format";
import React, { createElement, Fragment } from "react";
import * as M from "../../materials";
import { constant } from "../../prelude";

/**
 * Conditional rollup
 *
 * @param xs An iterable of data
 * @param predicate Whether to include this data row or not
 * @param reduce Returns a new data row
 * @param key A key function by which to group the data
 */
export function rollupCond<A, B, K>(
  xs: Iterable<A>,
  predicate: (value: Array<A>) => boolean,
  reduce: (value: Array<A>) => B,
  key: (value: A) => K
): Array<B> {
  return Array.from(
    rollup(xs, (vs) => (predicate(vs) ? reduce(vs) : null), key).values()
  ).filter(Boolean) as Array<B>;
}

/**
 * rollupPick
 *
 * In an array of items, pick one over all the others per group.
 */
export function rollupPick<A>(
  xs: Array<A>,
  keys: Array<keyof A>,
  pick: (a: A, b: A) => A
) {
  return Array.from(
    rollup(
      xs,
      (xs) => xs.reduce(pick, xs[0]),
      (x) => keys.map((k) => x[k]).join("-")
    ).values()
  );
}

/**
 * widen
 *
 * Widens "long" data by merging cells from multiple rows into one row, creating
 * new columns for a key-value pair of cells. This reduces the amount of rows
 * in the data.
 *
 * Use the predicate function to decide whether a newly created row should be
 * kept; an example of when not to keep new rows is when not all new columns
 * could be filled because there is missing data
 *
 * @example
 * const data = [
 *   {id: 'A', cat: 'x', val: 1},
 *   {id: 'A', cat: 'y', val: 2},
 *   {id: 'B', cat: 'x', val: 3},
 *   {id: 'B', cat: 'y', val: 4}
 * ]
 * widen(data, ['id'], [['cat', 'val']]) // [{ id: "A", x: 1, y: 2 }, { id: "B", x: 3, y: 4 }]
 *
 * @param data An array of data records
 * @param keys An array of keys to use for grouping; the contents of these cells will be kept as is
 * @param merge Pairs of column names that will be used to create the the new column name and value
 * @param [keep] A predicate function to decide whether to keep the generated data row or not
 */
export function widen<
  A extends Record<string, unknown>,
  B extends Record<string, unknown>,
>(
  data: Array<A>,
  keys: Array<keyof A>,
  merge: Array<[keyof A, keyof A]>,
  keep?: (data: Array<A>) => boolean
): Array<B> {
  const reducer = (rows: Array<A>) => {
    const rb: $Unexpressable /* RB */ = {};
    keys.forEach((k) => (rb[k] = rows[0][k]));
    rows.forEach((r) =>
      merge.forEach(([key, value]) => (rb[r[key]] = r[value]))
    );
    return rb;
  };
  const key = (x: A) =>
    keys
      .reduce(
        (acc: Array<string>, key: keyof A) => acc.concat(String(x[key])),
        []
      )
      .join("-");

  return rollupCond(data, keep || constant(true), reducer, key);
}

export const groupBy = <T extends {}>(
  array: T[],
  key: (d: T, i: number) => string | number | undefined
): { key: string | number; values: T[] }[] => {
  const keys: (string | number)[] = [];
  const object = array.reduce(
    (o, item, index) => {
      const k = key(item, index) || "";
      if (!o[k]) {
        o[k] = [];
        keys.push(k);
      }
      o[k].push(item);
      return o;
    },
    {} as Record<string, T[]> // eslint-disable-line
  );

  return keys.map((k) => ({
    key: k,
    values: object[k],
  }));
};

export type SortType = "none" | "ascending" | "descending";

export const runSort = (
  cmd: $FixMe,
  array: $FixMe,
  accessor = (d: $FixMe) => d
) => {
  if (cmd !== "none") {
    const compare = cmd === "descending" ? descending : ascending;
    const original = [...array];
    array.sort(
      (a: $FixMe, b: $FixMe) =>
        compare(accessor(a), accessor(b)) ||
        ascending(original.indexOf(a), original.indexOf(b)) // stable sort
    );
  }
};

export const sortBy = <T extends {}>(
  array: T[],
  accessor: (d: T) => $Unexpressable
) =>
  [...array].sort(
    (a, b) =>
      ascending(accessor(a), accessor(b)) ||
      ascending(array.indexOf(a), array.indexOf(b)) // stable sort
  );

export const measure = (onMeasure: $FixMe) => {
  let ref: $FixMe;
  let rafHandle: $FixMe;
  const update = () => {
    onMeasure(ref, ref.getBoundingClientRect());
  };
  return (newRef: $FixMe) => {
    ref = newRef;
    if (ref) {
      window.addEventListener("resize", update);
      // raf needed to wait for glamor css styles
      rafHandle = window.requestAnimationFrame(update);
    } else {
      window.removeEventListener("resize", update);
      window.cancelAnimationFrame(rafHandle);
    }
  };
};

const thousandSeparator = "\u2009";
const swissNumbers = formatLocale({
  decimal: ".",
  thousands: thousandSeparator,
  grouping: [3],
  currency: ["CHF\u00a0", ""],
});

export function removeTrailingZero(f: (x: number) => string) {
  return (x: number) => {
    const num = f(x);
    return num.match(/%$/) ? num.replace(/\.0%/, "%") : num.replace(/\.0/, "");
  };
}

const formatPow = (tLabel: $FixMe, baseValue: $FixMe) => {
  const decimalFormat = swissNumbers.format(".0f");
  const [n] = decimalFormat(baseValue).split(".");
  let scale = (value: $FixMe) => value;
  let suffix = "";
  if (n.length > 9) {
    scale = (value) => value / Math.pow(10, 9);
    suffix = tLabel(" Mrd.");
  } else if (n.length > 6) {
    scale = (value) => value / Math.pow(10, 6);
    suffix = tLabel(" Mio.");
  }
  return {
    scale,
    suffix,
  };
};

const sFormat = (
  tLabel: (s: string) => string,
  precision = 4,
  pow?: $FixMe,
  type = "r"
) => {
  if (isNaN(precision)) {
    debugger;
  }
  const numberFormat4 = swissNumbers.format("d");
  const numberFormat5 = swissNumbers.format(",d");
  const numberFormat = (value: $FixMe) => {
    if (String(Math.round(value)).length > 4) {
      return numberFormat5(value);
    }
    return numberFormat4(value);
  };
  // we only round suffixed values to precision
  const numberFormatWithSuffix4 = swissNumbers.format(`.${precision}${type}`);
  const numberFormatWithSuffix5 = swissNumbers.format(`,.${precision}${type}`);
  const numberFormatWithSuffix = (value: $FixMe) => {
    if (String(Math.round(value)).length > 4) {
      return numberFormatWithSuffix5(value);
    }
    return numberFormatWithSuffix4(value);
  };
  return (value: $FixMe) => {
    const fPow = pow || formatPow(tLabel, value);
    if (fPow.suffix) {
      return numberFormatWithSuffix(fPow.scale(value)) + fPow.suffix;
    }
    return numberFormat(fPow.scale(value));
  };
};

export const getFormat = (numberFormat: $FixMe, tLabel: $FixMe) => {
  const specifier = formatSpecifier(numberFormat);

  if (specifier.type === "s") {
    return sFormat(tLabel, specifier.precision);
  }
  return swissNumbers.format(specifier as $FixMe);
};

export const last = (array: $FixMe, index: $FixMe) =>
  array.length - 1 === index;

export const calculateAxis = (
  numberFormat: $FixMe,
  tLabel: $FixMe,
  domain: $FixMe,
  unit = ""
) => {
  const [min, max] = domain;
  const step = (max - min) / 2;
  const ticks = [min, min < 0 && max > 0 ? 0 : min + step, max];
  const format = swissNumbers.format;

  const specifier = formatSpecifier(numberFormat);
  const formatter = getFormat(numberFormat, tLabel);
  let regularFormat: $FixMe;
  let lastFormat: $FixMe;
  if (specifier.type === "%") {
    const fullStep = +(step * 100).toFixed(specifier.precision);
    const fullMax = +(max * 100).toFixed(specifier.precision);
    specifier.precision = precisionFixed(
      fullStep - Math.floor(fullStep) || fullMax - Math.floor(fullMax)
    );
    lastFormat = format(specifier.toString());
    specifier.type = "f";
    const regularFormatInner = format(specifier.toString());
    regularFormat = (value: $FixMe) => regularFormatInner(value * 100);
  } else if (specifier.type === "s") {
    const magnitude =
      d3Max([max - (min > 0 ? min : 0), min].map(Math.abs)) || 0;
    const pow = formatPow(tLabel, Math.max(0, min) + magnitude / 2);
    const scaledStep = pow.scale(step);
    const scaledMax = pow.scale(max);
    specifier.precision = precisionFixed(
      scaledStep - Math.floor(scaledStep) || scaledMax - Math.floor(scaledMax)
    );

    lastFormat = sFormat(tLabel, specifier.precision, pow, "f");
    regularFormat = sFormat(
      tLabel,
      specifier.precision,
      { scale: pow.scale, suffix: "" },
      "f"
    );
  } else {
    specifier.precision = precisionFixed(
      step - Math.floor(step) || max - Math.floor(max)
    );
    lastFormat = regularFormat = format(specifier.toString());
  }
  const axisFormat = (value: $FixMe, isLast: $FixMe) =>
    isLast ? `${lastFormat(value)} ${unit}` : regularFormat(value);

  return {
    ticks,
    format: formatter,
    axisFormat,
  };
};

export const get3EqualDistTicks = (scale: $FixMe) => {
  const range = scale.range();
  return [
    scale.invert(range[0]),
    scale.invert(range[0] + (range[1] - range[0]) / 2),
    scale.invert(range[1]),
  ];
};

const subSupSplitter = (createTag: $FixMe) => {
  return (input: $FixMe) => {
    if (!input) {
      return input;
    }
    return input
      .split(/(<sub>|<sup>)([^<]+)<\/su[bp]>/g)
      .reduce((elements: $FixMe, text: $FixMe, i: $FixMe) => {
        if (text === "<sub>" || text === "<sup>") {
          elements.nextElement = text.replace("<", "").replace(">", "");
        } else {
          if (elements.nextElement) {
            elements.push(
              createTag(elements.nextElement, elements.nextElement + i, text)
            );
            elements.nextElement = null;
          } else {
            elements.push(text);
          }
        }
        return elements;
      }, []);
  };
};

export const subsup = subSupSplitter((tag: $FixMe, key: $FixMe, text: $FixMe) =>
  createElement(tag, { key }, text)
);
(subsup as $FixMe).svg = subSupSplitter(
  (tag: $FixMe, key: $FixMe, text: $FixMe) => {
    const dy = tag === "sub" ? "0.25em" : "-0.5em";
    return (
      <Fragment key={key}>
        <tspan dy={dy} fontSize="75%">
          {text}
        </tspan>
        {/* reset dy: https://stackoverflow.com/a/33711370 */}
        {/* adds a zero width space */}
        <tspan dy={`-${dy}`}>{"\u200b"}</tspan>
      </Fragment>
    );
  }
);

export const transparentAxisStroke = "rgba(0, 0,0,0.17)";
export const circleFill = "#fff";
export const baseLineColor = M.divider;

export const deduplicate = (d: $FixMe, i: $FixMe, all: $FixMe) =>
  all.indexOf(d) === i;

export function isDefined<T>(d: T | undefined): d is T {
  return d !== undefined;
}

// This is unsafe
// - all props that are passed to unsafeDatumFn should not be user defined
//   currently: filter, columnFilter.test, category, highlight
// eslint-disable-next-line no-new-func
type DatumFn = (code: string) => (d: $FixMe) => $FixMe;
export const unsafeDatumFn: DatumFn = (code) =>
  new Function("datum", `return ${code}`) as (d: $FixMe) => $FixMe;

export const getTextColor = (bgColor: $FixMe) => {
  const color = rgb(bgColor);
  const yiq = (color.r * 299 + color.g * 587 + color.b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
};

/**
 * Finds the nearest value to 'datum' within 'data'
 */
export function findClosest<A, B extends number | { valueOf(): number }>(
  data: Array<A>,
  acc: (x: A) => B,
  datum: B
) {
  const idx = bisector(acc).left(data, datum);
  const d0 = data[idx - 1] || data[0];
  const d1 = data[idx] || d0;
  return datum.valueOf() - acc(d0).valueOf() >
    acc(d1).valueOf() - datum.valueOf()
    ? d1
    : d0;
}

/**
 * Adapted from DataWrapper
 * https://github.com/datawrapper/shared/blob/master/estimateTextWidth.js
 *
 *
 * returns the estimated width of a given text in Roboto.
 * this method has proven to be a good compromise between pixel-perfect
 * but expensive text measuring methods using canvas or getClientBoundingRect
 * and just multiplying the number of characters with a fixed width.
 *
 * be warned that this is just a rough estimate of the text width. the
 * character widths will vary from typeface to typeface and may be
 * off quite a bit for some fonts (like monospace fonts).
 *
 * @exports estimateTextWidth
 * @kind function
 *
 * @param {string} text - the text to measure
 * @param {number} fontSize - the output font size (optional, default is 14)
 *
 * @example
 * import estimateTextWidth from '@datawrapper/shared/estimateTextWidth';
 * // or import {estimateTextWidth} from '@datawrapper/shared';
 * const width = estimateTextWidth('my text', 12);
 *
 * @export
 * @returns {number}
 */
export const estimateTextWidth = (text: string, fontSize = 14) => {
  const f = fontSize / 14;
  return (
    text.split("").reduce((w, char) => w + (CHAR_W[char] || CHAR_W.a), 0) * f
  );
};

const CHAR_W: { [x: string]: number } = {
  a: 9,
  A: 10,
  b: 9,
  B: 10,
  c: 8,
  C: 10,
  d: 9,
  D: 11,
  e: 9,
  E: 9,
  f: 5,
  F: 8,
  g: 9,
  G: 11,
  h: 9,
  H: 11,
  i: 4,
  I: 4,
  j: 4,
  J: 4,
  k: 8,
  K: 9,
  l: 4,
  L: 8,
  m: 14,
  M: 12,
  n: 9,
  N: 10,
  o: 9,
  O: 11,
  p: 9,
  P: 8,
  q: 9,
  Q: 11,
  r: 6,
  R: 10,
  s: 7,
  S: 9,
  t: 5,
  T: 9,
  u: 9,
  U: 10,
  v: 8,
  V: 10,
  w: 11,
  W: 14,
  x: 8,
  X: 10,
  y: 8,
  Y: 9,
  z: 7,
  Z: 10,
  ".": 4,
  ",": 4,
  ":": 5,
  ";": 5,
  "-": 5,
  "+": 12,
  " ": 4,
};
