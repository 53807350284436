import { AnimatePresence, motion } from "framer-motion";
import React, { HTMLAttributes, useEffect, useMemo, useRef } from "react";
import { css } from "@emotion/core";
import SvgIcClose16Dp from "icons/SvgIcClose16Dp";
import * as M from "../materials";
import { useTheme } from "hooks/useTheme";

const ClickAwayListener = ({
  children,
  onClickAway,
}: {
  children: React.ReactElement;
  onClickAway?: () => void;
}) => {
  const elementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleClick = (ev: MouseEvent) => {
      if (!elementRef.current) {
        return;
      }
      const bcr = elementRef.current.getBoundingClientRect();
      const { clientX: cx, clientY: cy } = ev;
      if (cx < bcr.left || cx > bcr.right || cy < bcr.top || cy > bcr.bottom) {
        onClickAway?.();
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  return React.cloneElement(children, {
    ref: elementRef,
  });
};

const getPopupStyle = (popupProps: PopupProps) => {
  const { anchorOffset, anchorPosition, container, anchor } = popupProps;
  const style: HTMLAttributes<HTMLDivElement>["style"] = {
    position: "absolute",
  };

  if (!container || !anchor) {
    return style;
  }

  const containerBcr = container
    ? container.getBoundingClientRect()
    : { top: 0, left: 0 };
  const anchorBcr = anchor.getBoundingClientRect();
  const top =
    anchorBcr.top -
    containerBcr.top +
    (anchorPosition.vertical === "end"
      ? anchorBcr.height
      : anchorPosition.vertical === "middle"
        ? anchorBcr.height / 2
        : 0) +
    (anchorOffset?.vertical || 0);
  const left =
    anchorBcr.left -
    containerBcr.left +
    (anchorPosition.horizontal === "end"
      ? anchorBcr.width
      : anchorPosition.horizontal === "middle"
        ? anchorBcr.width / 2
        : 0) +
    (anchorOffset?.horizontal || 0);

  style.top = top;
  style.left = left;

  return style;
};

const IconButton = ({
  children,
  ...props
}: { children: React.ReactNode } & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      css={css`
        color: ${M.unescoMarineBlue};
        border-radius: 2rem;
        cursor: pointer;
        padding: 0.5rem;

        &:hover {
          background: ${M.grayscalePalette[2]};
        }
      `}
      {...props}
    >
      {children}
    </div>
  );
};

type PopupProps = {
  children: React.ReactNode;
  style?: HTMLAttributes<HTMLDivElement>["style"];
  onClose?: () => void;
  open: boolean;
  container?: HTMLElement | null;
  anchor?: HTMLElement | null;
  anchorPosition: {
    horizontal: "start" | "end" | "middle";
    vertical: "start" | "end" | "middle";
  };
  anchorOffset: {
    horizontal?: number;
    vertical?: number;
  };
  showCloseButton?: boolean;
};

const KeyListener = ({
  onKey,
  name,
}: {
  onKey: (() => void) | undefined;
  name: string;
}) => {
  useEffect(() => {
    const handle = (ev: KeyboardEvent) => {
      if (ev.key === name) {
        onKey?.();
      }
    };
    document.addEventListener("keydown", handle);
    return () => {
      document.removeEventListener("keydown", handle);
    };
  });
  return null;
};

export const PopupDesktop = (popupProps: PopupProps) => {
  const { children, style, onClose, open, showCloseButton = true } = popupProps;
  const style2 = useMemo(() => {
    return getPopupStyle(popupProps);
  }, [popupProps]);

  return (
    <AnimatePresence>
      {open ? (
        <>
          <KeyListener name="Escape" onKey={onClose} />
          <ClickAwayListener onClickAway={onClose}>
            <motion.div
              style={{ ...style, ...style2 }}
              initial={{
                opacity: 0,
                scaleX: 0,
                scaleY: 0,
                originX: 0,
                originY: 0,
              }}
              animate={{
                opacity: 1,
                scaleX: 1,
                scaleY: 1,
              }}
              exit={{
                opacity: 0,
                y: 100,
                transition: {
                  opacity: {
                    duration: 0.25,
                  },
                  y: {
                    duration: 0.3,
                  },
                },
              }}
            >
              {showCloseButton ? (
                <IconButton
                  css={css`
                    position: absolute;
                    right: 0.75rem;
                    top: 0.75rem;
                    z-index: 2;
                  `}
                  onClick={onClose}
                >
                  <SvgIcClose16Dp />
                </IconButton>
              ) : null}
              {/* Arrow shadow, arrow is transparent */}
              <div
                css={css`
                  transform: rotate(-90deg);
                  color: transparent;
                  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
                  position: absolute;
                  left: -0.8rem;
                  top: 24px;
                  font-size: 1rem;
                `}
              >
                ▲
              </div>
              {/* Real arrow, no shadow */}
              <div
                css={css`
                  transform: rotate(-90deg);
                  color: white;
                  position: absolute;
                  left: -0.8rem;
                  top: 24px;
                  z-index: 1;
                  font-size: 1rem;
                `}
              >
                ▲
              </div>
              <div
                css={css`
                  background: rgba(255, 255, 255, 0.85);
                  padding: 1rem;
                  backdrop-filter: blur(10px);
                  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
                  border-radius: 4px;
                `}
              >
                {children}
              </div>
            </motion.div>
          </ClickAwayListener>
        </>
      ) : (
        false
      )}
    </AnimatePresence>
  );
};

const PopupMobile = ({
  open,
  children,
  onClose,
  showCloseButton = true,
}: PopupProps) => {
  return (
    <AnimatePresence>
      {open ? (
        <ClickAwayListener onClickAway={onClose}>
          <motion.div
            initial={{
              position: "fixed",
              y: 400,
              left: 0,
              right: 0,
              bottom: "env(safe-area-inset-bottom, 0)",
              borderRadius: 10,
              overflow: "hidden",
              maxHeight: "80vh",
              boxShadow: "0 -10px 10px rgba(0, 0, 0, 0.25)",
              background: "white",
              padding: "1rem",
            }}
            animate={{
              y: 0,
            }}
            transition={{
              type: "spring",
              stiffness: 200,
              damping: 20,
            }}
            exit={{
              y: 400,
            }}
          >
            {showCloseButton ? (
              <IconButton
                css={css`
                  position: absolute;
                  right: 0.75rem;
                  top: 0.75rem;
                  z-index: 2;
                `}
                onClick={onClose}
              >
                <SvgIcClose16Dp />
              </IconButton>
            ) : null}
            {children}
          </motion.div>
        </ClickAwayListener>
      ) : null}
    </AnimatePresence>
  );
};

export const Popup = (props: PopupProps) => {
  const { client } = useTheme();

  return client.screenMDown ? (
    <PopupMobile {...props} />
  ) : (
    <PopupDesktop {...props} />
  );
};
