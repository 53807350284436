export type FocusIndex = {
  published: string[];
};

export const focusArticles: FocusIndex = {
  published: [
    // "0-template",
    "1-demand",
    "2-teachoutfield",
    "3-calendar",
    "4-singlesexschools",
    "5-infrastructure",
    "6-learningnotlinear",
    "7-safeschools",
    "8-scholarshipsSSA",
    "9-indigenous",
    "10-earlyentry",
    "11-adulteducation",
    "12-lowperformance",
    "13-Spending",
    "14-householdwealth",
    "15-taxrevenue",
    "16-scholarships",
    "17-studentdebt",
    "18-climatechange",
    "19-numeracyskills",
    "20-computationalthinking",
    "21-writingtools",
    "22-microdredentials",
    "23-firstgeneration",
    "24-readingspeed",
    "25-schoolbuilding",
    "26-solarenergy",
    "27-onlinesearch",
    "28-mathanxiety",
    "29-ecceleaders",
    "30-teachleadership",
    "31-peereffects",
    "32-civicedu",
    "33-climateinfra",
    "34-NSAscholarships",
    "35-teachershortage",
    "36-principalsalaries",
    "37-climatefin",
  ],
};
