import css from "@emotion/css";
import styled from "@emotion/styled";
import { MessageDescriptor } from "@lingui/core";
import { Trans } from "@lingui/macro";
import useLocale from "hooks/useLocale";
import React, { PropsWithChildren } from "react";
import { useInView } from "react-intersection-observer";
import { ChartRenderer, RenderMode } from "../domain";
import {
  FigureStateProvider,
  foldFigureControl,
  useFigureState,
  useUrlFigureState,
} from "../domain/figure-state";
import { useTheme } from "../hooks";
import { useI18n } from "../locales";
import * as M from "../materials";
import { Layout } from "../materials";
import { io, O, pipe, R } from "../prelude";
import { withDataLoader } from "./data-loader";
import { FigureControls } from "./figure-controls";
import { MultiLogoIO } from "./logo";
import MobileControlPanel from "./mobile-controls-panel";
import { DownloadButton, InfoButton, ShareButton } from "./tooltip-triggers";

type FigureSize = "narrow" | "main" | "figure" | "wide";
export interface FigureHeight {
  narrow: number;
  wide: number;
}

const styles = {
  root: css`
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  `,
  figureheader: css`
    max-width: 100%;
    grid-column: ${M.layoutAreas.main};
  `,
  figurebodyBase: css`
    direction: ltr;
    margin-top: 0;
  `,
  figurebody: {
    narrow: css`
      grid-column: ${M.layoutAreas.main};
      & > * {
        max-width: ${M.spacing.base8(60)};
      }
    `,
    main: css`
      grid-column: ${M.layoutAreas.main};
    `,
    figure: css`
      grid-column: ${M.layoutAreas.figure};
      margin-top: 0;
    `,
    wide: css`
      grid-column: ${M.layoutAreas.wide};
    `,
  },
  title: css`
    ${M.fontHeading4}
    margin-bottom: ${M.spacing.base8(2)};
    &::before {
      content: "—";
      display: block;
      font-weight: normal;
    }
  `,
  controls: css`
    display: flex;
    &:not(:empty) {
      margin-bottom: ${M.spacing.base8(2)};
    }
    @media ${M.bpDown("m")} {
      flex-direction: column;
    }
  `,
  iconcontrols: css`
    display: flex;
    flex-direction: row;
    margin-bottom: ${M.spacing.base8(2)};
  `,
  controlbox: css`
    display: flex;
    flex-wrap: wrap;
  `,
  controlboxVertical: css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
  `,
  controlboxFallback: M.onlyIE(css`
    display: block;
  `),
  controlBoxItem: css`
    flex: 1;
    max-width: 100%;
    margin-right: ${M.spacing.base8(1)};

    &:only-child [data-control-type="select"] {
      @media ${M.bpDown("s")} {
        max-width: 100%;
      }
    }

    @media ${M.bpDown("s")} {
      margin-right: 0;
    }
  `,
  controlBoxItemVertical: css`
    flex: 1;
    min-width: 100%;
    max-width: 100%;
  `,
  divider: css`
    height: 32px;
    width: 1px;
    margin-right: ${M.spacing.base8(2)};
    margin-left: ${M.spacing.base8(2)};
    background-color: ${M.divider};

    @media ${M.bpDown("m")} {
      opacity: 0;
      display: none;
    }
  `,
  dividerSilent: css`
    width: ${M.spacing.base8(2)};
  `,
};

export interface FigureMetadata {
  id: string;
  title: MessageDescriptor;
  caption: MessageDescriptor;
  source: MessageDescriptor;
}

export const Divider = ({}) => {
  return <div css={styles.divider} />;
};

const DividerSilent = ({}) => {
  return <div role="presentation" css={styles.dividerSilent} />;
};

export const ControlBox = ({
  children,
  orientation = "horizontal",
}: { orientation: "horizontal" | "vertical" } & $PropsWithChildren) => {
  const numChildren = React.Children.toArray(children).length;

  return (
    <div
      css={[
        orientation === "vertical"
          ? styles.controlboxVertical
          : styles.controlbox,
        numChildren >= 3 && styles.controlboxFallback,
      ]}
    >
      {React.Children.map(children, (c, i) => {
        if (c === null) {
          return null;
        }
        return (
          <div
            css={
              orientation === "vertical"
                ? styles.controlBoxItemVertical
                : styles.controlBoxItem
            }
            key={i}
          >
            {c}
          </div>
        );
      })}
    </div>
  );
};

export const Figure = ({
  children,
  id,
  csv,
  xlsx,
  title,
  caption,
  source: sourceRaw,
  renderMode,
  size = "figure",
  layout = "article",
  disableEmbed,
  dataset,
}: $PropsWithChildren<
  FigureMetadata & {
    renderMode: RenderMode;
    csv: string;
    xlsx: string;
    size?: FigureSize;
    layout?: Layout;
    disableEmbed?: boolean;
    dataset?: string;
  }
>) => {
  const i18n = useI18n();
  const { client } = useTheme();
  const parsedFigureState = useUrlFigureState();
  const [ref, inView] = useInView({
    rootMargin: "25%",
    triggerOnce: true,
  });

  // Assignment with detours to improve variable naming in translated string
  const source = sourceRaw ? i18n._(sourceRaw) : undefined;
  const sourceText = source ? <Trans>Source: {source}.</Trans> : undefined;
  const captionText = i18n._(caption);
  const titleText = title ? i18n._(title) : parsedFigureState.title;

  const controlValues = pipe(
    parsedFigureState.controls,
    O.map((s) =>
      R.toArray(s).map(([_, c]) =>
        foldFigureControl(c, {
          ButtonGroup: (control) => control.selected,
          Checkbox: (control) =>
            control.selected.toString() === "true" ? control.label : undefined,
          SingleSelect: (control) => O.toUndefined(control.selected),
          MultiSelect: (control) =>
            `${pipe(
              control.selected,
              O.map((d) => `${control.label}: ${d.join(", ")}`),
              O.toUndefined
            )}`,
          Timeline: (control) => control.selected.toString(),
          Radio: (control) => O.toUndefined(control.selected),
          MultiCheckbox: (control) =>
            `${pipe(
              control.selected,
              O.map((d) => `${control.label}: ${d.join(", ")}`),
              O.toUndefined
            )}`,
        })
      )
    ),
    O.toUndefined
  );

  const controlValuesText = controlValues ? (
    <Trans>Selection: {controlValues.filter(Boolean).join("; ")}.</Trans>
  ) : undefined;

  const iconControls = (
    <div css={styles.iconcontrols}>
      {renderMode !== "embed" && (
        <>
          <InfoButton content={captionText} source={sourceText} />
          <DividerSilent />
        </>
      )}
      <DownloadButton id={id} csv={csv} xlsx={xlsx} />
      <DividerSilent />
      <ShareButton
        id={id}
        message={titleText}
        disableEmbed={disableEmbed}
        dataset={dataset}
      />
    </div>
  );

  return (
    <FigureStateProvider initialState={parsedFigureState}>
      {renderMode === "static" ? (
        <StaticChart
          layout={size === "narrow" ? "rows" : "columns"}
          title={titleText}
          source={sourceText}
          caption={captionText}
          controlValues={controlValuesText}
        >
          {children}
        </StaticChart>
      ) : renderMode === "embed" ? (
        <EmbedChart
          caption={captionText}
          source={sourceText}
          iconControls={iconControls}
        >
          {children}
        </EmbedChart>
      ) : (
        <M.Grid layout={layout} as="figure" styles={styles.root}>
          <div css={styles.figureheader} ref={ref} id={id}>
            {title && <h3 css={styles.title}>{titleText}</h3>}
            <div css={styles.controls}>
              {!client.screenMDown && iconControls}

              {!client.screenMDown && (
                <>
                  <FigureControls position="TOP" />
                  <FigureControls showDivider={false} position="TOOLBAR" />
                </>
              )}
            </div>
          </div>

          {inView && <FigureView size={size}>{children}</FigureView>}

          {client.screenMDown ? (
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              {iconControls}
              <div
                css={css`
                  flex-grow: 1;
                `}
              ></div>
              <FigureControls position="TOOLBAR" />
            </div>
          ) : null}
        </M.Grid>
      )}
    </FigureStateProvider>
  );
};

const FigureView = ({
  children,
  size = "figure",
}: $PropsWithChildren<{
  size?: FigureSize;
}>) => {
  const [state] = useFigureState();
  const { client } = useTheme();

  const hasSideControls = pipe(
    state.controls,
    O.map((controls) => {
      return R.toArray(controls).reduce(
        (prev, [, control]) =>
          control.position === "LEFT" || control.position === "RIGHT" || prev,
        true
      );
    }),
    O.toNullable
  );

  return (
    <>
      {client.screenMDown && hasSideControls ? (
        <>
          <MobileControlPanel />
          <div css={[styles.figurebodyBase, styles.figurebody[size]]}>
            {children}
          </div>
        </>
      ) : (
        <>
          <SideControls side="left" visible={hasSideControls}>
            <FigureControls showDivider={false} position="LEFT" />
          </SideControls>
          <div css={[styles.figurebodyBase, styles.figurebody[size]]}>
            {children}
          </div>
          <SideControls side="right" visible={hasSideControls}>
            <FigureControls showDivider={false} position="RIGHT" />
          </SideControls>
        </>
      )}
    </>
  );
};

const SideControls = styled("div")<{
  side: "left" | "right";
  visible: boolean | null;
}>`
  grid-column: ${({ side }) =>
    side === "left"
      ? `${M.layoutAreas.wideStart} / ${M.layoutAreas.mainStart}`
      : `    ${M.layoutAreas.mainEnd} / ${M.layoutAreas.wideEnd};
  `};

  @media ${M.bpDown("m")} {
    grid-column: ${M.layoutAreas.main};
  }

  ${({ visible }) => (!visible ? `display: none;` : undefined)}
`;

const embedChartStyles = {
  root: css`
    max-width: 1200px;
    margin: 0 auto;
    padding: ${M.spacing.base8(3)} ${M.spacing.base8(5)};
    position: relative;
    color: ${M.lightText};
  `,
  content: css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: ${M.spacing.base8(2)};
  `,
  chart: css`
    min-width: 1px;
    width: 100%;
    flex: 1;
  `,
  meta: css`
    ${M.fontTable};
    display: flex;
    background: white;
    justify-content: space-between;
    padding: ${M.spacing.base8(2)} 0;
    gap: ${M.spacing.base8(5)};

    @media ${M.bpDown("s")} {
      flex-direction: column;
      align-items: flex-start;
      gap: ${M.spacing.base8(2)};
    }
  `,
  metaHero: css`
    margin-right: ${M.spacing.base8(2)}};
  `,
  small: css`
    display: block;
    ${M.fontChartLabel};
  `,
  link: css`
    display: block;
    margin-top: ${M.spacing.base8(0.5)};
    ${M.fontBody2};
    font-weight: 500;
    color: ${M.unescoMainBlue};

    :hover {
      text-decoration: underline;
    }
  `,
};

export const EmbedChart = ({
  children,
  caption,
  source,
  iconControls,
  isHeroChart,
}: PropsWithChildren<{
  caption: string;
  source: React.ReactNode;
  iconControls?: React.ReactNode;
  isHeroChart?: boolean;
}>) => {
  const locale = useLocale();
  const { client } = useTheme();
  const logoEl = <MultiLogoIO size="small" variant="positive" />;

  return (
    <div css={embedChartStyles.root}>
      <div css={styles.controls}>
        {!client.screenMDown && iconControls}
        <FigureControls />
      </div>

      <div css={embedChartStyles.content}>
        <div
          css={[
            embedChartStyles.chart,
            // Hero charts work with RTL, others seem to break
            !isHeroChart ? { direction: "ltr" } : undefined,
          ]}
        >
          {children}
        </div>
        <div
          css={[
            embedChartStyles.meta,
            isHeroChart ? embedChartStyles.metaHero : undefined,
          ]}
        >
          <div>
            <div css={embedChartStyles.small}>{caption}</div>
            <div css={embedChartStyles.small}>{source}</div>
            <a
              href={`https://www.education-progress.org/${locale}`}
              target="_blank"
              rel="noreferrer"
              css={embedChartStyles.link}
            >
              <Trans>Visit the GEM Report SCOPE website</Trans>
            </a>
          </div>
          {logoEl}
        </div>
      </div>
    </div>
  );
};

const staticChartStyles = {
  root: css`
    max-width: 1200px;
    margin: 0 auto;
    padding: ${M.spacing.base8(3)} ${M.spacing.base8(5)};
    position: relative;
    color: ${M.lightText};
  `,
  chart: css`
    direction: ltr;
    min-height: ${M.spacing.base8(55)};
    min-width: 1px;
    width: 100%;
    flex: 1;
  `,
  content: css`
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: ${M.spacing.base8(2)};
  `,
  contentAside: css`
    padding: ${M.spacing.base8(5)} 0;
  `,
  meta: css`
    ${M.fontTable};
    display: flex;
    background: white;
    justify-content: space-between;
    align-items: center;
    padding: ${M.spacing.base8(2)} 0;
  `,
  metaAside: css`
    flex-direction: column;
    /* ~golden ratio */
    max-width: 42.8%;
    padding: 0;
    padding-top: ${M.spacing.base8(2)};
    padding-left: ${M.spacing.base8(4)};
  `,
  title: css`
    ${M.fontHeading4};
    color: ${M.blackText};
  `,
  small: css`
    display: block;
    ${M.fontChartLabel};
  `,
  smallPadded: css`
    padding: ${M.spacing.base8(1)} 0;
  `,
};

export const StaticChart = ({
  children,
  layout,
  title,
  source,
  caption,
  controlValues,
}: PropsWithChildren<{
  layout: "columns" | "rows";
  title: string;
  caption: string;
  source: React.ReactNode;
  controlValues?: React.ReactNode;
}>) => {
  const locale = useLocale();
  const today = new Date();
  const options = { year: "numeric", month: "short", day: "numeric" } as const;
  const formatted = today.toLocaleDateString(locale, options);
  const isAside = layout === "rows";
  const logoEl = <MultiLogoIO size="small" variant="positive" />;
  const header = (
    <div>
      <h3 css={staticChartStyles.title}>{title}</h3>
      {caption}
    </div>
  );
  return (
    <div css={staticChartStyles.root}>
      {!isAside && header}

      <div
        css={[
          staticChartStyles.content,
          isAside && staticChartStyles.contentAside,
        ]}
        style={{
          flexDirection: isAside ? "row" : "column",
        }}
      >
        <div css={staticChartStyles.chart}>{children}</div>
        <div
          css={[staticChartStyles.meta, isAside && staticChartStyles.metaAside]}
        >
          {isAside && header}
          <div>
            <div css={staticChartStyles.small}>
              <Trans>
                This graphic was designed for the GEM Report Education Progress
                website.
              </Trans>
              <a href="https://www.education-progress.org">
                {" "}
                www.education-progress.org
              </a>{" "}
            </div>
            <div
              css={[
                staticChartStyles.small,
                isAside && staticChartStyles.smallPadded,
              ]}
            >
              {controlValues} {controlValues && <br />}
              {source} {isAside && <br />}
              <Trans>Accessed</Trans>: {formatted}
            </div>
            {isAside && logoEl}
          </div>
          {!isAside && logoEl}
        </div>
      </div>
    </div>
  );
};

export interface Dataset {
  [key: string]: {
    filename: string;
    url: string;
    csv: string;
    xlsx: string;
  };
}

interface FigureIOPProps<I, O> {
  url: string;
  csv: string;
  xlsx: string;
  metadata: FigureMetadata;
  Data: io.Decoder<I, O>;
  Chart: React.ComponentType<ChartRenderer<O>>;
  size?: FigureSize;
  /** Refers to minimum height for the loader to avoid screen jumping when the chart is loaded on intersection */
  height?: FigureHeight;
  datasets: Dataset;
}

export interface FigureProps {
  renderMode: RenderMode;
  dataset?: string;
  renderMultiple?: boolean;
}

export function withFigureIO<I, O>(props: FigureIOPProps<I, O>) {
  const {
    url,
    csv,
    xlsx,
    metadata,
    Data,
    Chart,
    size = "figure",
    height = { narrow: 500, wide: 500 },
    datasets,
  } = props;
  return ({ renderMode, dataset, renderMultiple = false }: FigureProps) => {
    const Renderer =
      dataset && datasets
        ? withDataLoader(datasets[dataset].url, Data, Chart, height)
        : withDataLoader(url, Data, Chart, height);
    return (
      <>
        <Figure
          {...metadata}
          size={size}
          renderMode={renderMode}
          csv={csv}
          xlsx={xlsx}
          {...(datasets && dataset && { dataset })}
        >
          <Renderer renderMode={renderMode} />
        </Figure>
        {renderMultiple &&
          Object.entries(datasets).map(([key, value]) => {
            const Renderer = withDataLoader(value.url, Data, Chart, height);
            return (
              <>
                <h2 style={{ textAlign: "center" }}>
                  Dataset: {value.filename}
                </h2>
                <Figure
                  key={key}
                  {...metadata}
                  size={size}
                  renderMode="embed"
                  csv={value.csv}
                  xlsx={value.xlsx}
                  dataset={key}
                >
                  <Renderer renderMode="embed" />
                </Figure>
              </>
            );
          })}
      </>
    );
  };
}

export function withFigureIOExplorer<O>({
  metadata,
  Chart,
  size = "main",
  csv,
  xlsx,
}: {
  metadata: FigureMetadata;
  Chart: React.ComponentType<Omit<ChartRenderer<O>, "data">>;
  size?: FigureSize;
  /** Refers to minimum height for the loader to avoid screen jumping when the chart is loaded on intersection */
  height?: FigureHeight;
  csv: string;
  xlsx: string;
}) {
  return ({ renderMode }: { renderMode: RenderMode }) => (
    <>
      <Figure
        {...metadata}
        size={size}
        layout={"explorer"}
        renderMode={renderMode}
        /**
         * @FIXME
         * needs discussion, static data does not necessarily make sense for simulations
         */
        csv={csv}
        xlsx={xlsx}
        // FIXME: ideally we should be able to embed the explorer, but it needs some work
        disableEmbed={true}
      >
        <Chart renderMode={renderMode} />
      </Figure>
      <div data-loader-ready style={{ display: "none" }}></div>
    </>
  );
}
