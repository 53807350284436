import css from "@emotion/css";
import * as M from "materials";
import React, { useContext, useState } from "react";
import { mkStyles } from "../../hooks";
import { Figure } from "./figure";
import { ExplorerContext } from "../../domain";
import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { useI18n } from "locales";
import { ExplorerProvider } from "./explorer-provider";

const useStyles = mkStyles((theme) => {
  return {
    tabNavigation: css`
      display: inline-block;
      position: relative;
      bottom: -2px;
      gap: ${M.spacing.base8(2)};
      padding-top: ${M.spacing.base8(6)};
    `,
    tab: css`
      border: 1px solid ${theme.palette.grayscale[4]};
      border-top-left-radius: ${M.spacing.base8(0.5)};
      border-top-right-radius: ${M.spacing.base8(0.5)};
      background-color: white;
      border-bottom: none;
      color: ${M.blackText};
      padding: ${M.spacing.base8(0.5)} ${M.spacing.base8(1)};
      margin-right: ${M.spacing.base8(1)};
      ${M.fontBody2Hero};
      cursor: pointer;
    `,
    tabActive: css`
      border-bottom: 3px solid ${theme.palette.grayscale[1]};
      background-color: ${theme.palette.grayscale[1]};
      color: ${M.unescoMarineBlue};
    `,
    root: css`
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border-top: 1px solid ${theme.palette.grayscale[4]};
      background-color: ${theme.palette.grayscale[1]};
    `,
    leftFilter: css`
      grid-column: ${M.layoutAreas.wideStart};
    `,
    rightFilter: css`
      grid-column: ${M.layoutAreas.mainEnd};
    `,
    intro: css`
      grid-column: ${M.layoutAreas.main};
    `,
    title: css`
      ${M.fontHeading4}
    `,
    description: css`
      margin-top: 1rem;
    `,
  };
});

const TABS = ["trajectories", "simulations"] as const;
type Tab = (typeof TABS)[number];

const tabNameLookup: Record<Tab, MessageDescriptor> = {
  trajectories: t(
    "fig.learning.trajectories.explorer.tab.trajectories.name"
  )`Trajectories`,
  simulations: t(
    "fig.learning.simulations.explorer.tab.simulations.name"
  )`Simulations`,
};

const tabTitleLookup: Record<Tab, MessageDescriptor> = {
  trajectories: t(
    "fig.learning.trajectories.explorer.tab.trajectories.title"
  )`Learning trajectories builder`,
  simulations: t(
    "fig.learning.simulations.explorer.tab.simulations.title"
  )`Policy simulator`,
};

const ErrorMessage = () => {
  const {
    immer: [state],
  } = useContext(ExplorerContext);
  return state.status === "error" && state.errorMessage ? (
    <div style={{ color: "red" }}>{state.errorMessage}</div>
  ) : null;
};

export const Explorer = ({ children }: $PropsWithChildren) => {
  const styles = useStyles();
  const [activeTab, setActiveTab] = useState<Tab>("trajectories");
  const i18n = useI18n();

  const handleClick = (tabId: Tab) => {
    setActiveTab(tabId);
  };

  /* 
  FIXME: Share url for figures depends on div ids to link to the correct page section. Since the figure 
  TRAJECTORIES_EXPLORER_SIMULATION is only present when the user clicks on the Simulation tab, it 
  doesn't exist on page load. In order for the user to still be taken to the Data Explorer, we 
  specify its id here.
  */
  return (
    <ExplorerProvider>
      <div css={styles.tabNavigation} id="TRAJECTORIES_EXPLORER_SIMULATION">
        {TABS.map((tab, index) => (
          <button
            key={index}
            css={[styles.tab, tab === activeTab ? styles.tabActive : null]}
            onClick={() => handleClick(tab)}
          >
            {i18n._(tabNameLookup[tab])}
          </button>
        ))}
      </div>
      <M.Grid layout="explorer" styles={styles.root}>
        <ErrorMessage />
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            if (
              activeTab === "trajectories" &&
              child.props.mdxType === "TrajectoriesTab"
            )
              return child;
            if (
              activeTab === "simulations" &&
              child.props.mdxType === "SimulationsTab"
            )
              return child;
          }
          return null;
        })}
      </M.Grid>
    </ExplorerProvider>
  );
};

export const TrajectoriesTab = ({ children }: $PropsWithChildren) => {
  const styles = useStyles();
  const i18n = useI18n();

  return (
    <>
      <h3 css={styles.title}>{i18n._(tabTitleLookup["trajectories"])}</h3>
      <p css={styles.description}>{children}</p>

      <Figure id="TRAJECTORIES_EXPLORER_CURRENT" />
    </>
  );
};

export const SimulationsTab = ({ children }: $PropsWithChildren) => {
  const styles = useStyles();
  const i18n = useI18n();

  return (
    <>
      <h3 css={styles.title}>{i18n._(tabTitleLookup["simulations"])}</h3>
      <p css={styles.description}>{children}</p>

      <Figure id="TRAJECTORIES_EXPLORER_SIMULATION" />
    </>
  );
};
