import { useI18n } from "locales";
import React from "react";
import { SankeyAuto } from "../charts-motion/sankey";
import { withFigureIO } from "../components/figure";
import { useConfig } from "../config";
import { metadata } from "../data/data_finance_fig_AID_FLOWS";
import { ChartRenderer, getAidFlowName, mkGemDataDecoder } from "../domain";
import * as M from "../materials";
import { io } from "../prelude";
import { t } from "@lingui/macro";

export * from "../data/data_finance_fig_AID_FLOWS";

export const Data = mkGemDataDecoder([], {
  donor: io.string,
  flow: io.number,
  recipient: io.string,
  level: io.string,
});
export type Data = io.TypeOf<typeof Data>;
export type NodeGroup<D extends Record<string, any>> = {
  nodes: keyof D;
  depth: number;
  label: string;
  category: string;
};

export const Chart = ({ data }: ChartRenderer<Data>) => {
  const { aidflows } = useConfig();
  const i18n = useI18n();

  const nodeGroups: NodeGroup<Data[number]>[] = React.useMemo(
    () => [
      {
        nodes: "donor",
        depth: 0,
        label: i18n._(t`Donors`),
        category: "donors",
      },
      {
        nodes: "recipient",
        depth: 1,
        label: i18n._(t`Recipients`),
        category: "recipients",
      },
      {
        nodes: "level",
        depth: 2,
        label: i18n._(t`Education levels`),
        category: "education_levels",
      },
    ],
    [i18n]
  );

  const colorPalettes = React.useMemo(
    () => [
      { label: i18n._(t`Donors`), palette: M.q06Palette, id: "donors" },
      { label: i18n._(t`Recipients`), palette: M.q03Palette, id: "recipients" },
      {
        label: i18n._(t`Education levels`),
        palette: M.q10Palette,
        id: "education_levels",
      },
    ],
    [i18n]
  );

  const sankeyData = React.useMemo(() => {
    const localizedData = data.map((row) => ({
      ...row,
      donorId: row.donor,
      donor: getAidFlowName(aidflows, "donor", row.donor),
      recipientId: row.recipient,
      recipient: getAidFlowName(aidflows, "recipient", row.recipient),
      levelId: row.level,
      level: getAidFlowName(aidflows, "level", row.level),
    }));
    return computeSankeyNodes(localizedData, nodeGroups);
  }, [aidflows, data, nodeGroups]);

  return (
    <SankeyAuto
      data={sankeyData}
      height={M.chartHeight.l}
      colorPalettes={colorPalettes}
      nodeGroups={nodeGroups}
    />
  );
};

export default withFigureIO({
  url: require("../data/data_finance_fig_AID_FLOWS.json"),
  csv: require("../data/data_finance_fig_AID_FLOWS.zip"),
  xlsx: require("../data/data_finance_fig_AID_FLOWS.xlsx"),
  metadata,
  Data,
  Chart,
  datasets: require("../data/data_finance_fig_AID_FLOWS_datasets").datasets,
});

// -----------------------------------------------------------------------------

export function computeSankeyNodes<D extends { flow: number }>(
  data: D[],
  nodeGroups: NodeGroup<D>[]
) {
  const nodes = Array.from(
    new Set(
      nodeGroups.flatMap((group) => data.map((x) => x[group.nodes]))
    ).values()
  ).map((id) => ({ id: id as $FixMe }));

  const links = nodeGroups.flatMap((source) => {
    const target = nodeGroups.find((g) => g.depth === source.depth + 1);
    if (!target) return [];
    return data.map((x, linkId) => ({
      source: x[source.nodes],
      target: x[target.nodes],
      value: x.flow,
      linkId,
    }));
  });

  return { nodes, links };
}
